import React, {Fragment} from 'react';
import Home from '../src/pages/HomePage/Home';
import Login from './pages/Authentication/LoginPage/Login';
import ListEntities from '../src/pages/Entities/ListEntitiesPage/ListEntities';
import {BrowserRouter as Router, matchPath, Route, Switch, withRouter} from 'react-router-dom';
import {PrivateRoute} from "./_components/PrivateRoute";
import CreateEntity from "./pages/Entities/CreateEntityPage/CreateEntity";
import EditEntity from "./pages/Entities/EditEntityPage/EditEntity";
import ListProperties from "./pages/Properties/ListPropertiesPage/ListProperties";
import CreateProperty from "./pages/Properties/CreatePropertyPage/CreateProperty";
import EditProperty from "./pages/Properties/EditPropertyPage/EditProperty";
import {ListTenants} from "./pages/Tenants/ListTenantsPage/ListTenants";
import CreateTenant from "./pages/Tenants/CreateTenantPage/CreateTenant";
import EditTenant from "./pages/Tenants/EditTenantPage/EditTenant";
import CreateContract from "./pages/Contracts/CreateContractPage/CreateContract";
import EditContract from "./pages/Contracts/EditContractPage/EditContract";
import Registration from "./pages/Authentication/RegistrationPage/Registration";
import ActivateAccount from "./pages/Authentication/ActivateAccountPage/ActivateAccount";

import './appStyle.css';
import CreateExpense from "./pages/Expenses/CreateExpensePage/CreateExpense";
import EditExpense from "./pages/Expenses/EditExpensePage/EditExpense";
import EditRent from "./pages/Rents/EditRentPage/EditRent";
import CreateRent from "./pages/Rents/CreateRentPage/CreateRent";
import CreateInvoicePdfDocument from "./pages/RentPayments/CreateInvoicePdfDocument/CreateInvoicePdfDocument";
import ValidateInvoicePdfDocument from "./pages/RentPayments/ValidateInvoicePdfDocument/ValidateInvoicePdfDocument";
import RequestResetPassword from "./pages/Authentication/ForgotPassword/RequestResetPassword";
import ResetPassword from "./pages/Authentication/ForgotPassword/ResetPassword";
import {entityService} from "./_services";
import {userEntityService} from "./_services/userEntity.service";
import CreateRentPayment from "./pages/RentPayments/CreateRentPayment";
import Entity from "./pages/Entities/EntityPage/Entity";
import {ListContracts} from "./pages/Contracts/ListContractsPage/ListContracts";
import {ContractPage} from "./pages/Contracts/ContractPage/ContractPage";
import {Property} from "./pages/Properties/PropertyPage/Property";
import {TenantPage} from "./pages/Tenants/TenantPage/TenantPage";

export const SERVER_URL = "https://www.koncreteglobal.com/api";

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    getEntityIdFromPathName() {
        const entityPathProperties = matchPath(window.location.pathname, {path: '/entities/:entityId'});
        if (entityPathProperties && entityPathProperties.params) {
            return entityPathProperties.params.entityId;
        }
        return null;
    }

    getUserEntityAccessMap() {
        entityService.getAll().then(entities => {
            this.setState({entities});
            const entityAccessMap = Object.fromEntries(
                entities.map(e => [e.id, e.accessType])
            );
            userEntityService.setUserEntityAccessMap(entityAccessMap);
            window.location.reload();
        });
    }

    render() {
        const entityId = this.getEntityIdFromPathName();
        if (entityId) {
            entityService.setCurrentEntity(entityId);
            if (!userEntityService.hasAccesses()) {
                this.getUserEntityAccessMap();
            }
        }
        return (
            <Router>
                <section className="app-dimensions pt-20">
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <Route path='/home' component={Home}/>
                        <Route path='/login' component={Login}/>
                        <Route path='/registration' component={Registration}/>
                        <Route path='/activateAccount:token?' component={ActivateAccount}/>
                        <Route path='/requestResetPassword' component={RequestResetPassword}/>
                        <Route path='/resetPassword' component={ResetPassword}/>


                        <Fragment>
                            <div className="jumbotron app-dimensions" style={{backgroundColor: "inherit", marginBottom: 0, boxShadow: "none"}}>
                                <div className="container" style={{"paddingLeft": 0, "paddingRight": 0, backgroundColor: "inherit"}}>
                                    <div className="row pt-20 pages-basic" style={{backgroundColor: "inherit"}}>
                                        <div className="col-md-12">

                                            <PrivateRoute exact path='/entities' component={ListEntities}/>
                                            <PrivateRoute exact path='/entities/:entityId' component={Entity}/>
                                            <PrivateRoute exact path='/entity/new' component={CreateEntity}/>
                                            <PrivateRoute exact path='/entities/:entityId/edit' component={EditEntity}/>

                                            <PrivateRoute exact path='/entities/:entityId/properties' component={ListProperties}/>
                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId' component={Property}/>
                                            <PrivateRoute exact path='/entities/:entityId/property/new' component={CreateProperty}/>
                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId/edit' component={EditProperty}/>

                                            <PrivateRoute exact path='/entities/:entityId/expense/new' component={CreateExpense}/>
                                            <PrivateRoute exact path='/entities/:entityId/expenses/:expenseId' component={EditExpense}/>

                                            <PrivateRoute exact path='/entities/:entityId/contracts' component={ListContracts}/>
                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId/contracts/:contractId' component={ContractPage}/>
                                            <PrivateRoute exact path='/entities/:entityId/contract/new' component={CreateContract}/>
                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId/contracts/:contractId/edit' component={EditContract}/>

                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId/contracts/:contractId/rent/new' component={CreateRent}/>
                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId/contracts/:contractId/rents/:rentId/edit' component={EditRent}/>
                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId/contracts/:contractId/rents/:rentId/payments/:paymentId/pdf/create' component={CreateInvoicePdfDocument}/>
                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId/contracts/:contractId/rents/:rentId/payments/:paymentId/pdf/validate' component={ValidateInvoicePdfDocument}/>

                                            <PrivateRoute exact path='/entities/:entityId/properties/:propertyId/contracts/:contractId/rents/:rentId/pay' component={CreateRentPayment}/>

                                            <PrivateRoute exact path='/entities/:entityId/tenants' component={ListTenants}/>
                                            <PrivateRoute exact path='/entities/:entityId/tenants/:tenantId' component={TenantPage}/>
                                            <PrivateRoute exact path='/entities/:entityId/tenant/new' component={CreateTenant}/>
                                            <PrivateRoute exact path='/entities/:entityId/tenants/:tenantId/edit' component={EditTenant}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </Switch>
                </section>
            </Router>
        );
    }
}

export default withRouter(App);
