import React, {useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from "react-router";
import {permissionService} from "../../../_services/permission.service";
import {MDBIcon} from "mdbreact";
import {ConfirmDeleteContract} from "./ConfirmDeleteContract";
import './Contract.css';
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {contractService} from "../../../_services";
import {getStringAddress} from "../../../_helpers/address-functions";
import {Card, CardBody, CardHeader} from 'reactstrap';
import {getStringDateInCurrentTimeZone} from "../../../_helpers/date-functions";
import {Money} from "../../../_helpers/NumberUtils";
import ContractAmountsCharts from './ContractAmountsCharts';
import ContractTenants from "./ContractTenants";
import ContractFiles from "./ContractFiles";
import ContractRents from "./ContractRents";
import {rentService} from "../../../_services/rent.service";
import {getIcon} from "../../../_helpers/Icon";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";
import {errorToast, infoToast, successToast} from "../../../_helpers/toast-functions";

export interface Tenant {
    id: number,
    name: string
}

export interface File {
    id: number,
    name: string,
    createdAt: Date
}

export interface Property {
    id: number,
    name: string,
    propertyTypeId: number,
    propertyType: string,
    address: Object
}

export interface ContractInterface {
    id: number,
    entityId: number,
    property: Property,
    startDate: Date,
    endDate: Date,
    startDateRenegotiationPeriod: Date,
    endDateRenegotiationPeriod: Date,
    frequencyType: number,
    guarantee: number,
    currency: string,
    amount: number,
    taxableAmount: number,
    extraAmount: number,
    amountAfterTaxes: number,
    extraAmountDescription: string,
    taxRetentionPercentage: number,
    debt: number,
    tenants: [Tenant],
    files: [File]
}


export const ContractPage = ({match}: RouteComponentProps<Props>) => {
    const entityId = match.params.entityId;
    const propertyId = match.params.propertyId;
    const contractId = match.params.contractId;

    const {t} = useTranslation();
    const history = useHistory();

    const [contract, setContract] = useState<ContractInterface>();
    const [rents, setRents] = useState([]);
    const [files, setFiles] = useState([]);

    const filesDivRef = useRef<HTMLInputElement>(null);

    const numRents = 0; // todo

    const fileChangeHandler = (event: any) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 10000000) {
                errorToast(`${t('toast-File cannot have more than 10 MB')}.`);
            } else {
                const formData = new FormData();
                formData.append('file', selectedFile);
                infoToast(`${t('toast-Uploading')}.`);
                contractService.uploadFile(entityId, propertyId, contractId, formData)
                    .then(response => {
                            successToast(`${t('toast-File uploaded successfully')}.`);
                            if (response && response.length > 0) {
                                setFiles(response);
                            }
                            if (filesDivRef && filesDivRef.current) {
                                filesDivRef.current.scrollIntoView();
                            }
                        },
                        error => {
                            console.log(error);
                            errorToast(`${t('toast-Error while uploading file')}.`);
                        });
            }
        }
    };

    const triggerUploadButtonClicked = () => {
        // @ts-ignore
        document.querySelector("input[type='file']").click();
    };

    React.useEffect(() => {
        contractService.getContractOverview(entityId, propertyId, contractId)
            .then(c => {
                setContract(c);
                setFiles(c.files);
            });
        rentService.getAll(entityId, propertyId, contractId)
            .then(r => setRents(r));
    }, []);

    return (
        <div>
            <Navigation entityId={entityId}/>
            <div className="col-xl-6 offset-md-3 text-center">
                <div className="section-title">
                    <h4>{t('contract-Details')}</h4>
                </div>
                {
                    permissionService.hasEditAccess(entityId) &&
                    <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/edit`}
                          style={{marginLeft: "10px", marginRight: "10px"}}>
                        <MDBIcon icon="pen"/>
                    </Link>
                }
                {
                    permissionService.hasDeleteAccess(entityId) &&
                    <ConfirmDeleteContract history={history} ids={match.params} numRents={numRents}/>
                }
            </div>
            <br/>
            <div className="card card-cascade narrower">
                <div className="row view view-cascade py-3 gradient-card-header info-color-dark d-flex justify-content-between align-items-center white-text"
                     style={{borderRadius: "5px"}}>
                    <div className="col-md-10">
                        <Link to={"/entities/" + entityId + "/properties/" + propertyId}
                              style={{color: "white"}}>
                            <MDBIcon icon={getIcon(contract?.property.propertyTypeId)} size="3x" className="float-child"/>
                            <h5 className="float-child">
                                <strong>
                                    {t(`properties-${contract?.property.propertyType.toUpperCase()}`)}
                                    {contract?.property.name && <span className="ml30">{contract?.property.name}</span>}
                                </strong>
                                <br/>
                                {getStringAddress(contract?.property.address)}
                            </h5>
                        </Link>
                    </div>
                    <div className="col-md-2 text-right">
                        {
                            permissionService.hasCreateAccess(entityId) &&
                            <>
                                <>
                                    <input type="file" name="file" onChange={fileChangeHandler} hidden={true}/>
                                    <button onClick={triggerUploadButtonClicked} type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            data-tip data-for="uploadFileTip">
                                        <MDBIcon icon="upload" size="2x"/>
                                    </button>
                                    <ReactTooltip id="uploadFileTip" place="top" effect="solid">
                                        {t('Upload file')}
                                    </ReactTooltip>
                                </>
                                <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rent/new`}>
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="creteRentTip">
                                        <MDBIcon icon="plus"/> <MDBIcon icon="file-contract" size="2x"/>
                                    </button>
                                    <ReactTooltip id="creteRentTip" place="top" effect="solid">
                                        {t('Create a new Rent')}
                                    </ReactTooltip>
                                </Link>
                            </>
                        }
                    </div>
                </div>
                <div className="card-body">
                    <div className="row justify-content-md-center">
                        <div className="col-md-3">
                            <Card className="card-details">
                                <CardHeader style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}}>
                                    <MDBIcon icon="clock"/> {t('contract-Duration')}
                                </CardHeader>
                                <CardBody className="vertical-center-container">
                                    <div className="vertical-center">
                                        <div className="text-center">
                                            {getStringDateInCurrentTimeZone(contract?.startDate)}
                                            <hr className={"divider"}/>
                                            <b>{getStringDateInCurrentTimeZone(contract?.endDate)}</b>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card className="card-details">
                                <CardHeader style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}}>
                                    <MDBIcon icon="comments"/> {t('contract-Renegotiation')}
                                </CardHeader>
                                <CardBody className="vertical-center-container">
                                    <div className="vertical-center">
                                        <div className="text-center">
                                            {getStringDateInCurrentTimeZone(contract?.startDateRenegotiationPeriod)}
                                            <hr className={"divider"}/>
                                            {getStringDateInCurrentTimeZone(contract?.endDateRenegotiationPeriod)}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            <Card className="card-details">
                                <CardHeader style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}}>
                                    <MDBIcon icon="hand-holding-usd"/> {t('contract-Guarantee')}
                                </CardHeader>
                                <CardBody className="vertical-center-container">
                                    <div className="vertical-center">
                                        <div className="text-center">
                                            <Money number={contract?.guarantee || 0} currency={contract?.currency || ''}/>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    <div>
                        <ContractAmountsCharts
                            totalAmount={(contract?.amount || 0) + (contract?.extraAmount || 0)}
                            extraAmount={contract?.extraAmount}
                            taxableAmount={contract?.taxableAmount}
                            netAmount={contract?.amountAfterTaxes}
                            taxRetentionPercentage={contract?.taxRetentionPercentage || 0}
                            currency={contract?.currency}
                        />
                    </div>
                </div>
            </div>
            <ContractTenants
                tenants={contract?.tenants}
                entityId={entityId}
                history={history}
            />
            <ContractFiles
                files={files}
                entityId={entityId}
                propertyId={propertyId}
                contractId={contractId}
                history={history}
            />
            <div ref={filesDivRef}>
                <ContractRents
                    entityId={entityId}
                    propertyId={propertyId}
                    contractId={contractId}
                    rents={rents}
                    history={history}
                />
            </div>
        </div>
    );
}

type Props = {
    entityId: string,
    propertyId: string,
    contractId: string;
}