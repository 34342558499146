import React from 'react';
import {contractService} from "../../../_services";
import ContractForm from "../_aux/ContractForm";
import {MDBIcon} from "mdbreact";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

export const EDIT_CONTRACT = "EditContract";

class EditContract extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                guarantee: null,
                amount: null,
                taxableAmount: null,
                extraAmount: null,
                extraAmountDescription: '',
                taxRetention: false,
                taxRetentionPercentage: null,
                frequencyValue: null
            },
            tenantsId: []
        };
    }

    componentDidMount() {
        const entityId = this.props.match.params.entityId;
        const propertyId = this.props.match.params.propertyId;
        const contractId = this.props.match.params.contractId;

        contractService.getContract(entityId, propertyId, contractId).then(response => {
            let initialValues = {};
            initialValues.guarantee = response.guarantee;
            initialValues.amount = response.amount;
            initialValues.taxableAmount = response.taxableAmount;
            initialValues.extraAmount = response.extraAmount;
            initialValues.extraAmountDescription = response.extraAmountDescription;
            initialValues.taxRetention = response.taxRetention;
            initialValues.taxRetentionPercentage = response.taxRetentionPercentage;
            initialValues.frequencyValue = response.frequencyValue;
            this.setState({initialValues: initialValues});
            this.setState({currencyId: response.currencyId});
            this.setState({startDate: response.startDate});
            this.setState({endDate: response.endDate});
            this.setState({startDateRenegotiationPeriod: response.startDateRenegotiationPeriod});
            this.setState({endDateRenegotiationPeriod: response.endDateRenegotiationPeriod});
            this.setState({frequencyTypeId: response.frequencyTypeId});
            this.setState({tenantsId: response.tenantsId});
        });
    }

    render() {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;
        const propertyId = this.props.match.params.propertyId;
        const contractId = this.props.match.params.contractId;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-3 col-md-3">
                        <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}`}
                              style={{color: "#0062cc"}}>
                            <MDBIcon icon="undo"/> Back to Contract
                        </Link>
                    </div>
                    <div className="col-xl-6 col-md-6 text-center">
                        <div className="section-title">
                            <h4>{t('contractForm-Edit Contract')}</h4>
                        </div>
                    </div>
                </div>
                <ContractForm
                    entityId={entityId}
                    propertyId={propertyId}
                    contractId={contractId}
                    history={this.props.history}
                    initialValues={this.state.initialValues}
                    currencyId={this.state.currencyId}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    startDateRenegotiationPeriod={this.state.startDateRenegotiationPeriod}
                    endDateRenegotiationPeriod={this.state.endDateRenegotiationPeriod}
                    frequencyTypeId={this.state.frequencyTypeId}
                    tenantsId={this.state.tenantsId}
                    page={EDIT_CONTRACT}/>
            </div>
        );
    }
}


export default withTranslation()(EditContract);