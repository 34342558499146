import React, {Component} from 'react';
import './sections/Welcome.css';
import Welcome from "./sections/Welcome";
import Services from "./sections/Services";
import Pricing from "./sections/Pricing";
import Contact from "./sections/Contact";
import {successToast} from "../../_helpers/toast-functions";
import Navigation from "../../_components/Navigation";
import {withTranslation} from "react-i18next";

class Home extends Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        const queryFilters = require('query-string').parse(this.props.location.search);
        if (queryFilters["logout"] == "true") {
            successToast(`${t('toast-Logout Successful')}!`);
        }
    }

    render() {
        return (
            <div>
                <Navigation/>
                <Welcome/>
                <Services/>
                <Pricing/>
                <Contact/>
            </div>
        );
    }
}

export default withTranslation()(Home);