import React from 'react';
import {contractService} from "../../../_services";
import RentForm from "../_aux/RentForm";
import {roundTwoDecimalCases} from "../../../_helpers/util-functions";
import {getLastDayOfMonth, getNextMonth} from "../../../_helpers/date-functions";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

export const CREATE_RENT = "CreateRent";

class CreateRent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                amount: '',
                amountPaid: '',
                taxableAmount: '',
                description: ''
            },
            paymentDueDate: getLastDayOfMonth(),
            paymentPeriodDate: null,
            taxRetentionPercentage: null
        };
    }

    componentDidMount() {
        const entityId = this.props.match.params.entityId;
        const propertyId = this.props.match.params.propertyId;
        const contractId = this.props.match.params.contractId;

        contractService.getContract(entityId, propertyId, contractId).then(response => {
            let initialValues = {};
            initialValues.amount = response.amount + response.extraAmount;
            if (response.taxRetention) {
                this.setState({taxRetentionPercentage: response.taxRetention && response.taxRetentionPercentage});
                initialValues.retainedAmount = roundTwoDecimalCases(response.taxableAmount * (response.taxRetentionPercentage / 100));
                initialValues.taxableAmount = '';
            } else {
                initialValues.taxableAmount = response.taxableAmount;
            }
            this.setState({initialValues: initialValues});
            this.setState({currencyId: response.currencyId});
            this.setState({paymentPeriodDate: this.getPaymentPeriodDate(response.lastPaymentPeriod)});
        });
    }

    getPaymentPeriodDate(lastPaymentPeriod) {
        let paymentPeriodDate = new Date();
        if (lastPaymentPeriod) {
            paymentPeriodDate = getNextMonth(lastPaymentPeriod);
        }
        paymentPeriodDate.setDate(15);
        paymentPeriodDate.setHours(12);
        return paymentPeriodDate;
    }

    render() {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('rentForm-New Rent')}</h4>
                        </div>
                    </div>
                </div>
                <RentForm
                    entityId={entityId}
                    propertyId={this.props.match.params.propertyId}
                    contractId={this.props.match.params.contractId}
                    history={this.props.history}
                    initialValues={this.state.initialValues}
                    currencyId={this.state.currencyId}
                    paymentDueDate={this.state.paymentDueDate}
                    paymentPeriodDate={this.state.paymentPeriodDate}
                    taxRetentionPercentage={this.state.taxRetentionPercentage}
                    page={CREATE_RENT}/>
            </div>
        );
    }
}

export default withTranslation()(CreateRent);