import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {errorToast, successToast} from "../../../_helpers/toast-functions";
import {rentService} from "../../../_services/rent.service";
import {useTranslation} from "react-i18next";

export function ConfirmDeleteRent(props) {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDeleteRent = () => {
        rentService.deleteRent(props.entityId, props.propertyId, props.contractId, props.rentId)
            .then(
                response => {
                    successToast(`${t('toast-Rent successfully deleted')}!`);
                    props.history.replace(`/entities/${props.entityId}/properties/${props.propertyId}/contracts/${props.contractId}/#rents`);
                    window.location.reload();
                },
                error => {
                    errorToast(error.message);
                    setShow(false);
                }
            );
    };

    return (
        <>
            <a onClick={handleShow} style={{"color": "#BC0000"}}>Delete</a>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                    Are you sure you want to delete this rent? <br/>
                    The rent and all it's payments will be permanently deleted.
                    This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteRent}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
