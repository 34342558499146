import React from "react";
import './Pricing.css';
import {useTranslation} from "react-i18next";

function Pricing() {
    const {t} = useTranslation();
    return (
        <section>
            <div className="container">
                <div className="col-xl-6 mx-auto text-center">
                    <div className="section-title mb-100">
                        <a href="https://www.youtube.com/watch?v=CnTRwHCCjJM&list=PLQb1dCoIKQqm7uhLzHmf_xuyp2Ee8ljoc">
                            <h4>{t('home-Pricing')}</h4>
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="pricingTable">
                            <svg x="0" y="0" viewBox="0 0 360 220">
                                <g>
                                    <path fill="#ae003d" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                        L0.732,193.75z"></path>
                                </g>
                                <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" fontSize="78.4489">€200</text>
                                <text transform="matrix(0.9566 0 0 1 244.3096 83.9121)" fill="#fff" fontSize="29.0829">.99</text>
                                <text transform="matrix(1 0 0 1 273.9629 115.5303)" fill="#fff" fontSize="15.4128">/ {t('home-Month')}</text>
                            </svg>
                            <div className="pricing-content">
                                <h3 className="title">{t('home-Standard')}</h3>
                                <div className="data">
                                    <ul className="pricing-content">
                                        <li><b>50</b> {t('home-Properties')}</li>
                                        <li><b>2</b> {t('home-Users')}</li>
                                        <li><b>1</b> {t('home-User Role (Admin)')}</li>
                                    </ul>
                                </div>
                                <div className="features">
                                    <div style={{"textAlign": "left", "paddingLeft": "40px", "color": "#ae003d", "fontWeight": "600"}}>{t('home-Features:')}</div>
                                    <ul className="pricing-content">
                                        <li>{t('home-Create Properties')}</li>
                                        <li>{t('home-Create Tenants')}</li>
                                        <li>{t('home-Create Contracts')}</li>
                                    </ul>
                                </div>
                                <div className="top-up">
                                    <div style={{"textAlign": "left", "paddingLeft": "40px", "color": "#ae003d", "fontWeight": "600"}}>{t('home-Top Up:')}</div>
                                    <ul className="pricing-content">
                                        <li><small><b>+100€/{t('home-month')}</b> {t('home-for additional')} <b>50</b> {t('home-properties')}</small></li>
                                        <li><small><b>+10€/{t('home-month')}</b> {t('home-for each additional user')}</small></li>
                                    </ul>
                                </div>
                                {/*<a href="#" className="pricingTable-signup">Subscribe Now</a>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="pricingTable">
                            <svg x="0" y="0" viewBox="0 0 360 220">
                                <g>
                                    <path fill="#005c99" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                        L0.732,193.75z"></path>
                                </g>
                                <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" fontSize="78.4489">€300</text>
                                <text transform="matrix(0.9566 0 0 1 244.3096 83.9121)" fill="#fff" fontSize="29.0829">.99</text>
                                <text transform="matrix(1 0 0 1 273.9629 115.5303)" fill="#fff" fontSize="15.4128">/ {t('home-Month')}</text>
                            </svg>
                            <div className="pricing-content">
                                <h3 className="title-blue">{t('home-Business')}</h3>
                                <div className="data">
                                    <ul className="pricing-content">
                                        <li><b>50</b> {t('home-Properties')}</li>
                                        <li><u><b>5</b> {t('home-Users')}</u></li>
                                        <li><u><b>2</b> {t('home-User Role (Admin, Read)')}</u></li>
                                    </ul>
                                </div>
                                <div className="features">
                                    <div style={{"textAlign": "left", "paddingLeft": "40px", "color": "#ae003d", "fontWeight": "600"}}>{t('home-Features:')}</div>
                                    <ul className="pricing-content">
                                        <li>{t('home-Create Properties')}</li>
                                        <li>{t('home-Create Tenants')}</li>
                                        <li>{t('home-Create Contracts')}</li>
                                        <li><u>{t('home-Register Invoices')}</u></li>
                                        <li><u>{t('home-Register Properties\' expenses')}</u></li>
                                        <li><u>{t('home-Notification on important dates')}</u></li>
                                    </ul>
                                </div>
                                <div className="top-up">
                                    <div style={{"textAlign": "left", "paddingLeft": "40px", "color": "#ae003d", "fontWeight": "600"}}>{t('home-Top Up:')}</div>
                                    <ul className="pricing-content">
                                        <li><small><b>+150€/{t('home-month')}</b> {t('home-for additional')} <b>50</b> {t('home-properties')}</small></li>
                                        <li><small><b>+12€/{t('home-month')}</b> {t('home-for each additional user')}</small></li>
                                    </ul>
                                </div>
                                {/*<a href="#" className="pricingTable-signup">Subscribe Now</a>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="pricingTable">
                            <svg x="0" y="0" viewBox="0 0 360 220">
                                <g>
                                    <path fill="#db2c29" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061
                                    c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243
                                    s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48
                                    L0.732,193.75z"></path>
                                </g>
                                <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" fontSize="78.4489">€400</text>
                                <text transform="matrix(0.9566 0 0 1 244.3096 83.9121)" fill="#fff" fontSize="29.0829">.99</text>
                                <text transform="matrix(1 0 0 1 273.9629 115.5303)" fill="#fff" fontSize="15.4128">/ {t('home-Month')}</text>
                            </svg>
                            <div className="pricing-content">
                                <h3 className="title-red">{t('home-Executive')}</h3>
                                <div className="data">
                                    <ul className="pricing-content">
                                        <li><b>50</b> {t('home-Properties')}</li>
                                        <li><b>5</b> {t('home-Users')}</li>
                                        <li><b>2</b> {t('home-User Role (Admin, Read)')}</li>
                                        <li><u>{t('home-Account for each Tenant')}</u></li>
                                    </ul>
                                </div>
                                <div className="features">
                                    <div style={{"textAlign": "left", "paddingLeft": "40px", "color": "#ae003d", "fontWeight": "600"}}>{t('home-Features:')}</div>
                                    <ul className="pricing-content">
                                        <li>{t('home-Create Properties')}</li>
                                        <li>{t('home-Create Tenants')}</li>
                                        <li>{t('home-Create Contracts')}</li>
                                        <li>{t('home-Register Invoices')}</li>
                                        <li>{t('home-Register Properties\' expenses')}</li>
                                        <li>{t('home-Notification on important dates')}</li>
                                        <li><u>{t('home-Tenants can confirm rent payment')}</u></li>
                                        <li><u>{t('home-Communicate with your tenants')}</u></li>
                                    </ul>
                                </div>
                                <div className="top-up">
                                    <div style={{"textAlign": "left", "paddingLeft": "40px", "color": "#ae003d", "fontWeight": "600"}}>{t('home-Top Up:')}</div>
                                    <ul className="pricing-content">
                                        <li><small><b>+200€/{t('home-month')}</b> {t('home-for additional')} <b>50</b> {t('home-properties')}</small></li>
                                        <li><small><b>+15€/{t('home-month')}</b> {t('home-for each additional user')}</small></li>
                                    </ul>
                                </div>
                                {/*<a href="#" className="pricingTable-signup">Subscribe Now</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Pricing;
