import React from "react";
import './Services.css';
import {useTranslation} from "react-i18next";

function Services() {
    const {t} = useTranslation();
    return (
        <section>
            <div className="container">
                <div className="col-xl-6 mx-auto text-center">
                    <div className="section-title mb-100">
                        <h4>{t('home-Services')}</h4>
                    </div>
                </div>

                <div className="row mbr-justify-content-center">

                    <div className="col-lg-6 mbr-col-md-10">
                        <div className="wrap box">
                            <div className="ico-wrap">
                                <a href="https://www.youtube.com/watch?v=pBiK1imc0Dc&list=PLQb1dCoIKQql1xZQOYTQTc8kMfgYXjxb6">
                                    <span className="mbr-iconfont fa-calendar fa"></span>
                                </a>
                            </div>
                            <div className="text-wrap vcenter">
                                <h2 className="mbr-fonts-style mbr-bold mbr-section-title3 display-5">{t('home-Collaboration & Organisation')}</h2>
                                <p className="mbr-fonts-style text1 mbr-text display-6">
                                    {t('home-Organise both your personal and your company\'s real estate portfolio')}<br/>
                                    {t('home-Share it with your team and assign responsibilities')}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mbr-col-md-10">
                        <div className="wrap box">
                            <div className="ico-wrap">
                                <a href="https://www.youtube.com/watch?v=cvobDdiA3mQ&list=PLQb1dCoIKQqkqO_RVkpTECJWYKufP_qDLø">
                                    <span className="mbr-iconfont fa-folder-open fa"></span>
                                </a>
                            </div>
                            <div className="text-wrap vcenter">
                                <h2 className="mbr-fonts-style mbr-bold mbr-section-title3 display-5">{t('home-Manage Contracts')}</h2>
                                <p className="mbr-fonts-style text1 mbr-text display-6">
                                    {t('home-A single place to create your contracts, preserve and consult your history, facilitate reporting')}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mbr-col-md-10">
                        <div className="wrap box">
                            <div className="ico-wrap">
                                <a href="https://rumble.com/v1qxmbm-stress-relief-with-rain-sounds-on-autumn-leaves-at-forest-road.-nature-soun.html">
                                    <span className="mbr-iconfont fa-volume-up fa"></span>
                                </a>
                            </div>
                            <div className="text-wrap vcenter">
                                <h2 className="mbr-fonts-style mbr-bold mbr-section-title3 display-5">{t('home-Improved communication')}</h2>
                                <p className="mbr-fonts-style text1 mbr-text display-6">
                                    {t('home-Accurate and simple communication with tenants. Confirmation and validation of rent payments')}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mbr-col-md-10">
                        <div className="wrap box">
                            <div className="ico-wrap">
                                <a href="https://www.youtube.com/shorts/Gz8_EU0Mv6I">
                                    <span className="mbr-iconfont fas fa-bullseye"></span>
                                </a>
                            </div>
                            <div className="text-wrap vcenter">
                                <h2 className="mbr-fonts-style mbr-bold mbr-section-title3 display-5">{t('home-Quick to action')}</h2>
                                <p className="mbr-fonts-style text1 mbr-text display-6">
                                    {t('home-Easily identify and act on contracts requiring action')}
                                    {t('home-Act on contracts about to expire, approaching renegotiation period, payment overdue, etc')}</p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

        </section>
    );
}

export default Services;
