import {getStringAddress} from "../../../_helpers/address-functions";

function getStringProperty(property) {
    if (!property) {
        return null;
    }
    let stringProperty = property.propertyType;
    if (property.name != null) {
        stringProperty += " - " + property.name;
    }
    stringProperty += " - " + getStringAddress(property.address);
    return stringProperty;
}

export function getMapOfStringProperties(properties) {
    let mapStringProperties = [];
    Object.keys(properties).forEach(function (key) {
        let entry = {};
        entry["id"] = properties[key].id;
        entry["name"] = getStringProperty(properties[key]);
        entry["address"] = properties[key].address;
        mapStringProperties[key] = entry;
    });

    mapStringProperties.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    return mapStringProperties;
}
