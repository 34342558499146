export const dateService = {
    getMonthName,
    getMonthNamePt
};
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

const monthNamesPt = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

function getMonthName(date) {
    if (!date) {
        return "";
    }
    return monthNames[date.getMonth()];
}

function getMonthNamePt(date) {
    if (!date) {
        return "";
    }
    return monthNamesPt[date.getMonth()];
}
