import React from 'react';
import {MDBIcon} from "mdbreact";
import {Card, CardBody, Collapse} from "reactstrap";
import {withTranslation} from "react-i18next";
import ExpensesTable from "../../../Expenses/_aux/ExpensesTable";

class ExpensesTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        }
    }

    toggle() {
        return () => this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        const {t} = this.props;
        const entityId = this.props.entityId;
        const propertyId = this.props.propertyId;
        return (
            <div className="tab-content entity-overview-tab" id="expenses">
                <div className="section-title text-center">
                    <h4></h4>
                </div>
                <br/>
                <div className="card">

                    <div className="card-header" role="tab" id="headingThree" style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}} onClick={this.toggle()}>
                        <div id="folder" className="row">
                        <span className="mt-1 mb-0 col-md-10">
                            {t('property-Expenses-card')}
                        </span>
                            {
                                this.state.isOpen
                                    ? <MDBIcon icon="angle-up col-md-2 text-right" size="2x"/>
                                    : <MDBIcon icon="angle-down col-md-2 text-right" size="2x"/>
                            }
                        </div>
                    </div>

                    <Collapse isOpen={this.state.isOpen}>
                        <Card>
                            <CardBody>
                                <ExpensesTable
                                    entityId={entityId}
                                    propertyId={propertyId}
                                    expenses={this.props.expenses}
                                    history={this.props.history}
                                    emptyMessage={t('property-Expenses-Table-No Expenses')}
                                />
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>

            </div>
        );
    }
}

export default withTranslation()(ExpensesTab);