import React from "react";
import FinanceDetailsAddress from "./FinanceDetailsAddress";

export class FinanceDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const financeDetails = this.props.financeDetails;
        return (
            <>
                <div className="section-title text-center">
                    <h4></h4>
                </div>
                {
                    financeDetails &&
                    financeDetails.length > 0 &&
                    financeDetails.map(row => {
                            return <FinanceDetailsAddress
                                row={row}
                                entityId={this.props.entityId}
                                history={this.props.history}
                                oneMonthSummary={this.props.oneMonthSummary}
                                financeDetailsEndDate={this.props.financeDetailsEndDate}/>
                        }
                    )
                }
            </>
        );
    }
}


