import React from "react";
import {Document, Page, StyleSheet, Text, View,} from "@react-pdf/renderer";
import styled from '@react-pdf/styled-components';
import {roundTwoDecimalCases} from "../../../_helpers/util-functions";


const styles = StyleSheet.create({
        container: {
            border: true,
            height: 360,
            margin: "5%"
        },
        textVertical: {
            fontSize: 8,
            transform: "rotate(-90deg)"
        },
        topSection: {
            margin: '5px'
        },
        topSectionTopRow: {
            flexDirection: 'row',
            height: 80
        },
        topSectionTopRowLeft: {
            width: "75%"
        },
        topSectionMiddleRow: {
            marginLeft: '40%'
        },
        topSectionBottomRow: {
            marginTop: '10px'
        },
        bottomSection: {
            marginTop: '8px',
            marginLeft: '8px',
            marginRight: '8px',
            borderTop: true
        },
        bottomSectionTopRow: {
            flexDirection: 'row',
            height: 60
        },
        bottomSectionTopRowLeft: {
            width: "50%"
        },
        bottomSectionTopRowRight: {
            textAlign: 'right',
            width: '250px'
        },
        totalRent: {
            flexDirection: 'row',
            borderTop: true,
            textAlign: 'left',
            width: '170px',
            marginLeft: '80px'
        },
        totalRentAmount: {
            textAlign: 'right',
            marginRight: '24px'
        },
        legalWarning: {
            paddingTop: '20px'
        },
        signature: {
            borderBottom: true,
            width: '50%',
            marginLeft: '50%',
            height: '80px'
        },
    }
);

const Title = styled.Text
    `
  marginBottom: 3px;
  font-size: 10px;
`;

const Content = styled.Text
    `
  font-size: 8px;
`;

class InvoicePdfDocument extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const data = this.props.data;
        return (
            <Document>
                <Page wrap>
                    <View style={styles.container}>
                        <View style={styles.topSection}>
                            <View style={styles.topSectionTopRow}>
                                <View style={styles.topSectionTopRowLeft}>
                                    <Content>{data.landlordName}</Content>
                                    <Content>{data.landlordAddress}</Content>
                                    <Content>{data.landlordPostalCode}</Content>
                                    <Content>Telefone {data.landlordPhoneNumber}</Content>
                                    <Content>Contribuinte: {data.landlordTaxNumber}</Content>
                                </View>
                                <View>
                                    <Title>Original</Title>
                                    <Content>Recibo n° {data.receiptNumber}</Content>
                                    <Content>Pagamento: {data.paymentDate}</Content>
                                    <Content>Emissão: {data.emissionDate}</Content>
                                    <Content>Recibo válido apos boa cobrança</Content>
                                </View>
                            </View>
                            <View style={styles.topSectionMiddleRow}>
                                <Content>{data.tenantName}</Content>
                                <Content>{data.tenantAddress}</Content>
                                <Content>{data.tenantPostalCode}</Content>
                            </View>
                            <View style={styles.topSectionBottomRow}>
                                <Content>V. Contribuinte: {data.tenantTaxNumber}</Content>
                                <Content style={{marginTop: '10px'}}>
                                    Valores relativos a: {data.propertyAddress}
                                </Content>
                            </View>
                        </View>
                        <View style={styles.bottomSection}>
                            <View style={styles.bottomSectionTopRow}>
                                <View style={styles.bottomSectionTopRowLeft}>
                                    <Title>Renda de {data.rentPeriod}</Title>
                                    {
                                        data.retainedAmount > 0 &&
                                        <Title>Retenção à taxa de 25,00 %</Title>
                                    }
                                </View>
                                <View style={styles.bottomSectionTopRowRight}>
                                    <Title>{data.rentAmount} €</Title>
                                    {
                                        data.retainedAmount > 0 &&
                                        <Title>{data.retainedAmount} €</Title>
                                    }
                                    <View>
                                        <View style={styles.totalRent}>
                                            <Title>
                                                Total:
                                            </Title>
                                            <View style={styles.totalRentAmount}>
                                                <Title>
                                                    {roundTwoDecimalCases(data.rentAmount) - roundTwoDecimalCases(data.retainedAmount ? data.retainedAmount : 0)} €
                                                </Title>
                                            </View>
                                        </View>
                                        <Content>{data.amountToPayText}</Content>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.legalWarning}>
                                <Content>As rendas pagas depois do prazo legal, terão um acréscimo</Content>
                                <Content>de 50% nos termos do art°. 1041 do Cód Civil (Renda em</Content>
                                <Content>R.A.U.).</Content>
                            </View>
                            <View style={styles.signature}>
                            </View>
                        </View>
                    </View>
                    <View style={{height: 100, flexDirection: 'row', marginLeft: -28, marginTop: -140}}>
                        <View style={{alignItems: 'flex-start', justifyContent: 'center'}}>
                            <Text style={styles.textVertical}>Processado por computador </Text></View>
                    </View>
                </Page>
            </Document>
        );
    }
}

export default InvoicePdfDocument;
// https://react-pdf.org/repl?example=ruler
