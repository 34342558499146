import React from 'react';
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {filterBy} from '@progress/kendo-data-query';
import {lookupValuesService, propertyService} from "../../../_services";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Checkbox} from '@progress/kendo-react-inputs';
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {CREATE_EXPENSE} from "../CreateExpensePage/CreateExpense";
import {successToast} from "../../../_helpers/toast-functions";
import {expenseService} from "../../../_services/expense.service";
import {EDIT_EXPENSE} from "../EditExpensePage/EditExpense";
import {withTranslation} from "react-i18next";
import {getMapOfStringProperties} from "../../Properties/_aux/properties-functions";
import MyNumberInput from "../../../_components/MyNumberInput";

class ExpenseForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currencies: [],
            allCurrencies: [],
            currency: null,
            expenseDate: null,

            propertiesFetched: false,
            properties: [],
            allProperties: [],
            property: null
        };
    }

    componentDidMount() {
        const {t} = this.props;
        propertyService.getAllSummary(this.props.entityId).then(response => {
            this.setState({propertiesFetched: true});
            const propertiesTranslated = response.map(x => {
                let translatedObj = x;
                translatedObj.propertyType = `${t(`properties-${x.propertyType.toUpperCase()}`)}`;
                return translatedObj;
            });
            const mapOfStringProperties = getMapOfStringProperties(propertiesTranslated);
            this.setState({properties: mapOfStringProperties});
            this.setState({allProperties: mapOfStringProperties});
            this.setState({property: this.getProperty(mapOfStringProperties)});
        });
        lookupValuesService.getCurrencies().then(response => {
            this.setState({currencies: response});
            this.setState({allCurrencies: response})
        });
    }

    getProperty(allProperties) {
        const propertyId = this.props.propertyId;
        for (const p of allProperties) {
            if (p.id == propertyId) {
                return p;
            }
        }
        return null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let currencyValue = {currency: prevState.currency};
        let expenseDateValue = {startDate: prevState.startDate};

        if (!prevState.currency) {
            currencyValue = {currency: setCurrency(nextProps, prevState)};
        }
        if (!prevState.expenseDate && nextProps.expenseDate) {
            expenseDateValue = {expenseDate: new Date(nextProps.expenseDate)};
        }
        return ({...currencyValue, ...expenseDateValue});
    }

    handleChange = (event) => {
        switch (event.target.name) {
            case "property":
                this.setState({property: event.target.value});
                break;
            case "currency":
                this.setState({currency: event.target.value});
                break;
            case "expenseDate":
                const expenseDate = new Date(event.target.value);
                expenseDate.setHours(12);
                this.setState({expenseDate});
                break;
            default:
                console.warn("unhandled change event for " + event.target.name);
        }
    };

    filterChange = (event) => {
        switch (event.target.name) {
            case "property":
                const properties = this.state.allProperties.slice();
                this.setState({properties: filterBy(properties, event.filter)});
                break;
            case "currency":
                const currencies = this.state.allCurrencies.slice();
                this.setState({currencies: filterBy(currencies, event.filter)});
                break;
            default:
                console.warn("unhandled filter change for " + event.target.name);
        }
    };

    render() {
        const {t} = this.props;
        return (
            <div className="container up">
                <Formik
                    enableReinitialize
                    initialValues={this.props.initialValues}
                    validationSchema={Yup.object().shape(expenseValidationSchema)}
                    onSubmit={(values, {setStatus, setSubmitting}
                    ) => {
                        if (!this.isValidInput()) {
                            setSubmitting(false);
                            let error = this.getErrorMessages();
                            setStatus(error);
                        } else {
                            setStatus();
                            this.createOrUpdateExpense(values.amount, values.name, values.description, values.includeInYield, setSubmitting, setStatus);
                            setSubmitting(false);
                        }
                    }}
                    render={({errors, status, values, touched, isSubmitting, setFieldValue}) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="property" className="control-label">{t('expenseForm-Property')}</label>
                                <ComboBox
                                    data={this.state.properties}
                                    textField="name"
                                    dataItemKey="id"
                                    value={this.state.property}
                                    name="property"
                                    onChange={this.handleChange}
                                    filterable={true}
                                    onFilterChange={this.filterChange}
                                    style={{"width": "100%"}}/>
                                <ErrorMessage name="property" component="div" className="invalid-feedback"/>
                                {
                                    status && status.propertyId &&
                                    <div className="text-danger invalid-input">
                                        {t('expenseForm-Property')} {status.propertyId}
                                    </div>
                                }
                            </div>
                            <div className="form-group smallInput required">
                                <label htmlFor="name" className="control-label">{t('expenseForm-Name')}</label>
                                <Field name="name" type="text"
                                       className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                                {
                                    status && status.name &&
                                    <div className="text-danger invalid-input">
                                        {t('expenseForm-Name')} {status.name}
                                    </div>
                                }
                            </div>

                            {
                                this.state.property &&
                                <div className="form-group smallInput">
                                    <label htmlFor="includeInYield" style={{"marginRight": "10px"}}>{t('expenseForm-Include in Yield')}</label>
                                    <Checkbox id="includeInYield" name="includeInYield" value={values.includeInYield} onChange={e => setFieldValue('includeInYield', e.value)}/>
                                </div>
                            }

                            <div className="form-group required">
                                <label htmlFor="currency" className="control-label">{t('expenseForm-Currency')}</label> <br/>
                                <ComboBox
                                    data={this.state.currencies}
                                    textField="name"
                                    dataItemKey="id"
                                    value={this.state.currency}
                                    name="currency"
                                    onChange={this.handleChange}
                                    filterable={true}
                                    onFilterChange={this.filterChange}
                                />
                                <ErrorMessage name="currency" component="div" className="invalid-feedback"/>
                                {
                                    status && status.currencyId &&
                                    <div className="text-danger invalid-input">
                                        {t('expenseForm-Currency')} {status.currencyId}
                                    </div>
                                }
                            </div>

                            <div className="form-group smallInput required">
                                <label htmlFor="amount" className="control-label">{t('expenseForm-Amount')}</label>

                                <MyNumberInput
                                    className={'form-control' + (errors.amount && touched.amount ? ' is-invalid' : '')}
                                    placeholder="Ex: 123,98"
                                    value={values.amount}
                                    onValueChange={val => setFieldValue('amount', val.floatValue)}
                                />

                                <ErrorMessage name="amount" component="div" className="invalid-feedback"/>
                                {
                                    status && status.amount &&
                                    <fdiv className="text-danger invalid-input">
                                        {t('expenseForm-Amount')} {status.amount}
                                    </fdiv>
                                }
                            </div>


                            <div className="form-group required">
                                <label htmlFor="expenseDate" className="control-label">{t('expenseForm-Date')}</label> <br/>
                                <DatePicker
                                    format={"dd-MM-yyyy"}
                                    formatPlaceholder="formatPattern"
                                    name="expenseDate"
                                    onChange={this.handleChange}
                                    value={this.state.expenseDate}
                                />
                                <ErrorMessage name="expenseDate" component="div" className="invalid-feedback"/>
                                {
                                    status && status.expenseDate &&
                                    <div className="text-danger invalid-input">
                                        {t('expenseForm-Date')} {status.expenseDate}
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">{t('expenseForm-Description')}</label>
                                <Field name="description" component="textarea"
                                       className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}/>
                                <ErrorMessage name="description" component="div" className="invalid-feedback"/>
                                {
                                    status && status.constraints && status.constraints.description &&
                                    <div className="text-danger invalid-input">
                                        {t('expenseForm-Description')} {status.constraints.description}
                                    </div>
                                }
                            </div>

                            {
                                !this.state.property &&
                                <div className={'alert alert-info'}>
                                    <strong>Info: </strong> {t('expenseForm-Warning No Property')}
                                </div>
                            }

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                    {this.props.page === CREATE_EXPENSE ? <a>Create</a> : <a>Update</a>}
                                </button>
                                {
                                    isSubmitting &&
                                    <img
                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                }
                            </div>
                            {
                                status && status.message &&
                                <div>
                                    <div className={'alert alert-danger'}>{status.message}</div>
                                </div>
                            }
                        </Form>
                    )}
                />
            </div>
        );
    }

    isValidInput() {
        return this.state.currency != null
            && this.state.expenseDate != null;
    }

    getErrorMessages() {
        let error = {};
        if (!this.state.currency) {
            error["currencyId"] = "is required";
        }
        if (!this.state.expenseDate) {
            error["expenseDate"] = "is required";
        }
        return error;
    }

    createOrUpdateExpense(amount, name, description, includeInYield, setSubmitting, setStatus) {
        const propertyId = this.state.property ? this.state.property.id : null;
        if (this.props.page === CREATE_EXPENSE) {
            this.createExpense(propertyId, amount, name, description, includeInYield, setSubmitting, setStatus);
        } else if (this.props.page === EDIT_EXPENSE) {
            this.updateExpense(propertyId, amount, name, description, includeInYield, setSubmitting, setStatus);
        }
    }

    createExpense(propertyId, amount, name, description, includeInYield, setSubmitting, setStatus) {
        const {t} = this.props;
        expenseService.createExpense(
            this.props.entityId, propertyId,
            this.state.currency.id, this.state.expenseDate, amount, name, description, includeInYield
        )
            .then(
                response => {
                    successToast(`${t('toast-Expense successfully created')}!`);
                    this.props.history.push(this.getRedirectUrl(propertyId));
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }

    getRedirectUrl(propertyId) {
        if (propertyId) {
            return `/entities/${this.props.entityId}/properties/${propertyId}/#expenses`;
        } else {
            return `/entities/${this.props.entityId}`;
        }
    }

    updateExpense(propertyId, amount, name, description, includeInYield, setSubmitting, setStatus) {
        const {t} = this.props;
        expenseService.updateExpense(
            this.props.entityId, propertyId, this.props.expenseId,
            this.state.currency.id, this.state.expenseDate, amount, name, description, includeInYield
        )
            .then(
                response => {
                    successToast(`${t('toast-Expense successfully updated')}!`);
                    this.props.history.push(this.getRedirectUrl(propertyId));
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }
}

export const expenseValidationSchema = {
    amount: Yup.number()
        .typeError("Invalid number")
        .positive("Must be a positive number")
        .required(),
    name: Yup.string()
        .max(50, 'Cannot contain more than 50 characters')
        .required(),
    description: Yup.string()
        .max(255, 'Cannot contain more than 200 characters')
        .nullable(),
};


function setCurrency(prop, prevState) {
    if (prevState.currency || !prop.currencyId || !prevState.allCurrencies.length) {
        return null;
    }
    for (const curr of prevState.allCurrencies) {
        if (curr.id === prop.currencyId) {
            return curr;
        }
    }
    return null;
}

export default withTranslation()(ExpenseForm);