import React from "react";
import {useTranslation} from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import 'react-flags-select/css/react-flags-select.css';


function LanguageSwitcher() {
    const {i18n} = useTranslation();

    function getDefaultCountry() {
        return (i18n.language && i18n.language.length > 0 && i18n.language.substring(0, 2) === "pt")
            ? "PT"
            : "GB";
    }

    function onSelect(countryCode) {
        if (countryCode === "GB") {
            i18n.changeLanguage("en");
        } else {
            i18n.changeLanguage("pt");
        }
    }

    return (


        <ReactFlagsSelect
            countries={["PT", "GB"]}
            // placeholder="Select Language"
            showSelectedLabel={false}
            showOptionLabel={false}
            defaultCountry={getDefaultCountry()}
            selectedSize={13}
            optionsSize={13}
            onSelect={onSelect}
        />
    );
    this.onSelect = this.onSelect.bind(this);
}


export default LanguageSwitcher;