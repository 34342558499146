import * as Yup from "yup";

export const validationSchema = {
    name: Yup.string().required('Name is required')
        .max(60, 'Cannot contain more than 60 characters'),
    legalName: Yup.string().nullable()
        .max(60, 'Cannot contain more than 60 characters'),
    description: Yup.string().nullable()
        .max(200, 'Cannot contain more than 200 characters'),
    financeNumber: Yup.string().nullable()
        .max(20, 'Cannot contain more than 20 characters'),
    phone: Yup.string().nullable()
        .max(20, 'Cannot contain more than 20 characters'),
    address: Yup.string().nullable()
        .max(250, 'Cannot contain more than 250 characters'),
    postalCode: Yup.string().nullable()
        .max(30, 'Cannot contain more than 30 characters')
};
