import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import {orderFormatter} from "../../../_helpers/util-functions";
import {getStringAddress} from "../../../_helpers/address-functions";
import {getProfit} from "../../../_services/metrics.service";
import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import {getNumber, Money} from "../../../_helpers/NumberUtils";

function PropertiesTable(params) {

    const {t} = useTranslation();


    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            params.history.push(`/entities/${params.entityId}/properties/${row.id}`);
        }
    };

    const defaultSortedFinanceDetailsTable = [
        {
            dataField: 'id',
            order: 'asc'
        }
    ];

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            hidden: true,
            sort: true
        },
        {
            dataField: 'address.street',
            text: `${t('properties-header-Property')}`,
            sort: true,
            sortValue: (cell, row) => getStringAddress(row.address) + row.name,
            headerAlign: "center",
            headerStyle: (colum, colIndex) => {
                return {width: '340px', minWidth: '310px'};
            },
            headerFormatter: orderFormatter,
            formatter: (cell, row, rowIndex) => (
                <div style={{display: "inline-block"}}>
                    <div>
                        {!params.hideType && <b style={{marginRight: "1em"}}>{t(`properties-${row.propertyType.name.toUpperCase()}`)}</b>}
                        {row.name && <span>{row.name}</span>}
                        {row.numBedrooms && row.name && <span>  -  </span>}
                        {row.numBedrooms && <span>T{row.numBedrooms}</span>}
                    </div>
                    {
                        params.showTaxCode &&
                        <div>
                            <b>{t(`properties-Tax code`)}:</b> {row.code}
                        </div>
                    }
                    <small style={{color: "#0062cc"}}>{getStringAddress(row.address)}</small>
                    {
                        row.numChildren > 0 &&
                        <>
                            <hr className={"divider"}/>
                            <small>
                                <span style={{marginRight: "3em"}}>{row.numChildren} {t('Inner Properties')}</span>
                                <span style={{marginLeft: "3em"}}><b>{Math.round(100 * row.numActiveContractsHierarchy / row.numPossibleContractsHierarchy)}%</b> {t('Occupation')}</span>
                            </small>
                        </>
                    }
                </div>
            ),
            footer: columnData =>
                <small>
                    # {columnData.length}
                    <span style={{marginLeft: "6em"}}><b>{numPossibleContracts === 0 ? 0 : Math.round(100 * numActiveContracts / numPossibleContracts)}%</b> {t('Occupation')}</span>
                </small>
        },
        {
            dataField: 'valueHierarchy',
            text: `${t('properties-header-Value')}`,
            sort: true,
            headerAlign: "center",
            align: "center",
            headerStyle: (colum, colIndex) => {
                return {width: '100px', minWidth: '100px'};
            },
            headerFormatter: orderFormatter,
            formatter: (cell, row) => (
                <div>
                    {
                        cell
                            ? <Money number={cell} currency={currency}/>
                            : <></>
                    }
                </div>
            ),
            footerAlign: 'center',
            footer: () => <small><Money number={totalValue} currency={currency}/></small>
        },
        {
            dataField: 'contractAmountHierarchy',
            text: `${t('properties-header-Contract')}`,
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerStyle: (colum, colIndex) => {
                return {width: '100px', minWidth: '100px'};
            },
            headerFormatter: (column) => (
                <>
                    {column.text}
                    <hr className={"divider"}/>
                    <small style={{color: "DarkGray"}}>{t('Gross')} / {t('Net')}</small>
                </>
            ),
            formatter: (cell, row) => (
                <div>
                    {
                        cell
                            ? <>
                                <Money number={cell} currency={currency}/>
                                {
                                    cell !== row.contractNetAmountHierarchy
                                        ? <>
                                            <hr className="divider"/>
                                            <Money number={row.contractNetAmountHierarchy} currency={currency}/>
                                        </>
                                        : <></>
                                }
                            </>
                            : <></>
                    }
                </div>
            ),
            footerAlign: 'center',
            footer: () =>
                <small>
                    <Money number={totalContractAmount} currency={currency}/>
                    {
                        totalContractAmount !== totalContractNetAmount &&
                        <>
                            <hr className={"divider"}/>
                            <Money number={totalContractNetAmount} currency={currency}/>
                        </>
                    }
                </small>
        },
        {
            dataField: 'grossProfit',
            text: `${t('properties-header-Profit')}`,
            sort: true,
            headerAlign: 'center',
            align: 'center',
            headerFormatter: (column) => (
                <div data-tip data-for="profitTip">
                    <>
                        {column.text}
                        <hr className={"divider"}/>
                        <small style={{color: "DarkGray"}}>{t('Gross')} / {t('Net')}</small>
                    </>
                    <ReactTooltip id="profitTip" place="top" effect="solid">
                        Profit = <br/> Contract * 12 / (Value + Expenses)
                    </ReactTooltip>
                </div>
            ),
            formatter: (cell, row, rowIndex) => (
                <>
                    {
                        cell &&
                        <>
                            {getNumber(cell)} %
                            {
                                cell != row.netProfit &&
                                <>
                                    <hr className={"divider"}/>
                                    {getNumber(row.netProfit)} %
                                </>
                            }
                        </>}
                </>
            ),
            headerStyle: (colum, colIndex) => {
                return {width: '120px', minWidth: '120px'};
            },
            footerAlign: 'center',
            footer: () =>
                <small>
                    {
                        totalGrossProfit
                            ? <>
                                {getNumber(totalGrossProfit)} %
                                {
                                    totalGrossProfit != totalNetProfit &&
                                    <>
                                        <hr className={"divider"}/>
                                        {getNumber(totalNetProfit)} %
                                    </>
                                }
                            </>
                            : <></>
                    }
                </small>
        },
        {
            dataField: 'expensesAmountYearHierarchy',
            text: `${t('properties-header-Expenses')}`,
            sort: true,
            headerAlign: 'center',
            align: 'center',
            headerFormatter: orderFormatter,
            formatter: (cell, row, rowIndex) => (
                <>
                    {
                        row.expensesAmountHierarchy > 0 &&
                        <>
                            {
                                row.expensesAmountHierarchy === cell
                                    ? <Money number={cell} currency={currency}/>
                                    : <>
                                        <div><small><b>{new Date().getFullYear()}: </b></small><Money number={cell} currency={currency}/></div>
                                        <hr className={"divider"}/>
                                        <div><small><b>Total: </b><Money number={row.expensesAmountHierarchy} currency={currency}/></small></div>
                                    </>
                            }
                        </>
                    }
                </>
            ),
            headerStyle: (colum, colIndex) => {
                return {width: '125px', minWidth: '125px'};
            },
            footerAlign: 'center',
            footer: columnData =>
                <small>
                    {
                        (totalExpenses && totalExpenses > 0)
                            ? <>
                                {
                                    totalExpenses === totalExpensesYear
                                        ? <Money number={totalExpenses} currency={currency}/>
                                        : <>
                                            <div><b>{new Date().getFullYear()}: </b><Money number={totalExpensesYear} currency={currency}/></div>
                                            <hr className={"divider"}/>
                                            <div><b>Total: </b><Money number={totalExpenses} currency={currency}/></div>
                                        </>
                                }
                            </>
                            : <></>
                    }
                </small>
        }
    ];

    let numActiveContracts = 0;
    let numPossibleContracts = 0;
    let totalValue = 0;
    let totalContractAmount = 0;
    let totalContractNetAmount = 0;
    let totalExpenses = 0;
    let totalExpensesYear = 0;

    params.properties.forEach(p => {
        numActiveContracts += p.numActiveContractsHierarchy || 0;
        numPossibleContracts += p.numPossibleContractsHierarchy || 0;
        totalValue += p.valueHierarchy || 0;
        totalContractAmount += p.contractAmountHierarchy || 0;
        totalContractNetAmount += p.contractNetAmountHierarchy || 0;
        totalExpenses += p.expensesAmountHierarchy || 0;
        totalExpensesYear += p.expensesAmountYearHierarchy || 0;

        p.grossProfit = getProfit(p.valueHierarchy, p.contractAmountHierarchy, p.expensesAmountHierarchy);
        p.netProfit = getProfit(p.valueHierarchy, p.contractNetAmountHierarchy, p.expensesAmountHierarchy);
    });
    const totalGrossProfit = getProfit(totalValue, totalContractAmount, totalExpenses);
    const totalNetProfit = getProfit(totalValue, totalContractNetAmount, totalExpenses);

    const propWithCurrency = params.properties.find(prop => prop.currency != null);
    const currency = propWithCurrency ? propWithCurrency.currency.name : 'Euro';

    return (
        <div>
            {
                (!params.properties || params.properties.length < 1)
                    ? <><br/><h3 className="no-data-to-display">{t('No properties found')}</h3><br/></>
                    : <BootstrapTable
                        condensed={true}
                        keyField='id'
                        data={params.properties}
                        columns={columns}
                        rowEvents={rowEvents}
                        hover={true}
                        bordered={false}
                        defaultSorted={defaultSortedFinanceDetailsTable}
                        wrapperClasses={"table-responsive"}/>
            }
        </div>
    );
}


export default PropertiesTable;
