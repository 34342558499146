import React from "react";
import './Contact.css';
import {MDBIcon} from "mdbreact";
import {useTranslation} from "react-i18next";

function Contact() {
    const {t} = useTranslation();
    return (
        <section id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title mb-50">
                            <h4>{t('home-Contacts')}</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-2">
                            <div className="row">
                                <div className="icon col-md-1"><MDBIcon icon="phone-alt" size="3x"/></div>
                                <div className="info col-md-8"><p>+351 910 567 382<br/>+351 910 567 382</p></div>
                            </div>
                            <div className="row" hidden={true}>
                                <div className="icon col-md-1"><MDBIcon icon="map-marker-alt" size="3x"/></div>
                                <div className="info col-md-8"><p>Some AddressAddressAddress AddressAddressAddress</p></div>
                            </div>
                            <div className="row">
                                <div className="icon col-md-1"><a href="https://www.buymeacoffee.com/miguelduque"><MDBIcon icon="envelope" size="3x"/></a></div>
                                <div className="info col-md-8"><p><a href="https://myduque.org/ ">koncrete.global@gmail.com</a></p></div>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
