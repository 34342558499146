import {jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {handleResponse} from "../_helpers/handle-response";
import {BehaviorSubject} from "rxjs";

const userEntityAccessMapSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('userEntityAccess')));

export const userEntityService = {
    addUser,
    updateEntityUser,
    removeUser,
    getUsers,
    setUserEntityAccessMap,
    getUserEntityAccess,
    hasAccesses
};

function hasAccesses() {
    const userEntityAccess = userEntityAccessMapSubject.value;
    return userEntityAccess !== undefined && userEntityAccess !== null;
}

function getUserEntityAccess(entityId) {
    const userEntityAccess = userEntityAccessMapSubject.value;
    if (userEntityAccess) {
        const objToMap = (o) => new Map(Object.entries(o));
        const map = objToMap(userEntityAccess);
        return map.get(entityId.toString());
    }
    return null;
}

function setUserEntityAccessMap(userEntityAccessMap) {
    if (userEntityAccessMap === 'undefined' || userEntityAccessMap === undefined || !userEntityAccessMap) {
        localStorage.setItem('currentEntity', '{}');
    } else {
        localStorage.setItem('userEntityAccess', JSON.stringify(userEntityAccessMap));
    }
}

function addUser(entityId, email, accessTypeId) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify({email, accessTypeId})
    };
    return fetch(SERVER_URL + `/entities/${entityId}/users`, requestOptions).then(handleResponse);
}

function updateEntityUser(entityId, userId, accessTypeId) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify({accessTypeId})
    };
    return fetch(SERVER_URL + `/entities/${entityId}/users/${userId}`, requestOptions).then(handleResponse);
}

function removeUser(entityId, userId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/users/${userId}`, requestOptions).then(handleResponse);
}

function getUsers(entityId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/users`, requestOptions).then(handleResponse);
}
