import React from 'react';
import {tenantService} from "../../../_services";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {validationSchema} from "../validation";
import {successToast} from "../../../_helpers/toast-functions";
import './../TenantForm.css';
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

class CreateTenant extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('tenantForm-New Tenant')}</h4>
                        </div>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        phone: '',
                        fiscalNumber: '',
                        note: '',
                        address: '',
                        postalCode: ''
                    }}
                    validationSchema={Yup.object().shape(validationSchema)}
                    onSubmit={({name, email, phone, fiscalNumber, note, address, postalCode}, {setStatus, setSubmitting}) => {
                        setStatus();
                        tenantService.createTenant(entityId, name, email, phone, fiscalNumber, note, address, postalCode)
                            .then(
                                response => {
                                    successToast(`${t('toast-Tenant successfully created')}!`);
                                    this.props.history.goBack();
                                },
                                error => {
                                    setSubmitting(false);
                                    setStatus(error);
                                }
                            );

                    }}
                    render={({errors, status, touched, isSubmitting}) => (
                        <Form>
                            <div className="form-group required">
                                <label htmlFor="name" className="control-label">{t('tenantForm-Name')}</label>
                                <Field name="name" type="text"
                                       className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                                {status && status.name &&
                                <div className="text-danger invalid-input">
                                    {t('tenantForm-Name')} {status.name}
                                </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="email" className="control-label">{t('tenantForm-Email')}</label>
                                <Field name="email" type="text"
                                       className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                {status && status.email && status.email &&
                                <div className="text-danger invalid-input">
                                    {t('tenantForm-Email')} {status.email}
                                </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="phone">{t('tenantForm-Phone')}</label>
                                <Field name="phone" type="text"
                                       className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}/>
                                <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                {status && status.phone &&
                                <div className="text-danger invalid-input">
                                    {t('tenantForm-Phone')} {status.phone}
                                </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="fiscalNumber">{t('tenantForm-Fiscal Number')}</label>
                                <Field name="fiscalNumber" type="text"
                                       className={'form-control' + (errors.fiscalNumber && touched.fiscalNumber ? ' is-invalid' : '')}/>
                                <ErrorMessage name="fiscalNumber" component="div" className="invalid-feedback"/>
                                {status && status.fiscalNumber &&
                                <div className="text-danger invalid-input">
                                    {t('tenantForm-Fiscal Number')} {status.fiscalNumber}
                                </div>
                                }
                            </div>

                            <div className="form-group">
                                <label htmlFor="note">{t('tenantForm-Notes')}</label>
                                <Field name="note" component="textarea"
                                       className={'form-control' + (errors.note && touched.note ? ' is-invalid' : '')}/>
                                <ErrorMessage name="note" component="div" className="invalid-feedback"/>
                                {status && status.note &&
                                <div className="text-danger invalid-input">
                                    {t('tenantForm-Notes')} {status.note}
                                </div>
                                }
                            </div>

                            <div className="address-box">
                                <div className={'alert alert-info'}>
                                    <b>Info: </b> {t('tenantForm-Address information is')} <strong>{t('tenantForm-not')}</strong> {t('tenantForm-required. If not specified, the address of the tenant\'s contract will be used')}.
                                    {t('tenantForm-This will only be used to generate invoices')}.
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">{t('tenantForm-Address')}</label>
                                    <Field name="address" type="text"
                                           className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="address" component="div" className="invalid-feedback"/>
                                    {status && status.address &&
                                    <div className="text-danger invalid-input">
                                        {t('tenantForm-Address')} {status.address}
                                    </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="postalCode">{t('tenantForm-Postal Code')}</label>
                                    <Field name="postalCode" type="text"
                                           className={'form-control' + (errors.postalCode && touched.postalCode ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="postalCode" component="div" className="invalid-feedback"/>
                                    {status && status.postalCode &&
                                    <div className="text-danger invalid-input">
                                        {t('tenantForm-Postal Code')} {status.postalCode}
                                    </div>
                                    }
                                </div>
                            </div>

                            <div className="form-group mt-3">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>{t('Create')}
                                </button>
                                {isSubmitting &&
                                <img
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                }
                            </div>
                            {status && status.message &&
                            <div>
                                <div className={'alert alert-danger'}>{status.message}</div>
                            </div>
                            }
                        </Form>
                    )}
                />
            </div>
        )
    }
}

export default withTranslation()(CreateTenant);
