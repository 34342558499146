import React from 'react';

import {authenticationService} from '../../../_services/authentication.service';
import {infoToast, successToast} from "../../../_helpers/toast-functions";
import {Form} from "formik";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

class ActivateAccount extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        if (authenticationService.currentUserValue) {
            infoToast(`${t('toast-You are already logged in')}.`);
            this.props.history.push('/');
        }
        this.state = {
            message: `${t('activateAccount-Please wait while we verify your activation token')}.`
        };
    }

    componentDidMount() {
        const queryParams = require('query-string').parse(this.props.location.search);

        authenticationService.activateAccount(queryParams.email, queryParams.token)
            .then(
                response => {
                    successToast(response.message);
                    this.props.history.push('/login');
                },
                error => this.setState({message: error.message})
            );
    }

    render() {
        const {t} = this.props;
        return (
            <div className="auth-div">
                <Navigation/>
                <img className="auth-background-image" src="/background-image-street-light.jpg" alt=""/>
                <div className="auth-content">
                    <div className="auth-container">
                        <div className="col-md-5 ml-auto mr-auto">
                            <div className="auth-card box card-plain">
                                <Form className="form" method="" action="">
                                    <div className="card-header text-center">
                                        <div className="auth-logo-container">
                                            <img src="/logo.svg"/>
                                            <h2><b>Koncrete</b></h2>
                                        </div>
                                    </div>
                                    <div className="card-footer auth-card-footer" style={{"text-align": "center"}}>
                                        <h4>{this.state.message}</h4>
                                        <small>{t('activateAccount-If your problem persists, please contact')}
                                            <a href="mailto:koncrete.global@gmail.com" style={{"color": "#00FFFF"}}> koncrete.global@gmail.com</a>
                                        </small>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ActivateAccount);
