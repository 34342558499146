export function getIcon(propertyTypeId?: number) {
    switch (propertyTypeId) {
        case 1:
            return "home";
        case 2:
            return "building";
        case 3:
            return "couch";
        case 4:
            return "bed";
        case 5:
            return "car-side";
        case 6:
            return "warehouse";
        case 7:
            return "tree";
        case 8:
            return "question";
        default:
            return "home";
    }
}