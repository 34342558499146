import React from "react";
import cellEditFactory, {Type} from "react-bootstrap-table2-editor";
import {userEntityService} from "../../../../../_services/userEntity.service";
import AddUser from "./AddUser";
import BootstrapTable from "react-bootstrap-table-next";
import {lookupValuesService} from "../../../../../_services";
import {permissionService} from "../../../../../_services/permission.service";
import {ConfirmDeleteUser} from "./ConfirmDeleteUser";
import {withTranslation} from "react-i18next";

class PermissionsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            accessTypes: [],
            usersTable: this.getUsersTable([])
        }
        this.updateUsers = this.updateUsers.bind(this);
        this.beforeSaveCell = this.beforeSaveCell.bind(this);
    }

    getUsersTable(accessTypes) {
        const entityId = this.props.ids.entityId;
        const {t} = this.props;
        return [
            {
                dataField: 'id',
                text: 'Id',
                hidden: true
            },
            {
                dataField: 'firstName',
                text: `${t('permissions-First Name')}`,
                sort: true,
                editable: false,
                headerStyle: (colum, colIndex) => ({width: '150px', minWidth: '150px'})
            },
            {
                dataField: 'lastName',
                text: `${t('permissions-Last Name')}`,
                sort: true,
                editable: false,
                headerStyle: (colum, colIndex) => ({width: '150px', minWidth: '150px'})
            },
            {
                dataField: 'email',
                text: `${t('permissions-Email')}`,
                sort: true,
                editable: false,
                headerStyle: (colum, colIndex) => ({width: '350px', minWidth: '350px'})
            },
            {
                dataField: 'accessType.name',
                text: `${t('permissions-Access')}`,
                sort: true,
                editable: permissionService.hasEditAccess(entityId),
                headerStyle: (colum, colIndex) => ({width: '120px', minWidth: '120px'}),
                editor: {
                    type: Type.SELECT,
                    options: accessTypes.map(at => ({value: at.name, label: at.name})),
                }
            }, {
                dataField: '',
                text: '',
                editable: false,
                headerStyle: (colum, colIndex) => ({width: '40px', minWidth: '40px'}),
                formatter:
                    (cell, row, rowIndex) => (
                        <div align="center">
                            {
                                permissionService.hasDeleteAccess(entityId) &&
                                <ConfirmDeleteUser entityId={entityId} userId={row.id} updateUsers={this.updateUsers}/>
                            }
                        </div>
                    )
            }
        ];
    }

    beforeSaveCell(oldValue, newValue, row, column, done) {
        const newAccessTypeId = this.state.accessTypes.find(at => at.name === newValue).id;
        const entityId = this.props.ids.entityId;
        userEntityService.updateEntityUser(entityId, row.id, newAccessTypeId)
            .then(
                response => {
                    done(true);
                },
                error => {
                    done(false);
                }
            );
        return {async: true};
    }

    componentDidMount() {
        lookupValuesService.getAccessTypes().then(response => this.setState({accessTypes: response, usersTable: this.getUsersTable(response)}))
        userEntityService.getUsers(this.props.ids.entityId).then(this.updateUsers);
    }

    updateUsers(users) {
        this.setState({users});
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-xl-12 mx-auto text-right section-title">
                        <div className="links">
                            {
                                <AddUser history={this.props.history} ids={this.props.ids} updateUsers={this.updateUsers} users={this.state.users} accessTypes={this.state.accessTypes}/>
                            }
                        </div>
                    </div>
                </div>
                <BootstrapTable
                    keyField='id'
                    data={this.state.users}
                    columns={this.state.usersTable}
                    cellEdit={cellEditFactory({
                            mode: 'click',
                            blurToSave: true,
                            beforeSaveCell: this.beforeSaveCell,
                        }
                    )}
                    hover={true}
                    bordered={false}
                    wrapperClasses={"table-responsive"}/>
            </>
        );
    }
}

export default withTranslation()(PermissionsSection);
