import React from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {successToast} from "../../../../../_helpers/toast-functions";
import {MDBIcon} from "mdbreact";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {userEntityService} from "../../../../../_services/userEntity.service";
import {withTranslation} from "react-i18next";

class AddUser extends React.Component {
    constructor(props) {
        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            accessType: null,
            show: false
        };
    }

    componentDidMount() {
    }

    handleClose() {
        this.setState({show: false});
    }

    handleShow() {
        this.setState({show: true});
    }

    changeAccessType = (event) => {
        this.setState({
            accessType: event.target.value
        });
    };

    render() {
        const {t} = this.props;
        return (
            <>
                <a onClick={this.handleShow}>
                    <p><MDBIcon icon="plus"/> {t('permissionsForm-New User')}</p>
                </a>

                <Modal show={this.state.show} onHide={this.handleClose} animation={false}>

                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .required(`${t('permissionsForm-Email is required')}`)
                                .email(`${t('permissionsForm-Must be a valid email')}`)
                        })}
                        onSubmit={({email}, {setStatus, setSubmitting}) => {
                            const entityId = this.props.ids.entityId;
                            if (this.state.accessType == null) {
                                const accessTypeError = {"accessType": `${t('permissionsForm-Access Type is a required field')}`};
                                setStatus(accessTypeError);
                                setSubmitting(false);
                            } else {
                                userEntityService.addUser(entityId, email, this.state.accessType.id)
                                    .then(
                                        response => {
                                            setStatus();
                                            successToast(`${t('permissionsForm-User successfully added')}!`);
                                            this.props.updateUsers(response);
                                            this.handleClose();
                                        },
                                        error => {
                                            const emailError = {"email": error.message};
                                            setStatus(emailError);
                                            setSubmitting(false);
                                        }
                                    );
                            }
                        }}
                        render={({errors, status, touched, isSubmitting}) => (
                            <Form>
                                <Modal.Header>
                                    <h3>{t('permissionsForm-Add a new user to you entity')}</h3>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="form-group required">
                                        <label htmlFor="email" className="control-label">{t('permissionsForm-Email')}</label>
                                        <Field name="email" type="text"
                                               className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                        {
                                            status && status.email &&
                                            <div className="text-danger invalid-input">
                                                {status.email}
                                            </div>
                                        }
                                    </div>

                                    <div className="form-group required">
                                        <label htmlFor="accessType" className="control-label">{t('permissionsForm-Access Type')}</label> <br/>
                                        <ComboBox
                                            data={this.props.accessTypes}
                                            textField="name"
                                            dataItemKey="id"
                                            value={this.props.accessType}
                                            onChange={this.changeAccessType}
                                        />
                                        <ErrorMessage name="accessType" component="div" className="invalid-feedback"/>
                                        {
                                            status && status.accessType &&
                                            <div className="text-danger invalid-input">{status.accessType}</div>
                                        }
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleClose}>
                                        Cancel
                                    </Button>
                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Create
                                    </button>
                                    {
                                        isSubmitting &&
                                        <img
                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                    }
                                </Modal.Footer>
                            </Form>
                        )}
                    />

                </Modal>
            </>
        );
    }
}

export default withTranslation()(AddUser);
