import {handleResponse} from "../_helpers/handle-response";
import {authHeader, jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {getCountEmptyAttributes, getNumberCleanValue, getObjectCleanId, getPrimitiveCleanValue, getNumberCleanValueIfParentNotNull} from "../_helpers/util-functions";


export const propertyService = {
    getAll,
    getAllSummary,
    getProperty,
    getPropertyOverview,
    getPropertySummary,
    deleteProperty,
    createProperty,
    updateProperty,
    cleanPropertyBaseInformation,
    cleanAdditionalInformation,
    cleanTaxInformation,
    uploadFile,
    getFileUrl
};

function getAll(entityId, parentPropertyId) {
    let url = SERVER_URL + `/entities/${entityId}/properties`;
    if (parentPropertyId != null) {
        url += "?parentPropertyId=" + parentPropertyId;
    }
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(url, requestOptions).then(handleResponse);
}

function getAllSummary(entityId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/summary`, requestOptions).then(handleResponse);
}

function getProperty(entityId, propertyId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}`, requestOptions).then(handleResponse);
}

function getPropertyOverview(entityId, propertyId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/overview`, requestOptions).then(handleResponse);
}

function getPropertySummary(entityId, propertyId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/summary`, requestOptions).then(handleResponse);
}

function deleteProperty(entityId, propertyId) {
    const requestOptions = {method: 'DELETE', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}`, requestOptions).then(handleResponse);
}

function createProperty(entityId, parentProperty, baseInfo, propertyTaxInformation, propertyDetails) {
    let attributes = {baseInfo, propertyTaxInformation, propertyDetails};
    if (parentProperty != null) {
        attributes["parentPropertyId"] = parentProperty.id;
    }

    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(attributes)
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties`, requestOptions).then(handleResponse);
}

function updateProperty(entityId, propertyId, parentProperty, baseInfo, propertyTaxInformation, propertyDetails) {
    let attributes = {baseInfo, propertyTaxInformation, propertyDetails};
    if (parentProperty != null) {
        attributes["parentPropertyId"] = parentProperty.id;
    } else {
        attributes["parentPropertyId"] = null;
    }

    const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(attributes)
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}`, requestOptions).then(handleResponse);
}

function cleanPropertyBaseInformation(addressId, propertyTypeId, name, code, description, areaMeters, value, currency) {
    let attributes = {addressId, propertyTypeId};

    attributes["name"] = getPrimitiveCleanValue(name);
    attributes["code"] = getPrimitiveCleanValue(code);
    attributes["description"] = getPrimitiveCleanValue(description);
    attributes["areaMeters"] = getNumberCleanValue(areaMeters);
    attributes["value"] = getNumberCleanValue(value);
    attributes["currencyId"] = getObjectCleanId(currency);
    return attributes;
}

function cleanAdditionalInformation(
    hasHeating, hasAirCondition, isFurnished, numBathrooms, numToilets, kitchenType, kitchenAreaMeters, outsideAreaType, outsideAreaMeters, numBalconies, numBedrooms, numSuits,
    hasLaundry, hasStorageRoom, storageRoomAreaMeters, hasLivingRoom, livingRoomAreaMeters, hasDiningRoom, diningRoomAreaMeters, numParkingInside, numParkingOutside
) {
    let attributes = {};

    attributes["heating"] = hasHeating;
    attributes["airCondition"] = hasAirCondition;
    attributes["furnished"] = isFurnished;
    attributes["numBathrooms"] = getNumberCleanValue(numBathrooms);
    attributes["numToilets"] = getNumberCleanValue(numToilets);
    attributes["kitchenTypeId"] = getObjectCleanId(kitchenType);
    attributes["kitchenAreaMeters"] = getNumberCleanValueIfParentNotNull(attributes["kitchenTypeId"], kitchenAreaMeters);
    attributes["outsideAreaTypeId"] = getObjectCleanId(outsideAreaType);
    attributes["outsideAreaMeters"] = getNumberCleanValueIfParentNotNull(attributes["outsideAreaTypeId"], outsideAreaMeters);
    attributes["numBalconies"] = getNumberCleanValue(numBalconies);
    attributes["numBedrooms"] = getNumberCleanValue(numBedrooms);
    attributes["numSuits"] = getNumberCleanValue(numSuits);
    attributes["laundry"] = hasLaundry;
    attributes["storageRoom"] = hasStorageRoom;
    attributes["storageRoomAreaMeters"] = getNumberCleanValueIfParentNotNull(attributes["storageRoom"], storageRoomAreaMeters);
    attributes["livingRoom"] = hasLivingRoom;
    attributes["livingRoomAreaMeters"] = getNumberCleanValueIfParentNotNull(attributes["livingRoom"], livingRoomAreaMeters);
    attributes["diningRoom"] = hasDiningRoom;
    attributes["diningRoomAreaMeters"] = getNumberCleanValueIfParentNotNull(attributes["diningRoom"], diningRoomAreaMeters);
    attributes["numParkingInside"] = getNumberCleanValue(numParkingInside);
    attributes["numParkingOutside"] = getNumberCleanValue(numParkingOutside);

    if (Object.entries(attributes).length !== getCountEmptyAttributes(attributes)) {
        return attributes;
    } else {
        return null;
    }
}

function cleanTaxInformation(propertyBusinessType, propertyLocationType, locationCode, articleCode, fractionCode) {
    let attributes = {};

    attributes["propertyBusinessTypeId"] = getObjectCleanId(propertyBusinessType);
    attributes["propertyLocationTypeId"] = getObjectCleanId(propertyLocationType);
    attributes["locationCode"] = getPrimitiveCleanValue(locationCode);
    attributes["articleCode"] = getPrimitiveCleanValue(articleCode);
    attributes["fractionCode"] = getPrimitiveCleanValue(fractionCode);

    if (Object.entries(attributes).length !== getCountEmptyAttributes(attributes)) {
        return attributes;
    } else {
        return null;
    }
}

function uploadFile(entityId, propertyId, formData) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken()},
        body: formData
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/files`, requestOptions).then(handleResponse);
}

function getFileUrl(entityId, propertyId, fileId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/files/${fileId}`, requestOptions).then(handleResponse);
}


