import React from "react";
import cogoToast from "cogo-toast";

const toastProperties = {position: "top-right", hideAfter: 5};

export function successToast(message) {
    cogoToast.success(message, toastProperties);
}

export function infoToast(message) {
    cogoToast.info(message, toastProperties);
}

export function warningToast(message) {
    cogoToast.warn(message, toastProperties);
}

export function errorToast(message) {
    cogoToast.error(message, toastProperties);
}
