import React from "react";
import {orderFormatter} from "../../../_helpers/util-functions";
import BootstrapTable from "react-bootstrap-table-next";
import {MDBIcon} from "mdbreact";
import {Card, CardBody, Collapse} from "reactstrap";
import {withTranslation} from "react-i18next";

class ContractTenants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        }
    }

    rowEvents = {
        onClick: (e, row, rowIndex) => {
            let entityId = this.props.entityId;
            const tenantUrl = `/entities/${entityId}/tenants/${row.id}`;
            this.props.history.push(tenantUrl);
        }
    };

    toggle() {
        return () => this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        const {t} = this.props;
        const columns = [{
            dataField: 'id',
            text: 'Id',
            hidden: true
        }, {
            dataField: 'name',
            text: `${t('tenants-header-Name')}`,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {width: '15%'};
            },
            headerFormatter: orderFormatter
        }, {
            dataField: 'phone',
            text: `${t('tenants-header-Phone')}`,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {width: '13%'};
            },
            headerFormatter: orderFormatter
        }, {
            dataField: 'email',
            text: `${t('tenants-header-Email')}`,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {width: '18%'};
            },
            headerFormatter: orderFormatter
        }, {
            dataField: 'fiscalNumber',
            text: `${t('tenants-header-Fiscal Number')}`,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {width: '14%'};
            },
            headerFormatter: orderFormatter
        }, {
            dataField: 'note',
            text: `${t('tenants-header-Note')}`,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {width: '40%'};
            },
            headerAlign: 'center'
        }];


        return (
            <div id="tenants" className="inner-list">
                <div className="section-title text-center">
                    <h4></h4>
                </div>
                <br/>

                <div className="card">

                    <div className="card-header" role="tab" id="headingThree" style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}} onClick={this.toggle()}>
                        <div id="folder" className="row">
                        <span className="mt-1 mb-0 col-md-10">
                            {t('contract-Tenants')}
                        </span>
                            {
                                this.state.isOpen
                                    ? <MDBIcon icon="angle-up col-md-2 text-right" size="2x"/>
                                    : <MDBIcon icon="angle-down col-md-2 text-right" size="2x"/>
                            }
                        </div>
                    </div>

                    <Collapse isOpen={this.state.isOpen}>
                        <Card>
                            <CardBody>
                                <BootstrapTable
                                    keyField='id'
                                    data={this.props.tenants || []}
                                    columns={columns}
                                    rowEvents={this.rowEvents}
                                    hover={true}
                                    bordered={false}/>
                                {
                                    (!this.props.tenants || this.props.tenants.length === 0) &&
                                    <h3 className="no-data-to-display">{t('contract-No tenants')}</h3>
                                }
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ContractTenants);
