import React, {useState} from 'react';
import {Card, CardBody, Collapse} from 'reactstrap';
import {MDBIcon} from "mdbreact";
import "./FinanceDetailsAddress.css";
import BootstrapTable from "react-bootstrap-table-next";
import {areDatesInSameMonth, getStringDateInCurrentTimeZone, isFirstDateInSameMonthOrBeforeSecondDate} from "../../../../../_helpers/date-functions";
import {dateService} from "../../../../../_services/date.service";
import {useTranslation} from "react-i18next";
import {getNumber, Money} from "../../../../../_helpers/NumberUtils";

const PROPERTY_CLICK = "PROPERTY_CLICK";
const EXPENSES_CLICK = "EXPENSES_CLICK";
const CREATE_NEW_EXPENSE_CLICK = "CREATE_NEW_EXPENSE_CLICK";
const PAY_CLICK = "PAY_CLICK";
const CREATE_NEW_RENT_CLICK = "CREATE_NEW_RENT_CLICK";

const FinanceDetailsAddress = (props) => {
    const [isOpen, setIsOpen] = useState(true);

    const {t} = useTranslation();

    const toggle = () => setIsOpen(!isOpen);

    const expandFinanceDetailsRow = {
        renderer: row => (
            <>
                {row.tenants.map(tenant =>
                    <div className="mb-4">
                        <label className="ml-5">{tenant.name}</label>
                        <label className="ml-5">{tenant.phone}</label>
                        <label className="ml-5">{tenant.email}</label>
                        <label className="ml-5">{tenant.note}</label>
                    </div>
                )}
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({isAnyExpands}) => {
            return <b></b>;
        },
        expandColumnRenderer: ({expanded}) => {
            if (expanded) {
                return (
                    <MDBIcon icon="angle-up"/>
                );
            }
            return (
                <MDBIcon icon="angle-down"/>
            );
        }
    };

    const defaultSortedFinanceDetailsTable = [
        {
            dataField: 'propertyId',
            order: 'asc'
        },
        {
            dataField: 'contractId',
            order: 'asc'
        }
    ];

    const financeDetailsTable = [
        {
            dataField: 'id',
            text: 'Id',
            hidden: true,
            sort: true
        },
        {
            dataField: 'propertyId',
            text: 'propertyId',
            hidden: true,
            sort: true
        },
        {
            dataField: 'contractId',
            text: 'contractId',
            hidden: true,
            sort: true
        },
        {
            dataField: 'addressFloor',
            text: `${t('overview-Property')}`,
            headerAlign: "center",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {width: '150px', minWidth: '150px'};
            },
            formatter: (cell, row, rowIndex) => (
                <a onClick={e => contractsRowEvents.onClick(e, row, rowIndex, PROPERTY_CLICK)}>
                    <small>
                        {cell}
                        {cell && row.propertyName && " - "}
                        {row.propertyName}
                        {!cell && !row.propertyName && row.propertyType}
                    </small>
                </a>
            ),
            footer: columnData => '# ' + columnData.length
        },
        {
            dataField: 'lastRentDueDate',
            text: `${t('overview-Last Rent')}`,
            align: 'center',
            headerAlign: 'center',
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return (
                    <>
                        <div className={cell && areDatesInSameMonth(cell, props.financeDetailsEndDate) ? 'good' : 'danger'}>
                            {getStringDateInCurrentTimeZone(cell)}
                        </div>
                        {
                            row.rentIdMissingPayment &&
                            <a onClick={e => contractsRowEvents.onClick(e, row, rowIndex, PAY_CLICK)}
                               style={{color: "rgb(0, 98, 204)"}}>
                                <small>{t('overview-Pay')}</small>
                            </a>
                        }
                        {
                            row.contractId && !row.rentIdMissingPayment && !areDatesInSameMonth(cell, props.financeDetailsEndDate) &&
                            <a onClick={e => contractsRowEvents.onClick(e, row, rowIndex, CREATE_NEW_RENT_CLICK)}
                               style={{color: "rgb(0, 98, 204)"}}>
                                <small>+ {t('overview-Create New')}</small>
                            </a>
                        }
                    </>
                );
            },
            headerStyle: (colum, colIndex) => {
                return {width: '100px', minWidth: '90px'};
            },
            footer: ''
        },
        {
            dataField: 'contractAmountInfo',
            text: `${t('overview-Contract')}`,
            align: 'center',
            headerAlign: 'center',
            sort: true,
            formatter: (cell, row) => (
                <>
                    {
                        cell && cell.value &&
                        <div>
                            <Money number={cell.value} currency={cell.currency}/><br/>
                            <small className={row.contractEndDate && isFirstDateInSameMonthOrBeforeSecondDate(row.contractEndDate, props.financeDetailsEndDate) ? 'danger' : ''}>
                                {getStringDateInCurrentTimeZone(row.contractEndDate)}
                            </small>
                        </div>
                    }
                </>
            ),
            headerStyle: (colum, colIndex) => {
                return {width: '100px', minWidth: '90px'};
            },
            footerAlign: 'center',
            footer: (columnData) => {
                const total = columnData.reduce((acc, item) => acc + item.value, 0);
                const findCurrencyObj = columnData.find(element => element.currency != null);
                const currency = findCurrencyObj ? findCurrencyObj.currency : null;
                return <Money number={total} currency={currency}/>;
            }
        },
        {
            dataField: 'rentsPaymentPeriodInfo',
            text: `${props.oneMonthSummary ? `${t(dateService.getMonthName(props.financeDetailsEndDate))}` : ''} ${t('overview-Payment')}`,
            align: 'center',
            headerAlign: 'center',
            formatter: (cell, row, rowIndex) => {
                return (
                    <>
                        {
                            (!cell.toPay || cell.toPay === 0) ? <></> :
                                <>
                                    <b className={cell.paid >= cell.toPay ? "good" : "danger"}><Money number={cell.paid} currency={cell.currency}/></b>
                                    {
                                        cell.paid !== cell.toPay &&
                                        <>
                                            <hr className={"divider"}/>
                                            <b><Money number={cell.toPay} currency={cell.currency}/></b>
                                        </>
                                    }
                                </>
                        }
                        {
                            (cell && cell.retained && cell.retained > 0)
                                ? <>
                                    <br/>
                                    <small style={{color: "DarkGray"}}>(<Money number={cell.retained} currency={cell.currency}/> {t('overview-retained')})</small>
                                </>
                                : <></>
                        }
                    </>
                );
            },
            headerStyle: (colum, colIndex) => {
                return {width: '140px', minWidth: '140px'};
            },
            footerAlign: 'center',
            footer: columnData => {
                const toPay = columnData.reduce((acc, item) => acc + item.toPay, 0);
                const paid = columnData.reduce((acc, item) => acc + item.paid, 0);
                const retained = columnData.reduce((acc, item) => acc + item.retained, 0);
                const findCurrencyObj = columnData.find(element => element.currency != null);
                const currency = findCurrencyObj ? findCurrencyObj.currency : null;
                return <>
                    {
                        (!toPay || toPay == 0) ? <></> :
                            <>
                                <div className={paid >= toPay ? "good" : "danger"}><Money number={paid} currency={currency}/></div>
                                {
                                    paid !== toPay &&
                                    <>
                                        <hr className={"divider"}/>
                                        <><Money number={toPay} currency={currency}/></>
                                    </>
                                }
                            </>
                    }
                    {
                        (retained && retained > 0)
                            ? <>
                                {paid !== toPay && <br/>}
                                <small style={{color: "DarkGray"}}>(<Money number={retained} currency={currency}/> retained)</small>
                            </>
                            : <></>
                    }
                </>;
            }
        },
        {
            dataField: 'contractDebtInfo',
            text: `${t('overview-Debt')}`,
            align: 'center',
            headerAlign: 'center',
            sort: true,
            formatter: (cell, row, rowIndex) => (
                <div className={'danger'}>
                    {
                        (cell && cell.toPay && cell.toPay != 0)
                            ? <b className={cell.toPay > 0 ? "danger" : "good"}>
                                <Money number={cell.toPay} currency={cell.currency}/>
                                <hr className={"divider"}/>
                                <small>{getNumber(Math.abs(cell.toPay / row.contractPaymentAmount))} {t('overview-rent(s)')}</small>
                            </b>
                            : <></>
                    }
                </div>
            ),
            headerStyle: (colum, colIndex) => {
                return {width: '100px', minWidth: '90px'};
            },
            footerAlign: 'center',
            footer: columnData => {
                const toPay = columnData.reduce((acc, item) => acc + (item.toPay > 0 ? item.toPay : 0), 0);
                const findCurrencyObj = columnData.find(element => element.currency != null);
                const currency = findCurrencyObj ? findCurrencyObj.currency : null;
                return <div className={'danger'}>
                    {
                        (toPay > 0 || toPay < 0) &&
                        <div className={'danger'}>
                            <Money number={toPay} currency={currency}/>
                        </div>
                    }
                </div>;
            }
        },
        {
            dataField: 'propertyExpenseAmountPeriodInfo',
            text: `${props.oneMonthSummary ? `${t(dateService.getMonthName(props.financeDetailsEndDate))}` : ''} ${t('overview-Expenses')}`,
            align: 'center',
            headerAlign: 'center',
            formatter: (cell, row, rowIndex) => (
                <>
                    {
                        cell && cell.value &&
                        <a onClick={e => contractsRowEvents.onClick(e, row, rowIndex, EXPENSES_CLICK)}>
                            <Money number={cell.value} currency={cell.currency}/><br/>
                        </a>
                    }
                    <a onClick={e => contractsRowEvents.onClick(e, row, rowIndex, CREATE_NEW_EXPENSE_CLICK)}
                       style={{color: "rgb(0, 98, 204)"}}>
                        <small>+ {t('overview-Create New')}</small>
                    </a>
                </>
            ),
            headerStyle: (colum, colIndex) => {
                return {width: '150px', minWidth: '150px'};
            },
            footerAlign: 'center',
            footer: columnData => {
                const total = columnData.reduce((acc, item) => acc + item.value, 0);
                const findCurrencyObj = columnData.find(element => element.currency != null);
                const currency = findCurrencyObj ? findCurrencyObj.currency : null;
                return <Money number={total} currency={currency}/>;
            }
        },
        {
            dataField: 'propertyExpenseNames',
            text: `${props.oneMonthSummary ? `${t(dateService.getMonthName(props.financeDetailsEndDate))}` : ''} ${t('overview-Notes')}`,
            headerStyle:
                (colum, colIndex) => {
                    return {width: '275px', minWidth: '275px'};
                },
            formatter:
                (cell, row, rowIndex) => (
                    <small>
                        {
                            cell
                            && <a onClick={e => contractsRowEvents.onClick(e, row, rowIndex, EXPENSES_CLICK)}>
                                <b>{t('overview-Expenses')}: </b>{cell}<br/>
                            </a>
                        }
                        {
                            row.rentsDescriptionPeriod
                            && <div><b>{t('overview-Rents')}: </b>{row.rentsDescriptionPeriod}<br/></div>
                        }
                    </small>
                ),
            footer: ''
        }];

    const contractsRowEvents = {
        onClick: (e, row, rowIndex, clickContext) => {
            e.stopPropagation();
            let url = `/entities/${props.entityId}`;
            if (clickContext === CREATE_NEW_EXPENSE_CLICK) {
                url += `/expense/new?propertyId=${row.propertyId}`;
            } else {
                url += `/properties/${row.propertyId}`;
                if (clickContext !== PROPERTY_CLICK) {
                    if (clickContext === EXPENSES_CLICK) {
                        url += "/#expenses";
                    } else if (row.contractId) {
                        url += `/contracts/${row.contractId}`;
                        if (clickContext === PAY_CLICK) {
                            url += `/rents/${row.rentIdMissingPayment}/pay`;
                        } else if (clickContext === CREATE_NEW_RENT_CLICK) {
                            url += `/rent/new`;
                        }
                    }
                }
            }
            props.history.push(url);
        }
    };

    return (
        <div>
            <br/>

            <div className="card">

                <div className="card-header" role="tab" id="headingThree" style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}} onClick={toggle}>
                    <div id="folder" className="row">
                        <span className="mt-1 mb-0 col-md-10">
                            {props.row[0].stringAddressStreetWithNumber}
                        </span>
                        {
                            isOpen
                                ? <MDBIcon icon="angle-up col-md-2 text-right" size="2x"/>
                                : <MDBIcon icon="angle-down col-md-2 text-right" size="2x"/>
                        }
                    </div>
                </div>

                <Collapse isOpen={isOpen}>
                    <Card>
                        <CardBody>
                            <BootstrapTable
                                key={`table-${[props.row[0].id]}`}
                                keyField='id'
                                data={props.row}
                                columns={financeDetailsTable}
                                expandRow={expandFinanceDetailsRow}
                                rowEvents={contractsRowEvents}
                                hover={true}
                                bordered={false}
                                defaultSorted={defaultSortedFinanceDetailsTable}
                                wrapperClasses={"table-responsive"}/>
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        </div>
    );
}

export default FinanceDetailsAddress;