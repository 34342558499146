import React, {useRef, useState} from 'react';
import {RouteComponentProps, useHistory} from "react-router";
import {Card, CardBody, Collapse} from 'reactstrap';
import {permissionService} from "../../../_services/permission.service";
import {MDBIcon} from "mdbreact";
import {useTranslation} from "react-i18next";
import {tenantService} from "../../../_services";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";
import {ConfirmDeleteTenant} from "./ConfirmDeleteTenant";
import TenantFiles from "./TenantFiles";
import {Contract} from "../../Contracts/ListContractsPage/ListContracts";
import {ContractsTable} from "../../Contracts/_aux/ContractsTable";
import ReactTooltip from "react-tooltip";
import {errorToast, infoToast, successToast} from "../../../_helpers/toast-functions";

export interface Tenant {
    id: number,
    name: string,
    email: string,
    phone: string,
    fiscalNumber: string,
    address: string,
    postalCode: string,
    note: string,
    contracts: Contract[]
}

export const TenantPage = ({match}: RouteComponentProps<Props>) => {
    const entityId = match.params.entityId;
    const tenantId = match.params.tenantId;

    const {t} = useTranslation();
    const history = useHistory();


    const [isContractsListOpen, setContractsListOpen] = useState(true);
    const toggleContractsList = () => setContractsListOpen(!isContractsListOpen);

    const [tenant, setTenant] = useState<Tenant>();
    const [files, setFiles] = useState([]);

    const filesDivRef = useRef<HTMLInputElement>(null);

    const fileChangeHandler = (event: any) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 10000000) {
                errorToast(`${t('toast-File cannot have more than 10 MB')}.`);
            } else {
                const formData = new FormData();
                formData.append('file', selectedFile);
                infoToast(`${t('toast-Uploading')}.`);
                tenantService.uploadFile(entityId, tenantId, formData)
                    .then(response => {
                            successToast(`${t('toast-File uploaded successfully')}.`);
                            if (response && response.length > 0) {
                                setFiles(response);
                            }
                            if (filesDivRef && filesDivRef.current) {
                                filesDivRef.current.scrollIntoView();
                            }
                        },
                        error => {
                            console.log(error);
                            errorToast(`${t('toast-Error while uploading file')}.`);
                        });
            }
        }
    };

    const triggerUploadButtonClicked = () => {
        // @ts-ignore
        document.querySelector("input[type='file']").click();
    };

    React.useEffect(() => {
        tenantService.getTenantOverview(entityId, tenantId).then(response => {
            for (let i = 0; i < response.contracts.length; i++) {
                response.contracts[i].property.entityId = entityId;
                response.contracts[i].entityId = entityId;
            }
            setTenant(response);
            setFiles(response.files);
        });
    }, []);

    const hasContacts = tenant?.email || tenant?.phone;
    const hasFiscalInfo = tenant?.fiscalNumber || tenant?.address || tenant?.postalCode;

    return (
        <div>
            <Navigation entityId={entityId}/>
            <div className="col-xl-6 offset-md-3 text-center">
                <div className="section-title">
                    <h4>{tenant?.name}</h4>
                </div>
                {
                    permissionService.hasEditAccess(entityId) &&
                    <Link to={`/entities/${entityId}/tenants/${tenantId}/edit`}
                          style={{marginLeft: "10px", marginRight: "10px"}}>
                        <MDBIcon icon="pen"/>
                    </Link>
                }
                {
                    permissionService.hasDeleteAccess(entityId) &&
                    <ConfirmDeleteTenant history={history} ids={match.params} numContracts={tenant?.contracts.length}/>
                }
            </div>
            <br/>
            <div className="card card-cascade narrower">
                <div className="row view view-cascade py-3 gradient-card-header info-color-dark d-flex justify-content-between align-items-center white-text"
                     style={{borderRadius: "5px"}}>
                    <div className="col-md-11">
                        <MDBIcon icon="user-alt" size="2x"/>
                    </div>
                    <div className="col-md-1 text-right">
                        {
                            permissionService.hasCreateAccess(entityId) &&
                            <>
                                <>
                                    <input type="file" name="file" onChange={fileChangeHandler} hidden={true}/>
                                    <button onClick={triggerUploadButtonClicked} type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            data-tip data-for="uploadFileTip">
                                        <MDBIcon icon="upload" size="2x"/>
                                    </button>
                                    <ReactTooltip id="uploadFileTip" place="top" effect="solid">
                                        {t('Upload file')}
                                    </ReactTooltip>
                                </>
                            </>
                        }
                    </div>
                </div>
                <div className="card-body">
                    {
                        !hasContacts && !hasFiscalInfo && !tenant?.note &&
                        <div className="row justify-content-md-center">
                            <h2 className="no-data-to-display mt-50 mb-50">{t('tenant-No details')}</h2>
                        </div>
                    }
                    <div className="row">
                        {
                            hasContacts &&
                            <div className="col-md-6">
                                {
                                    tenant?.phone &&
                                    <>
                                        <MDBIcon icon="phone-alt"/>
                                        <span className="ml-2">{tenant?.phone}</span>
                                        <br/>
                                        <br/>
                                    </>
                                }
                                {
                                    tenant?.email &&
                                    <a href={`mailto:${tenant?.email}`}>
                                        <MDBIcon icon="envelope"/>
                                        <span className="ml-2">{tenant?.email}</span>
                                    </a>
                                }
                            </div>
                        }
                        {
                            hasFiscalInfo &&
                            <div className="col-md-6">
                                {
                                    tenant?.fiscalNumber &&
                                    <>
                                        <strong>{t('tenantForm-Fiscal Number')}:</strong>
                                        <span className="ml-2">{tenant?.fiscalNumber}</span>
                                        <br/>
                                        <br/>
                                    </>
                                }
                                {
                                    tenant?.address &&
                                    <>
                                        {tenant?.address}
                                        <br/>
                                        {tenant?.postalCode}
                                    </>
                                }
                            </div>
                        }
                    </div>
                    {
                        (hasContacts || hasFiscalInfo) && tenant?.note &&
                        <hr className={"divider mt-3 mb-3"}/>
                    }
                    {tenant?.note}
                </div>
            </div>

            <div className="section-title text-center">
                <h4></h4>
            </div>
            <br/>

            <div className="card">

                <div className="card-header" role="tab" id="headingThree" style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}} onClick={toggleContractsList}>
                    <div id="folder" className="row">
                        <span className="mt-1 mb-0 col-md-10">
                            {t('tenant-Contracts History')}
                        </span>
                        {
                            isContractsListOpen
                                ? <MDBIcon icon="angle-up col-md-2 text-right" size="2x"/>
                                : <MDBIcon icon="angle-down col-md-2 text-right" size="2x"/>
                        }
                    </div>
                </div>

                <Collapse isOpen={isContractsListOpen}>
                    <Card>
                        <CardBody>
                            {
                                tenant?.contracts.length === 0
                                    ? <h2 className="no-data-to-display mt-50 mb-50">{t('tenant-No contracts found')}</h2>
                                    : <ContractsTable contracts={tenant?.contracts || []}/>
                            }
                        </CardBody>
                    </Card>
                </Collapse>
            </div>

            <div ref={filesDivRef}>
                <TenantFiles
                    files={files}
                    entityId={entityId}
                    tenantId={tenantId}
                    history={history}
                />
            </div>

        </div>
    );
}

type Props = {
    entityId: string,
    tenantId: string;
}