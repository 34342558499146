import {handleResponse} from "../_helpers/handle-response";
import {authHeader, jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {getPrimitiveCleanValue} from "../_helpers/util-functions";


export const tenantService = {
    getAll,
    getAllByContract,
    getTenantOverview,
    getTenant,
    deleteTenant,
    createTenant,
    updateTenant,
    uploadFile,
    getFileUrl
};

function getAll(entityId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/tenants`, requestOptions).then(handleResponse);
}

function getAllByContract(entityId, contractId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/tenants?contractId=${contractId}`, requestOptions).then(handleResponse);
}

function getTenantOverview(entityId, tenantId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/tenants/${tenantId}/overview`, requestOptions).then(handleResponse);
}

function getTenant(entityId, tenantId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/tenants/${tenantId}`, requestOptions).then(handleResponse);
}

function deleteTenant(entityId, tenantId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/tenants/${tenantId}`, requestOptions).then(handleResponse);
}

function createTenant(entityId, name, email, phone, fiscalNumber, note, address, postalCode) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanTenantAttributes(name, email, phone, fiscalNumber, note, address, postalCode))
    };
    return fetch(SERVER_URL + "/entities/" + entityId + "/tenants", requestOptions).then(handleResponse);
}

function updateTenant(entityId, tenantId, name, email, phone, fiscalNumber, note, address, postalCode) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanTenantAttributes(name, email, phone, fiscalNumber, note, address, postalCode))
    };
    return fetch(SERVER_URL + "/entities/" + entityId + "/tenants/" + tenantId, requestOptions).then(handleResponse);
}

function cleanTenantAttributes(name, email, phone, fiscalNumber, note, address, postalCode) {
    let attributes = {name};
    attributes["email"] = getPrimitiveCleanValue(email);
    attributes["phone"] = getPrimitiveCleanValue(phone);
    attributes["fiscalNumber"] = getPrimitiveCleanValue(fiscalNumber);
    attributes["note"] = getPrimitiveCleanValue(note);
    attributes["address"] = getPrimitiveCleanValue(address);
    attributes["postalCode"] = getPrimitiveCleanValue(postalCode);
    return attributes;
}

function uploadFile(entityId, tenantId, formData) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken()},
        body: formData
    };
    return fetch(SERVER_URL + `/entities/${entityId}/tenants/${tenantId}/files`, requestOptions).then(handleResponse);
}

function getFileUrl(entityId, tenantId, fileId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/tenants/${tenantId}/files/${fileId}`, requestOptions).then(handleResponse);
}
