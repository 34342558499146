import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {errorToast, successToast} from "../../../_helpers/toast-functions";
import {MDBIcon} from "mdbreact";
import {authenticationService} from "../../../_services";
import {Input} from "@progress/kendo-react-inputs";
import {entityService} from "../../../_services/entity.service";
import {useTranslation} from "react-i18next";

export function ConfirmDeleteEntity(props) {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [correctEmail, setCorrectEmail] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setCorrectEmail(false);
    }
    const handleDeleteEntity = () => {
        entityService.deleteEntity(props.ids.entityId)
            .then(
                response => {
                    successToast(`${t('toast-Entity successfully deleted')}!`);
                    props.history.push(`/entities`);
                },
                error => {
                    errorToast(error.message);
                    setShow(false);
                }
            );
    };
    const emailChanged = (event) => {
        setCorrectEmail(authenticationService.currentEmailValue === event.value);
    }

    return (
        <>
            <a onClick={handleShow} style={{marginLeft: "10px"}}>
                <MDBIcon icon="trash" style={{color: "#B22222"}}/>
            </a>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                    Are you sure you want to delete this entity? <br/>
                    <>The <strong>entity</strong>, all its <strong>properties</strong>, <strong>contracts</strong> and <strong>tenants</strong> will be <strong><u>permanently
                        deleted.</u></strong><br/></>
                    This action <strong><u>cannot be undone.</u></strong>
                    <div className="mb-3">
                        <Input
                            name="confirmPassword"
                            style={{width: '100%'}}
                            label="Type your email for confirmation"
                            minLength={1}
                            maxLength={200}
                            onChange={emailChanged}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteEntity} disabled={!correctEmail}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
