export function getStringAddress(address) {
    if (!address) {
        return '';
    }
    let addressString = address.street;
    if (address.number) {
        addressString += ", Num " + address.number;
    }
    if (address.floor) {
        addressString += ", " + address.floor;
    }
    if (address.mailBox) {
        addressString += ", Box " + address.mailBox;
    }
    if (address.postalCode) {
        addressString += ", " + address.postalCode;
    }
    if (address.city) {
        const city = address.city;
        const separator = address.postalCode ? " " : ", ";
        addressString += separator + city.name + ", " + city.country;
    }
    return addressString;
}

export function getStringAddressStreetWithNumber(address) {
    if (!address) {
        return '';
    }
    let addressString = address.street;
    if (address.number) {
        addressString += ", Num " + address.number;
    }
    if (address.postalCode) {
        addressString += ", " + address.postalCode;
    }
    if (address.city) {
        const city = address.city;
        const separator = address.postalCode ? " " : ", ";
        addressString += separator + city.name + ", " + city.country;
    }
    return addressString;
}

export function getStringAddressWithoutPostalCode(address) {
    if (!address) {
        return '';
    }
    let addressString = address.street;
    if (address.number) {
        addressString += ", Num " + address.number;
    }
    if (address.floor) {
        addressString += ", " + address.floor;
    }
    if (address.mailBox) {
        addressString += ", Box " + address.mailBox;
    }
    return addressString;
}

export function getStringAddressPostalCode(address) {
    if (!address) {
        return '';
    }
    let addressString = address.postalCode;
    if (address.city) {
        const city = address.city;
        const separator = address.postalCode ? " " : ", ";
        addressString += separator + city.name;
    }
    return addressString;
}

export function getMapOfStringAddress(addresses) {
    let mapStringAddress = [];
    Object.keys(addresses).forEach(function (key) {
        mapStringAddress[key] = getAddressMapEntry(addresses[key]);
    });

    mapStringAddress.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    return mapStringAddress;
}

function getAddressMapEntry(address) {
    let entry = {};
    entry["id"] = address.id;
    entry["name"] = getStringAddress(address);
    entry["raw"] = address;
    return entry;
}
