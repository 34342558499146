import React from 'react';
import {rentService} from "../../_services/rent.service";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import {lookupValuesService} from "../../_services";
import {successToast} from "../../_helpers/toast-functions";
import {rentPaymentService} from "../../_services/rentPayment.service";
import Navigation from "../../_components/Navigation";
import {withTranslation} from "react-i18next";
import MyNumberInput from "../../_components/MyNumberInput";


class CreateRentPayment extends React.Component {
    constructor(props) {
        super(props);
        let now = new Date();
        now.setHours(12);
        this.state = {
            currencies: [],
            allCurrencies: [],
            currency: null,
            initialValues: {
                amount: '',
                taxableAmount: '',
                description: '',
                retainedAmount: null
            },
            paymentDate: now
        };
    }

    componentDidMount() {
        const entityId = this.props.match.params.entityId;
        const propertyId = this.props.match.params.propertyId;
        const contractId = this.props.match.params.contractId;
        const rentId = this.props.match.params.rentId;

        lookupValuesService.getCurrencies().then(responseCurrencies => {
            this.setState({currencies: responseCurrencies});
            this.setState({allCurrencies: responseCurrencies})

            rentService.getRent(entityId, propertyId, contractId, rentId).then(responseRent => {
                let initialValues = {};
                initialValues.amount = responseRent.amount - responseRent.retainedAmount;
                initialValues.taxableAmount = responseRent.taxableAmount;
                initialValues.retainedAmount = responseRent.retainedAmount;

                this.setState({initialValues: initialValues});
                this.setState({currency: this.getCurrency(responseCurrencies, responseRent.currencyId)});
            });
        });

    }

    getCurrency(currencies, currencyId) {
        for (const curr of currencies) {
            if (curr.id === currencyId) {
                return curr;
            }
        }
    }

    handleChange = (event) => {
        switch (event.target.name) {
            case "currency":
                this.setState({currency: event.target.value});
                break;
            case "paymentDate":
                const paymentDate = new Date(event.target.value);
                paymentDate.setHours(12);
                this.setState({paymentDate});
                break;
            default:
                console.warn("unhandled change event for " + event.target.name);
        }
    };

    filterChange = (event) => {
        switch (event.target.name) {
            case "currency":
                const currencies = this.state.allCurrencies.slice();
                this.setState({currencies: filterBy(currencies, event.filter)});
                break;
            default:
                console.warn("unhandled filter change for " + event.target.name);
        }
    };

    render() {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('paymentForm-New Payment')}</h4>
                        </div>
                    </div>
                </div>

                <div className="container up">
                    <Formik
                        enableReinitialize
                        initialValues={this.state.initialValues}
                        validationSchema={Yup.object().shape(paymentValidationSchema)}
                        onSubmit={(
                            {
                                amount, taxableAmount, description
                            },
                            {setStatus, setSubmitting}
                        ) => {
                            if (!this.isValidInput()) {
                                setSubmitting(false);
                                let error = this.getErrorMessages();
                                setStatus(error);
                            } else {
                                setStatus();
                                this.createRentPayment(amount, taxableAmount, description, setSubmitting, setStatus);
                                setSubmitting(false);
                            }
                        }}
                        render={({errors, status, values, touched, isSubmitting, handleChange, setFieldValue}) => (
                            <Form>

                                <div className="form-group required">
                                    <label htmlFor="paymentDate" className="control-label">{t('paymentForm-Payment Date')}</label> <br/>
                                    <DatePicker
                                        format={"dd-MM-yyyy"}
                                        onChange={this.handleChange}
                                        name="paymentDate"
                                        value={this.state.paymentDate}
                                    />
                                    <ErrorMessage name="paymentDate" component="div" className="invalid-feedback"/>
                                    {
                                        status && status.paymentDate &&
                                        <div className="text-danger invalid-input">
                                            {t('paymentForm-Payment Date')} {status.paymentDate}
                                        </div>
                                    }
                                </div>

                                <div className="form-group required">
                                    <label htmlFor="currency" className="control-label">{t('paymentForm-Currency')}</label> <br/>
                                    <ComboBox
                                        data={this.state.currencies}
                                        textField="name"
                                        dataItemKey="id"
                                        value={this.state.currency}
                                        name="currency"
                                        onChange={this.handleChange}
                                        filterable={true}
                                        onFilterChange={this.filterChange}
                                    />
                                    <ErrorMessage name="currency" component="div" className="invalid-feedback"/>
                                    {
                                        status && status.currencyId &&
                                        <div className="text-danger invalid-input">
                                            {t('paymentForm-Currency')} {status.currencyId}
                                        </div>
                                    }
                                </div>

                                <div className="form-group smallInput required">
                                    <label htmlFor="amount" className="control-label">{t('paymentForm-Amount')}</label>
                                    <MyNumberInput
                                        className={'form-control' + (errors.amount && touched.amount ? ' is-invalid' : '')}
                                        placeholder="Ex: 123,98"
                                        value={values.amount}
                                        readOnly={this.state.finalFieldsReadOnly}
                                        onValueChange={val => setFieldValue('amount', val.floatValue)}
                                    />
                                    <ErrorMessage name="amount" component="div" className="invalid-feedback"/>
                                    {
                                        status && status.amount &&
                                        <div className="text-danger invalid-input">
                                            {t('paymentForm-Amount')} {status.amount}
                                        </div>
                                    }
                                </div>
                                {
                                    this.state.initialValues.retainedAmount > 0
                                        ? <></>
                                        : <div className="form-group smallInput">
                                            <label htmlFor="taxableAmount" className="control-label">{t('paymentForm-Taxable Amount')}</label>
                                            <MyNumberInput
                                                className={'form-control' + (errors.taxableAmount && touched.taxableAmount ? ' is-invalid' : '')}
                                                placeholder="Ex: 123,98"
                                                value={values.taxableAmount}
                                                onValueChange={val => setFieldValue('taxableAmount', val.floatValue)}
                                            />
                                            <ErrorMessage name="taxableAmount" component="div"
                                                          className="invalid-feedback"/>
                                            {
                                                status && status.taxableAmount &&
                                                <div className="text-danger invalid-input">
                                                    {t('paymentForm-Taxable Amount')} {status.taxableAmount}
                                                </div>
                                            }
                                        </div>
                                }

                                <div className="form-group">
                                    <label htmlFor="description">{t('paymentForm-Description')}</label>
                                    <Field name="description" component="textarea"
                                           className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="description" component="div" className="invalid-feedback"/>
                                    {
                                        status && status.constraints && status.constraints.description &&
                                        <div className="text-danger invalid-input">
                                            {t('paymentForm-Description')} {status.constraints.description}
                                        </div>
                                    }
                                </div>


                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                        {t('Create')}
                                    </button>
                                    {
                                        isSubmitting &&
                                        <img
                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                    }
                                </div>
                                {
                                    status && status.message &&
                                    <div>
                                        <div className={'alert alert-danger'}>{status.message}</div>
                                    </div>
                                }
                            </Form>
                        )}
                    />
                </div>
            </div>
        );
    }

    isValidInput() {
        return this.state.currency != null
            && this.state.paymentDate != null
    }

    getErrorMessages() {
        let error = {};
        if (!this.state.currency) {
            error["currencyId"] = "is required";
        }
        if (!this.state.paymentDate) {
            error["paymentDate"] = "is required";
        }
        return error;
    }

    createRentPayment(amount, taxableAmount, description, setSubmitting, setStatus) {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;
        const propertyId = this.props.match.params.propertyId;
        const contractId = this.props.match.params.contractId;
        rentPaymentService.createRentPayment(
            entityId, propertyId, contractId, this.props.match.params.rentId,
            this.state.currency.id, this.state.paymentDate, amount, taxableAmount, description)
            .then(
                response => {
                    successToast(`${t('toast-Payment successfully created')}!`);
                    this.props.history.push(`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/#rents`);
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }
}


export const paymentValidationSchema = {
    amount: Yup.number().typeError("Invalid number")
        .moreThan(-1, "Must be a positive number")
        .min(0)
        .required(),
    taxableAmount: Yup.number().typeError("Invalid number")
        .moreThan(-1, "Must be a positive number")
        .min(0)
        .nullable(),
    description: Yup.string()
        .max(255, 'Cannot contain more than 200 characters')
        .nullable()
};

export default withTranslation()(CreateRentPayment);
