import React from 'react';
import {ErrorMessage, Field} from "formik";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import Collapse from "react-bootstrap/Collapse";
import * as Yup from "yup";
import {createEventWithName} from "../../../../_helpers/event-functions";
import {Checkbox} from "@progress/kendo-react-inputs";
import AddressForm from "./AddressForm";
import {MDBIcon} from "mdbreact";
import {Tooltip} from "reactstrap";
import {withTranslation} from "react-i18next";
import MyNumberInput from "../../../../_components/MyNumberInput";


class BaseInformationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipSubProperty: false,
            setParentProperty: false,
            setCurrency: false,
            setPropertyType: false
        };
    }

    toggle = (event) => {
        switch (event.target.id) {
            case "tooltipSubProperty":
                this.setState({tooltipSubProperty: !this.state.tooltipSubProperty});
                break;
            default:
                console.warn("unhandled change event for " + event.target.id);
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let setParentPropertyValue = {setParentProperty: prevState.setParentProperty};
        let setCurrencyValue = {setCurrency: prevState.setCurrency};
        let setPropertyTypeValue = {setPropertyType: prevState.setPropertyType};

        if (!prevState.setParentProperty) {
            setParentPropertyValue = {setParentProperty: setParentProperty(nextProps)};
        }
        if (!prevState.setCurrency) {
            setCurrencyValue = {setCurrency: setCurrency(nextProps)};
        }
        if (!prevState.setPropertyType) {
            setPropertyTypeValue = {setPropertyType: setPropertyType(nextProps)};
        }

        return ({...setParentPropertyValue, ...setCurrencyValue, ...setPropertyTypeValue});
    }

    render() {
        const {t} = this.props;
        return (
            <Collapse in={!this.props.collapseBaseInformation}>
                <div id="base-info-div">

                    <div className="form-group required">
                        <label htmlFor="propertyType" className="control-label">{t('propertyForm-Property Type')}</label> <br/>
                        <ComboBox
                            name="propertyType"
                            data={this.props.state.propertyTypes}
                            textField="name"
                            dataItemKey="id"
                            value={this.props.state.propertyType}
                            filterable={true}
                            onFilterChange={this.props.handleFilterChange}
                            onChange={this.props.handleChange}
                        />
                        <ErrorMessage name="propertyType" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.propertyTypeId &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Property Type')} {this.props.status.propertyTypeId}
                            </div>
                        }
                        {
                            this.props.status && this.props.status.propertyType &&
                            <div className="text-danger invalid-input">{this.props.status.propertyType}</div>
                        }
                    </div>

                    <div className="form-group smallInput" style={{"marginBottom": "0px"}}>
                        <label htmlFor="isSubProperty" style={{"marginRight": "5px", "marginBottom": "0px"}}>{t('propertyForm-Is sub-property')}</label>
                        <MDBIcon id="tooltipSubProperty" icon="question" style={{color: "#00008B", "marginRight": "10px"}}/>
                        <Tooltip placement="right" isOpen={this.state.tooltipSubProperty} target="tooltipSubProperty" toggle={this.toggle}>
                            {t('propertyForm-Used to organize properties in a hierarchy')}. <br/>
                            <strong>{t('Example')}:</strong> {t('propertyForm-a room can be a sub-property of an apartment')}
                        </Tooltip>
                        <Checkbox id="isSubProperty" name="isSubProperty" value={this.props.values.isSubProperty} onChange={e => this.props.setFieldValue('isSubProperty', e.value)}/>
                    </div>
                    {
                        this.props.values.isSubProperty &&
                        <div className="form-group">
                            <ComboBox
                                name="parentProperty"
                                label={`${t('propertyForm-Parent property')}`}
                                data={this.props.state.properties}
                                textField="name"
                                dataItemKey="id"
                                value={this.props.state.parentProperty}
                                onChange={e => {
                                    e.isUpdate = true;
                                    this.props.handleChange(e);
                                }}
                                filterable={true}
                                onFilterChange={this.props.handleFilterChange}
                                style={{"width": "100%"}}/>
                            <ErrorMessage name="parentProperty" component="div" className="invalid-feedback"/>
                            {
                                this.props.status && this.props.status.parentPropertyId &&
                                <div className="text-danger invalid-input">
                                    {t('propertyForm-Parent property')} {this.props.status.parentPropertyId}
                                </div>
                            }
                        </div>
                    }

                    <AddressForm
                        address={this.props.address}
                        values={this.props.values}
                        status={this.props.status}
                        touched={this.props.touched}
                        errors={this.props.errors}
                        parentProperty={this.props.state.parentProperty}
                        parentPropertyUpdated={this.props.state.parentPropertyUpdated}
                        setFieldValue={this.props.setFieldValue}
                        page={this.props.page}
                        propertyType={this.props.state.propertyType}
                    />

                    <div className="form-group">
                        <label htmlFor="name">{t('propertyForm-Name')}</label>
                        <Field name="name" type="text" placeholder={`Ex: ${t('propertyForm-Name-placeholder')}`}
                               className={'form-control' + (this.props.errors.name && this.props.touched.name ? ' is-invalid' : '')}/>
                        <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.name &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Name')} {this.props.status.name}
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="areaMeters">{t('propertyForm-Area')} (m<sup>2</sup>)</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.areaMeters && this.props.touched.areaMeters ? ' is-invalid' : '')}
                            placeholder="Ex: 20"
                            value={this.props.values.areaMeters}
                            onValueChange={val => this.props.setFieldValue('areaMeters', val.floatValue)}
                        />
                        <ErrorMessage name="areaMeters" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.areaMeters &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Area')} {this.props.status.areaMeters}
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="value">{t('propertyForm-Value')}</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.value && this.props.touched.value ? ' is-invalid' : '')}
                            placeholder="Ex: 123,98"
                            value={this.props.values.value}
                            onValueChange={val => this.props.setFieldValue('value', val.floatValue)}
                        />
                        <ErrorMessage name="value" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.value &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Value')} {this.props.status.value}
                            </div>
                        }
                    </div>
                    {
                        this.props.values.value &&
                        <div className="form-group">
                            <label htmlFor="currency">{t('propertyForm-Currency')}</label> <br/>
                            <ComboBox
                                name="currency"
                                data={this.props.state.currencies}
                                textField="name"
                                dataItemKey="id"
                                value={this.props.state.currency}
                                onChange={this.props.handleChange}
                                filterable={true}
                                onFilterChange={this.props.handleFilterChange}
                            />
                            <ErrorMessage name="currency" component="div" className="invalid-feedback"/>
                            {
                                this.props.status && this.props.status.currencyId &&
                                <div className="text-danger invalid-input">
                                    {t('propertyForm-Currency')} {this.props.status.currencyId}
                                </div>
                            }
                        </div>
                    }

                    <div className="form-group">
                        <label htmlFor="code">{t('propertyForm-Code')}</label>
                        <Field name="code" type="text"
                               className={'form-control' + (this.props.errors.code && this.props.touched.code ? ' is-invalid' : '')}/>
                        <ErrorMessage name="code" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.constraints && this.props.status.constraints.code &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Code')} {this.props.status.constraints.code}
                            </div>
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="description">{t('propertyForm-Description')}</label>
                        <Field name="description" component="textarea"
                               className={'form-control' + (this.props.errors.description && this.props.touched.description ? ' is-invalid' : '')}/>
                        <ErrorMessage name="description" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.description &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Description')} {this.props.status.description}
                            </div>
                        }
                    </div>
                </div>
            </Collapse>
        );
    }
}

export default withTranslation()(BaseInformationForm);

export const baseInfoValidationSchema = {
    name: Yup.string()
        .max(60, 'Cannot contain more than 60 characters'),
    code: Yup.string()
        .max(20, 'Cannot contain more than 20 characters'),
    description: Yup.string()
        .max(200, 'Cannot contain more than 200 characters'),
    areaMeters: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    value: Yup.number().typeError("Invalid number")
        .positive("Must be a positive number")
};

function setParentProperty(props) {
    const parentPropertyId = props.parentPropertyId ? props.parentPropertyId : getParentPropQueryParam(props);
    if (props.state.parentProperty || !parentPropertyId || !props.state.allProperties.length) {
        return false;
    }
    for (const pro of props.state.allProperties) {
        if (pro.id == parentPropertyId) {
            props.handleChange(createEventWithName(pro, "parentProperty"));
            props.setFieldValue('isSubProperty', true)
            return true;
        }
    }
    return false;
}

function getParentPropQueryParam(props) {
    if (props.location) {
        const queryFilters = require('query-string').parse(props.location.search);
        if (queryFilters["parent"]) {
            return queryFilters["parent"];
        }
    }
    return null;
}

function setCurrency(props) {
    if (props.state.currency || !props.currencyId || !props.state.allCurrencies.length) {
        return false;
    }
    for (const curr of props.state.allCurrencies) {
        if (curr.id === props.currencyId) {
            props.handleChange(createEventWithName(curr, "currency"));
            return true;
        }
    }
    return false;
}

function setPropertyType(props) {
    if (props.state.propertyType || !props.propertyTypeId || !props.state.allPropertyTypes.length) {
        return false;
    }
    for (const proType of props.state.allPropertyTypes) {
        if (proType.id === props.propertyTypeId) {
            props.handleChange(createEventWithName(proType, "propertyType"));
            return true;
        }
    }
    return false;
}
