import React from 'react';
import {contractService, entityService} from "../../../_services";
import {MDBIcon} from "mdbreact";
import RentForm from "../_aux/RentForm";
import {rentService} from "../../../_services/rent.service";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

export const EDIT_RENT = "EditRent";

class EditRent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                amount: '',
                taxableAmount: '',
            },
            paymentDueDate: null,
            paymentPeriodDate: null,
            taxRetentionPercentage: null
        };
    }

    componentDidMount() {
        const entityId = this.props.match.params.entityId;
        const propertyId = this.props.match.params.propertyId;
        const contractId = this.props.match.params.contractId;
        const rentId = this.props.match.params.rentId;

        contractService.getContract(entityId, propertyId, contractId).then(response => {
            if (response.taxRetention) {
                this.setState({taxRetentionPercentage: response.taxRetention && response.taxRetentionPercentage});
            }
        });

        rentService.getRent(entityId, propertyId, contractId, rentId).then(response => {
            let initialValues = {};
            initialValues.amount = response.amount;
            initialValues.taxableAmount = response.taxableAmount;
            initialValues.retainedAmount = response.retainedAmount;
            this.setState({initialValues: initialValues});
            this.setState({currencyId: response.currencyId});
            this.setState({paymentDueDate: response.paymentDueDate});
            this.setState({paymentPeriodDate: response.paymentPeriodDate});
        });
    }

    render() {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;
        const propertyId = this.props.match.params.propertyId;
        const contractId = this.props.match.params.contractId;
        const rentId = this.props.match.params.rentId;

        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-3 col-md-3">
                        <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}`}
                           style={{color: "#0062cc"}}>
                            <MDBIcon icon="undo"/> Back to Contract
                        </Link>
                    </div>
                    <div className="col-xl-6 col-md-6 text-center">
                        <div className="section-title">
                            <h4>{t('rentForm-Edit Rent')}</h4>
                        </div>
                    </div>
                </div>
                <RentForm
                    entityId={entityId}
                    propertyId={propertyId}
                    contractId={contractId}
                    rentId={rentId}
                    history={this.props.history}
                    initialValues={this.state.initialValues}
                    currencyId={this.state.currencyId}
                    paymentDate={this.state.paymentDate}
                    paymentDueDate={this.state.paymentDueDate}
                    paymentPeriodDate={this.state.paymentPeriodDate}
                    taxRetentionPercentage={this.state.taxRetentionPercentage}
                    page={EDIT_RENT}/>
            </div>
        );
    }
}

export default withTranslation()(EditRent);