import React from 'react';

export class DetailsTab extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const details = this.props.details;
        return (
            <div className="tab-content entity-overview-tab" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    {
                        details.description &&
                        <div className="row">
                            <div className="col-md-3">
                                <p>Description</p>
                            </div>
                            <div className="col-md-9">
                                {details.description}
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-3">
                            <p>Code</p>
                        </div>
                        <div className="col-md-2">
                            {details.code}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            <p>Size</p>
                        </div>
                        <div className="col-md-3">
                            {details.areaMeters} m<sup>2</sup>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Heating</p>
                        </div>
                        <div className="col-md-2">
                            {details.heating}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            <p>Number of Balconies</p>
                        </div>
                        <div className="col-md-3">
                            {details.numBalconies}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Air Condition</p>
                        </div>
                        <div className="col-md-2">
                            {details.airCondition}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            <p>Outside Area Type</p>
                        </div>
                        <div className="col-md-3">
                            {details.outsideAreaType}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Furnished</p>
                        </div>
                        <div className="col-md-2">
                            {details.furnished}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            <p>Outside Area Size</p>
                        </div>
                        <div className="col-md-3">
                            {details.outsideAreaMeters && <div>{details.outsideAreaMeters} m<sup>2</sup></div>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Laundry</p>
                        </div>
                        <div className="col-md-2">
                            {details.laundry}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            <p>Kitchen Type</p>
                        </div>
                        <div className="col-md-3">
                            {details.kitchenType}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Number of Bathrooms</p>
                        </div>
                        <div className="col-md-2">
                            {details.numBathrooms}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            <p>Kitchen Area</p>
                        </div>
                        <div className="col-md-3">
                            {details.kitchenAreaMeters && <div>{details.kitchenAreaMeters} m<sup>2</sup></div>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Number of Toilets</p>
                        </div>
                        <div className="col-md-2">
                            {details.numToilets}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            {details.storageRoom ? <p>Storage Room Area</p> : <p>Storage Room</p>}
                        </div>
                        {
                            details.storageRoom
                                ? <div className="col-md-3">
                                    {
                                        details.storageRoomAreaMeters &&
                                        <div>{details.storageRoomAreaMeters} m<sup>2</sup></div>
                                    }
                                </div>
                                : <div className="col-md-3">{details.storageRoom}</div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Number of Bedrooms</p>
                        </div>
                        <div className="col-md-2">
                            {details.numBedrooms}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            {details.livingRoom ? <p>Living Room Area</p> : <p>Living Room</p>}
                        </div>
                        {
                            details.livingRoom
                                ? <div className="col-md-3">
                                    {
                                        details.livingRoomAreaMeters &&
                                        <div>{details.livingRoomAreaMeters} m<sup>2</sup></div>
                                    }
                                </div>
                                : <div className="col-md-3">{details.livingRoom}</div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Number of Suits</p>
                        </div>
                        <div className="col-md-2">
                            {details.numSuits}
                        </div>
                        <div className="col-md-3 offset-md-1">
                            {details.diningRoom ? <p>Dining Room Area</p> : <p>Dining Room</p>}
                        </div>
                        {
                            details.diningRoom
                                ? <div className="col-md-3">
                                    {
                                        details.diningRoomAreaMeters &&
                                        <div>{details.diningRoomAreaMeters} m<sup>2</sup></div>
                                    }
                                </div>
                                : <div className="col-md-3">{details.diningRoom}</div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <p>Number of Parking Spots</p>
                        </div>
                        <div className="col-md-2">
                            {
                                details.numParkingInside &&
                                <div className="row">
                                    <div className="col-md-6"><b>Inside</b></div>
                                    <div className="col-md-6">{details.numParkingInside}</div>
                                </div>
                            }
                            {
                                details.numParkingOutside &&
                                <div className="row">
                                    <div className="col-md-6"><b>Outside</b></div>
                                    <div className="col-md-6">{details.numParkingOutside}</div>
                                </div>
                            }
                        </div>
                        <div className="col-md-3 offset-md-1">
                            {details.diningRoom ? <p>Dining Room Area</p> : <p>Dining Room</p>}
                        </div>
                        {
                            details.diningRoom
                                ? <div className="col-md-3">
                                    {
                                        details.diningRoomAreaMeters &&
                                        <div>{details.diningRoomAreaMeters} m<sup>2</sup></div>
                                    }
                                </div>
                                : <div className="col-md-3">{details.diningRoom}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
