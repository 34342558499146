import React from "react";
import {orderFormatter, roundTwoDecimalCases} from "../../../_helpers/util-functions";
import BootstrapTable from "react-bootstrap-table-next";
import {getMonthYearOfDate, getStringDateInCurrentTimeZone} from "../../../_helpers/date-functions";
import {ConfirmDeleteRent} from "./ConfirmDeleteRent";
import {CurrencyHmlCode} from "../../../_helpers/CurrencyHmlCode";
import {permissionService} from "../../../_services/permission.service";
import {ConfirmDeleteRentPayment} from "./ConfirmDeleteRentPayment";
import {MDBIcon} from "mdbreact";
import {Card, CardBody, Collapse} from "reactstrap";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

class ContractRents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialExpand: false,
            expanded: [],
            isOpen: true
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (prevState.initialExpand === false && nextProps.rents.length > 0) {
            return {
                initialExpand: true,
                expanded: nextProps.rents.map(r => r.id)
            };
        }
    }

    toggle() {
        return () => this.setState({isOpen: !this.state.isOpen});
    }


    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                expanded: [...this.state.expanded, row.id]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.id)
            }));
        }
    }

    render() {
        const {t} = this.props;
        let entityId = this.props.entityId;
        let propertyId = this.props.propertyId;
        let contractId = this.props.contractId;

        const rentsPaymentPeriodDate = this.getOrderedRentsPaymentPeriods();
        const duplicates = this.getDuplicateRentsPeriod(rentsPaymentPeriodDate);
        const missing = this.getMissingRentsPeriod(rentsPaymentPeriodDate);

        const rentWithRetainedAmount = this.props.rents.some(i => i.retainedAmount > 0);
        const rentWithTaxableAmount = this.props.rents.some(i => i.taxableAmount > 0);

        const columns = [
            {
                dataField: 'id',
                text: 'Id',
                hidden: true
            },
            {
                dataField: 'paymentPeriodDate',
                text: `${t('contract-Rents-Period')}`,
                sort: true,
                align: 'center',
                headerAlign: 'center',
                headerFormatter: orderFormatter,
                formatter: (cell, row, rowIndex) => (
                    <strong><u>{getMonthYearOfDate(cell)}</u></strong>
                ),
                headerStyle: (colum, colIndex) => {
                    return {width: '90px', minWidth: '90px'};
                }
            },
            {
                dataField: 'paymentDueDate',
                text: `${t('contract-Rents-Payment Due Date')}`,
                sort: true,
                align: 'center',
                headerAlign: 'center',
                headerFormatter: orderFormatter,
                formatter: (cell, row, rowIndex) => (
                    <div>{getStringDateInCurrentTimeZone(cell)}</div>
                ),
                headerStyle: (colum, colIndex) => {
                    return {width: '100px', minWidth: '100px'};
                }
            },
            {
                dataField: 'amount',
                text: `${t('contract-Rents-Amount')}`,
                sort: true,
                align: 'center',
                headerAlign: 'center',
                headerStyle: (colum, colIndex) => {
                    return {width: '100px', minWidth: '100px'};
                },
                headerFormatter: orderFormatter,
                formatter: (cell, row, rowIndex) => (
                    <div>
                        {
                            cell && <div>{cell.toLocaleString()} <CurrencyHmlCode currency={row.currency.name}/></div>
                        }
                    </div>
                )
            },
            {
                dataField: 'retainedAmount',
                text: `${t('contract-Rents-Retained')}`,
                sort: true,
                align: 'center',
                headerAlign: 'center',
                headerStyle: (colum, colIndex) => {
                    return {width: '100px', minWidth: '100px'};
                },
                headerFormatter: orderFormatter,
                formatter: (cell, row, rowIndex) => (
                    <div>
                        {cell ? cell.toLocaleString() : 0} <CurrencyHmlCode currency={row.currency.name}/>
                    </div>
                )
            },
            {
                dataField: 'amountPaid',
                text: `${t('contract-Rents-Paid')}`,
                sort: true,
                align: 'center',
                headerAlign: 'center',
                headerStyle: (colum, colIndex) => {
                    return {width: '120px', minWidth: '120px'};
                },
                headerFormatter: orderFormatter,
                formatter: (cell, row, rowIndex) => {
                    const amountToPay = roundTwoDecimalCases(row.amount - row.retainedAmount);
                    return (
                        <div>
                            {
                                cell >= amountToPay
                                    ? <div className={'good'}>
                                        {cell.toLocaleString()} <CurrencyHmlCode currency={row.currency.name}/>
                                    </div>
                                    : <>
                                        <b className={'danger'}>
                                            {cell ? cell.toLocaleString() : 0} <CurrencyHmlCode
                                            currency={row.currency.name}/>
                                        </b> / {amountToPay.toLocaleString()} <CurrencyHmlCode
                                        currency={row.currency.name}/>
                                    </>
                            }
                            {
                                row.paymentDate &&
                                <div><strong><small>{getStringDateInCurrentTimeZone(row.paymentDate)}</small></strong>
                                </div>
                            }
                        </div>
                    );
                }
            },
            {
                dataField: 'taxableAmount',
                text: `${t('Taxable')}`,
                sort: true,
                align: 'center',
                headerAlign: 'center',
                headerStyle:
                    (colum, colIndex) => {
                        return {width: '130px', minWidth: '130px'};
                    },
                headerFormatter:
                orderFormatter,
                formatter:
                    (cell, row, rowIndex) => (
                        <div>
                            {cell ? cell.toLocaleString() : 0} <CurrencyHmlCode currency={row.currency.name}/>
                        </div>
                    )
            },
            {
                dataField: '',
                text: '',
                headerStyle: (colum, colIndex) => ({width: '100px', minWidth: '100px'}),
                formatter:
                    (cell, row, rowIndex) => (
                        <div align="center">
                            {
                                permissionService.hasCreateAccess(entityId) &&
                                <div>
                                    <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${row.id}/pay`}
                                          style={{color: "#0062cc", paddingLeft: "10px"}}>{t('rent-Payment-New')}</Link>
                                </div>
                            }
                            <div>
                                {
                                    permissionService.hasEditAccess(entityId) &&
                                    <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${row.id}/edit`}
                                          style={{color: "#0062cc", paddingLeft: "10px"}}>Edit</Link>
                                }
                                {
                                    permissionService.hasEditAccess(entityId) && permissionService.hasDeleteAccess(entityId) &&
                                    <a style={{marginRight: "10px", marginLeft: "10px"}}>-</a>
                                }
                                {
                                    permissionService.hasDeleteAccess(entityId) &&
                                    <ConfirmDeleteRent entityId={entityId} propertyId={propertyId}
                                                       contractId={contractId} rentId={row.id}
                                                       history={this.props.history}/>
                                }
                            </div>
                        </div>
                    )
            }];

        const expandRentRow = {
            renderer: row => (
                <>
                    {row.payments.length > 0 && <small>{t('rent-Payments')}:</small>}
                    {row.payments.map(payment =>
                        <div className="row">
                            <div
                                className="col-md-2 mx-auto text-center offset-md-1 ">{getStringDateInCurrentTimeZone(payment.paymentDate)}</div>
                            <div className="col-md-2 mx-auto text-center">
                                {payment.amount} <CurrencyHmlCode currency={row.currency.name}/>
                                {
                                    payment.taxableAmount &&
                                    <>
                                        <br/><small>({payment.taxableAmount} <CurrencyHmlCode
                                        currency={row.currency.name}/> {t('Taxable')})</small>
                                    </>
                                }
                            </div>
                            <div className="col-md-4 mx-auto">{payment.description}</div>

                            {
                                permissionService.hasCreateAccess(entityId) &&
                                <div className="col-md-2 mx-auto text-center">
                                    <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${row.id}/payments/${payment.id}/pdf/create`}
                                          style={{color: "#0062cc", paddingLeft: "10px"}}>
                                        {t('rent-Payment-Create Invoice Pdf')}
                                    </Link>
                                </div>
                            }
                            {
                                permissionService.hasDeleteAccess(entityId) &&
                                <div className="col-md-2 mx-auto">
                                    <ConfirmDeleteRentPayment entityId={entityId} propertyId={propertyId}
                                                              contractId={contractId} rentId={row.id}
                                                              rentPaymentId={payment.id}
                                                              history={this.props.history}/>
                                </div>
                            }
                        </div>
                    )}
                </>
            ),
            showExpandColumn: true,
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand
        };


        if (!rentWithRetainedAmount) {
            columns.splice(4, 1);
        }
        if (rentWithRetainedAmount && !rentWithTaxableAmount) {
            columns.splice(6, 1);
        }

        const rowStyle = {backgroundColor: '#E8E8E8'};

        return (
            <div id="rents" className="inner-list">
                <div className="section-title text-center">
                    <h4></h4>
                </div>
                <br/>

                <div className="card">

                    <div className="card-header" role="tab" id="headingThree" style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}} onClick={this.toggle()}>
                        <div id="folder" className="row">
                        <span className="mt-1 mb-0 col-md-10">
                            {t('contract-Rents')}
                        </span>
                            {
                                this.state.isOpen
                                    ? <MDBIcon icon="angle-up col-md-2 text-right" size="2x"/>
                                    : <MDBIcon icon="angle-down col-md-2 text-right" size="2x"/>
                            }
                        </div>
                    </div>

                    <Collapse isOpen={this.state.isOpen}>
                        <Card>
                            <CardBody>
                                {
                                    (duplicates || missing) &&
                                    <div className={'alert alert-warning'}>
                                        {
                                            duplicates &&
                                            <div><b>Warning: </b>Found multiple rents on [ {duplicates} ].</div>
                                        }
                                        {
                                            missing &&
                                            <div><b>Warning: </b>Expected to find rents for [ {missing} ] but no rents found.
                                            </div>
                                        }
                                    </div>
                                }
                                <BootstrapTable
                                    rowStyle={rowStyle}
                                    keyField='id'
                                    data={this.props.rents}
                                    expandRow={expandRentRow}
                                    columns={columns}
                                    hover={true}
                                    bordered={false}
                                    defaultSorted={[{dataField: 'paymentPeriodDate', order: 'desc'}]}/>
                                {
                                    (!this.props.rents || this.props.rents.length === 0) &&
                                    <h3 className="no-data-to-display">Contract has no rents</h3>
                                }
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>
            </div>
        );
    }

    getOrderedRentsPaymentPeriods() {
        return this.props.rents
            .map(i => i.paymentPeriodDate)
            .sort(function (a, b) {
                return new Date(a) - new Date(b)
            })
            .map(i => getMonthYearOfDate(i).replace(/\s/g, ''));
    }

    getMissingRentsPeriod(rentsPaymentPeriodDate) {
        let missing = rentsPaymentPeriodDate
            .filter((e, i) => {
                if (!rentsPaymentPeriodDate[i + 1] || rentsPaymentPeriodDate[i] === rentsPaymentPeriodDate[i + 1]) {
                    return false;
                }
                const month = parseInt(rentsPaymentPeriodDate[i].slice(0, 2));
                const year = parseInt(rentsPaymentPeriodDate[i].slice(5));

                let expectedNextMonth = (month + 1) % 12;
                if (expectedNextMonth === 0) {
                    expectedNextMonth = 12;
                }
                const expectedNextYear = expectedNextMonth > month ? year : year + 1;

                const nextMonth = parseInt(rentsPaymentPeriodDate[i + 1].slice(0, 2));
                const nextYear = parseInt(rentsPaymentPeriodDate[i + 1].slice(5));

                return nextMonth !== expectedNextMonth || nextYear !== expectedNextYear;
            })
            .map(e => {
                const month = parseInt(e.slice(0, 2));
                const year = parseInt(e.slice(3));
                let expectedNextMonthNumber = (month + 1) % 12;
                if (expectedNextMonthNumber === 0) {
                    expectedNextMonthNumber = 12;
                }
                const expectedNextMonth = ('0' + expectedNextMonthNumber).slice(-2);
                const expectedNextYear = expectedNextMonth > month ? year : year + 1;
                return expectedNextMonth + "/" + expectedNextYear;
            });
        missing = missing.join(', ');
        return missing;
    }

    getDuplicateRentsPeriod(rentsPaymentPeriodDate) {
        let duplicates = rentsPaymentPeriodDate.filter((e, i) => rentsPaymentPeriodDate[i] === rentsPaymentPeriodDate[i + 1]);
        duplicates = [...new Set(duplicates)];
        duplicates = duplicates.join(', ');
        return duplicates;
    }
}

export default withTranslation()(ContractRents);
