import React from 'react';
import {Bar} from 'react-chartjs-2';
import {getNumber} from "../../../_helpers/NumberUtils";
import {withTranslation} from "react-i18next";


class PropertyFinancesChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                responsive: true,
                legend: {
                    display: true,
                },
                type: 'bar',
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        }
                    }]
                },
                plugins: {
                    datalabels: {
                        formatter: function (value, context) {
                            if (value === 0) {
                                return '';
                            }
                            return getNumber(value) + ' €';
                        }
                    }
                }
            }
        };
    }

    componentDidMount() {
        let years = [`${new Date().getFullYear()}`];
        let revenues = [0, 0];
        let taxables = [0, 0];
        let nets = [0, 0];
        let expenses = [0, 0];
        let profits = [0, 0];
        if (this.props.finances && this.props.finances.revenues) {
            years = this.getYears();
            revenues = this.getRevenues();
            taxables = this.getTaxables();
            nets = this.getNets();
            expenses = this.getExpenses();
            profits = this.getProfits();
        }
        const data = {
            labels: years,
            datasets: this.getDatasets(revenues, taxables, nets, expenses, profits)
        };
        this.setState({data});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props && this.props.finances && this.props.finances.revenues) {
            const years = this.getYears();
            const revenues = this.getRevenues();
            const taxables = this.getTaxables();
            const nets = this.getNets();
            const expenses = this.getExpenses();
            const profits = this.getProfits();
            const data = {
                labels: years,
                datasets: this.getDatasets(revenues, taxables, nets, expenses, profits)
            };
            this.setState({data});
        }
    }

    getYears() {
        let years = [...this.props.finances.years];
        years.push('Total');
        return years;
    }

    getRevenues() {
        let revenues = [...this.props.finances.revenues];
        revenues.push(revenues.reduce((a, b) => a + b, 0));
        return revenues;
    }

    getTaxables() {
        let taxables = [...this.props.finances.taxables];
        taxables.push(taxables.reduce((a, b) => a + b, 0));
        return taxables;
    }

    getNets() {
        let nets = [...this.props.finances.nets];
        nets.push(nets.reduce((a, b) => a + b, 0));
        return nets;
    }

    getExpenses() {
        let expenses = [...this.props.finances.expenses];
        expenses.push(expenses.reduce((a, b) => a + b, 0));
        return expenses;
    }

    getProfits() {
        let profits = [...this.props.finances.profits];
        profits.push(profits.reduce((a, b) => a + b, 0));
        return profits;
    }

    getDatasets(revenues, taxables, nets, expenses, profits) {
        let datasets = [];
        datasets.push(this.getRevenueDataset(revenues));
        if (this.hasTaxableAmount()) {
            datasets.push(this.getTaxableRevenuesDataset(taxables));
            datasets.push(this.getNetRevenuesDataset(nets));
        }
        datasets.push(this.getExpensesDataset(expenses));
        datasets.push(this.getProfitsDataset(profits));
        return datasets;
    }

    hasTaxableAmount() {
        return this.props.finances
            && this.props.finances.taxables
            && this.props.finances.taxables.reduce((a, b) => a + b, 0) > 0;
    }

    getRevenueDataset(data) {
        const {t} = this.props;
        return {
            label: `${t('property-Finances-Chart-Revenue')}`,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 0.2)',
            borderWidth: 1,
            data: data
        };
    }

    getTaxableRevenuesDataset(data) {
        const {t} = this.props;
        return {
            label: `${t('Taxable')}`,
            backgroundColor: 'rgba(255, 206, 86, 0.2)',
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 1,
            data: data
        };
    }

    getNetRevenuesDataset(data) {
        const {t} = this.props;
        return {
            label: `${t('property-Finances-Chart-Net')}`,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            data: data
        };
    }

    getExpensesDataset(data) {
        const {t} = this.props;
        return {
            label: `${t('property-Finances-Chart-Expenses')}`,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
            borderWidth: 1,
            data: data
        };
    }

    getProfitsDataset(data) {
        const {t} = this.props;
        return {
            label: `${t('property-Finances-Chart-Profit')}`,
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 0.2)',
            borderWidth: 1,
            data: data
        };
    }

    render() {
        const {t} = this.props;
        return (
            <div className="text-center">
                <h3 className='mt-5'>{t('property-Finances')}</h3><br/>
                <Bar
                    height={150}
                    data={this.state.data}
                    width={null}
                    options={this.state.options}
                />
            </div>
        )
    }
}

export default withTranslation()(PropertyFinancesChart);