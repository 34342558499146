import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {roundTwoDecimalCases} from "../../../_helpers/util-functions";
import {getStringDateInCurrentTimeZone} from "../../../_helpers/date-functions";
import {getStringAddress, getStringAddressPostalCode, getStringAddressWithoutPostalCode} from "../../../_helpers/address-functions";
import {dateService} from "../../../_services/date.service";
import {rentPaymentService} from "../../../_services/rentPayment.service";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

class CreateInvoicePdfDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: this.getInitialValues()
        }
    }

    componentDidMount() {
        if (!this.props.location.state) {
            const entityId = this.props.match.params.entityId;
            const propertyId = this.props.match.params.propertyId;
            const contractId = this.props.match.params.contractId;
            const rentId = this.props.match.params.rentId;
            const paymentId = this.props.match.params.paymentId;
            rentPaymentService.getInvoicePdfInformation(entityId, propertyId, contractId, rentId, paymentId)
                .then(
                    response => {
                        const initialValues = this.updateInitialValues(response);
                        this.setState({initialValues});
                    }
                );
        }
    }

    render() {
        const {t} = this.props;
        const validationSchema = {
            landlordName: Yup.string().required(),
            landlordAddress: Yup.string().required(),
            landlordPostalCode: Yup.string().required(),
            landlordPhoneNumber: Yup.string().required(),
            landlordTaxNumber: Yup.string().required(),

            tenantTaxNumber: Yup.string().required(),
            propertyAddress: Yup.string().required(),

            tenantName: Yup.string().required(),
            tenantAddress: Yup.string().required(),
            tenantPostalCode: Yup.string().required(),

            receiptNumber: Yup.string().required(),
            paymentDate: Yup.string().required(),
            emissionDate: Yup.string().required(),

            rentPeriod: Yup.string().required(),
            rentAmount: Yup.number().typeError("Invalid number")
                .positive("Must be a positive number")
                .required(),
            retainedAmount: Yup.number().nullable()
                .typeError("Invalid number")
                .positive("Must be a positive number"),
            amountToPay: Yup.number()
                .typeError("Invalid number")
                .positive("Must be a positive number")
                .nullable(),
            amountToPayText: Yup.string().required()
        };
        const entityId = this.props.match.params.entityId;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('invoicePdfForm-Generate Invoice')}</h4>
                        </div>
                    </div>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={this.state.initialValues}
                    validationSchema={Yup.object().shape(validationSchema)}
                    onSubmit={
                        (
                            {
                                landlordName, landlordAddress, landlordPostalCode, landlordPhoneNumber, landlordTaxNumber,
                                propertyAddress,
                                tenantTaxNumber, tenantName, tenantAddress, tenantPostalCode,
                                receiptNumber, paymentDate, emissionDate,
                                rentPeriod, rentAmount, retainedAmount, amountToPayText
                            },
                            {setStatus, setSubmitting}) => {

                            const amountToPay = roundTwoDecimalCases(rentAmount - retainedAmount);

                            const formValues = {
                                landlordName, landlordAddress, landlordPostalCode, landlordPhoneNumber, landlordTaxNumber,
                                propertyAddress,
                                tenantTaxNumber, tenantName, tenantAddress, tenantPostalCode,
                                receiptNumber, paymentDate, emissionDate,
                                rentPeriod, rentAmount, retainedAmount, amountToPay, amountToPayText
                            };

                            setStatus();
                            setSubmitting(false);
                            const propertyId = this.props.match.params.propertyId;
                            const contractId = this.props.match.params.contractId;
                            const rentId = this.props.match.params.rentId;
                            const paymentId = this.props.match.params.paymentId;
                            this.props.history.push(`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${rentId}/payments/${paymentId}/pdf/validate`, formValues);
                        }}
                    render={({errors, values, touched, isSubmitting}) => (
                        <Form>
                            <div className="form-group required">
                                <label htmlFor="landlordName" className="control-label">{t('invoicePdfForm-Landlord Name')}</label>
                                <Field name="landlordName" type="text"
                                       className={'form-control' + (errors.landlordName && touched.landlordName ? ' is-invalid' : '')}/>
                                <ErrorMessage name="landlordName" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="landlordAddress" className="control-label">{t('invoicePdfForm-Landlord Address')}</label>
                                <Field name="landlordAddress" type="text"
                                       className={'form-control' + (errors.landlordAddress && touched.landlordAddress ? ' is-invalid' : '')}/>
                                <ErrorMessage name="landlordAddress" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="landlordPostalCode" className="control-label">{t('invoicePdfForm-Landlord Postal Code')}</label>
                                <Field name="landlordPostalCode" type="text"
                                       className={'form-control' + (errors.landlordPostalCode && touched.landlordPostalCode ? ' is-invalid' : '')}/>
                                <ErrorMessage name="landlordPostalCode" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="landlordPhoneNumber" className="control-label">{t('invoicePdfForm-Landlord Phone Number')}</label>
                                <Field name="landlordPhoneNumber" type="text"
                                       className={'form-control' + (errors.landlordPhoneNumber && touched.landlordPhoneNumber ? ' is-invalid' : '')}/>
                                <ErrorMessage name="landlordPhoneNumber" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="landlordTaxNumber" className="control-label">{t('invoicePdfForm-Landlord Tax Number')}</label>
                                <Field name="landlordTaxNumber" type="text"
                                       className={'form-control' + (errors.landlordTaxNumber && touched.landlordTaxNumber ? ' is-invalid' : '')}/>
                                <ErrorMessage name="landlordTaxNumber" component="div" className="invalid-feedback"/>
                            </div>

                            <div className="form-group required">
                                <label htmlFor="propertyAddress" className="control-label">{t('invoicePdfForm-Property Address')}</label>
                                <Field name="propertyAddress" type="text"
                                       className={'form-control' + (errors.propertyAddress && touched.propertyAddress ? ' is-invalid' : '')}/>
                                <ErrorMessage name="propertyAddress" component="div" className="invalid-feedback"/>
                            </div>

                            <div className="form-group required">
                                <label htmlFor="tenantName" className="control-label">{t('invoicePdfForm-Tenant Name')}</label>
                                <Field name="tenantName" type="text"
                                       className={'form-control' + (errors.tenantName && touched.tenantName ? ' is-invalid' : '')}/>
                                <ErrorMessage name="tenantName" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="tenantAddress" className="control-label">{t('invoicePdfForm-Tenant Address')}</label>
                                <Field name="tenantAddress" type="text"
                                       className={'form-control' + (errors.tenantAddress && touched.tenantAddress ? ' is-invalid' : '')}/>
                                <ErrorMessage name="tenantAddress" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="tenantPostalCode" className="control-label">{t('invoicePdfForm-Tenant Postal Code')}</label>
                                <Field name="tenantPostalCode" type="text"
                                       className={'form-control' + (errors.tenantPostalCode && touched.tenantPostalCode ? ' is-invalid' : '')}
                                       placeholder="Ex: 8000-242 Faro"/>
                                <ErrorMessage name="tenantPostalCode" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="tenantTaxNumber" className="control-label">{t('invoicePdfForm-Tenant Tax Number')}</label>
                                <Field name="tenantTaxNumber" type="text"
                                       className={'form-control' + (errors.tenantTaxNumber && touched.tenantTaxNumber ? ' is-invalid' : '')}/>
                                <ErrorMessage name="tenantTaxNumber" component="div" className="invalid-feedback"/>
                            </div>

                            <div className="form-group required">
                                <label htmlFor="receiptNumber" className="control-label">{t('invoicePdfForm-Receipt Number')}</label>
                                <Field name="receiptNumber" type="text"
                                       className={'form-control' + (errors.receiptNumber && touched.receiptNumber ? ' is-invalid' : '')}/>
                                <ErrorMessage name="receiptNumber" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="paymentDate" className="control-label">{t('invoicePdfForm-Payment Date')}</label>
                                <Field name="paymentDate" type="text"
                                       className={'form-control' + (errors.paymentDate && touched.paymentDate ? ' is-invalid' : '')}/>
                                <ErrorMessage name="paymentDate" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="emissionDate" className="control-label">{t('invoicePdfForm-Emission Date')}</label>
                                <Field name="emissionDate" type="text"
                                       className={'form-control' + (errors.emissionDate && touched.emissionDate ? ' is-invalid' : '')}/>
                                <ErrorMessage name="emissionDate" component="div" className="invalid-feedback"/>
                            </div>

                            <div className="form-group required">
                                <label htmlFor="rentPeriod" className="control-label">{t('invoicePdfForm-Rent Period')}</label>
                                <Field name="rentPeriod" type="text"
                                       className={'form-control' + (errors.rentPeriod && touched.rentPeriod ? ' is-invalid' : '')}
                                       placeholder="Ex: Novembro de 2019"/>
                                <ErrorMessage name="rentPeriod" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="rentAmount" className="control-label">{t('invoicePdfForm-Rent Amount')}</label>
                                <Field name="rentAmount" type="text"
                                       className={'form-control' + (errors.rentAmount && touched.rentAmount ? ' is-invalid' : '')}
                                       placeholder="Ex: 750"/>
                                <ErrorMessage name="rentAmount" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="retainedAmount" className="control-label">{t('invoicePdfForm-Amount Retained')}</label>
                                <Field name="retainedAmount" type="text"
                                       className={'form-control' + (errors.retainedAmount && touched.retainedAmount ? ' is-invalid' : '')}/>
                                <ErrorMessage name="retainedAmount" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="amountToPay" className="control-label">{t('invoicePdfForm-Amount to Pay')}</label>
                                <Field name="amountToPay" type="text"
                                       className={'form-control' + (errors.amountToPay && touched.amountToPay ? ' is-invalid' : '')}
                                       value={Math.round((values.rentAmount - values.retainedAmount) * 100) / 100}
                                       readOnly={true}/>
                                <ErrorMessage name="amountToPay" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="form-group required">
                                <label htmlFor="amountToPayText" className="control-label">{t('invoicePdfForm-Amount to Pay Text')}</label>
                                <Field name="amountToPayText" type="text"
                                       className={'form-control' + (errors.amountToPayText && touched.amountToPayText ? ' is-invalid' : '')}
                                       placeholder="Ex: Setecentos e trinta euros"/>
                                <ErrorMessage name="amountToPayText" component="div" className="invalid-feedback"/>
                            </div>


                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting} on>
                                    {t('invoicePdfForm-Validate Invoice Pdf')}
                                </button>
                                {
                                    isSubmitting &&
                                    <img
                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                }
                            </div>
                        </Form>
                    )}
                />
            </div>
        );
    }

    getInitialValues() {
        if (this.props.location.state) {
            return this.props.location.state;
        }
        return {
            landlordName: '',
            landlordAddress: '',
            landlordPostalCode: '',
            landlordPhoneNumber: '',
            landlordTaxNumber: '',

            propertyAddress: '',

            tenantTaxNumber: '',
            tenantName: '',
            tenantAddress: '',
            tenantPostalCode: '',

            receiptNumber: '',
            paymentDate: '',
            emissionDate: '',

            rentPeriod: '',
            rentAmount: '',
            retainedAmount: '',
            amountToPay: '',
            amountToPayText: ''
        };
    }

    updateInitialValues(response) {
        const initialValues = this.state.initialValues;
        initialValues.landlordName = response.entityLegalName || '';
        initialValues.landlordAddress = response.entityAddress || '';
        initialValues.landlordPostalCode = response.entityPostalCode || '';
        initialValues.landlordPhoneNumber = response.entityPhone || '';
        initialValues.landlordTaxNumber = response.entityFinanceNumber || '';
        initialValues.propertyAddress = getStringAddress(response.propertyAddress);
        initialValues.tenantTaxNumber = response.tenantFiscalNumber || '';
        initialValues.tenantName = response.tenantName || '';
        if (response.tenantAddress) {
            initialValues.tenantAddress = response.tenantAddress;
            initialValues.tenantPostalCode = response.tenantPostalCode;
        } else {
            initialValues.tenantAddress = getStringAddressWithoutPostalCode(response.propertyAddress);
            initialValues.tenantPostalCode = getStringAddressPostalCode(response.propertyAddress);
        }
        initialValues.paymentDate = getStringDateInCurrentTimeZone(response.paymentDate);
        initialValues.emissionDate = getStringDateInCurrentTimeZone(new Date());
        const paymentPeriod = new Date(response.paymentPeriodDate);
        initialValues.rentPeriod = dateService.getMonthNamePt(paymentPeriod) + " de " + paymentPeriod.getFullYear();
        initialValues.rentAmount = (response.retainedAmount || !response.taxableAmount) ? response.amount : response.taxableAmount;
        initialValues.retainedAmount = response.retainedAmount;
        return initialValues;
    }
}

export default withTranslation()(CreateInvoicePdfDocument);