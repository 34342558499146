import {userEntityService} from "./userEntity.service";

export const permissionService = {
    hasEditAccess,
    hasCreateAccess,
    hasDeleteAccess
};

function hasEditAccess(entityId) {
    return "ADMIN" === userEntityService.getUserEntityAccess(entityId);
}

function hasCreateAccess(entityId) {
    return "ADMIN" === userEntityService.getUserEntityAccess(entityId);
}

function hasDeleteAccess(entityId) {
    return "ADMIN" === userEntityService.getUserEntityAccess(entityId);
}
