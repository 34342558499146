import React from 'react';
import {useTranslation} from "react-i18next";
import {MDBIcon} from "mdbreact";
import ReactTooltip from "react-tooltip";
import {PropertyImage} from "./PropertyImage";
import {Card, CardBody, CardHeader} from 'reactstrap';
import {getIcon} from "../../../_helpers/Icon";
import {getStringAddress} from "../../../_helpers/address-functions";
import {Link} from "react-router-dom";
import {Money} from "../../../_helpers/NumberUtils";
import {getStringDateInCurrentTimeZone, isDateInCurrYear} from "../../../_helpers/date-functions";
import {getProfit} from "../../../_services/metrics.service";
import PropertyFinancesChart from './PropertyFinancesChart';
import {ActiveContract, Expense, Property, PropertyOverviewI} from "./Property";

interface Props {
    entityId: string,
    propertyId: string,
    property?: Property,
    contract?: ActiveContract,
    children: Array<PropertyOverviewI>,
    expenses: Array<Expense>
}

export const PropertyOverview = ({entityId, propertyId, property, contract, children, expenses}: Props) => {
    const {t} = useTranslation();

    return (
        <>
            <div>
                <b>{t(`properties-${property?.baseInfo.propertyType.toUpperCase()}`)} </b>
                {
                    property?.parent &&
                    <Link to={`/entities/${entityId}/properties/${property?.parent.id}`}>
                        ({t('property-Parent')} <MDBIcon icon={getIcon(property?.parent.propertyTypeId)}/> <u>{t(`properties-${property?.parent.propertyType.toUpperCase()}`)}</u>)
                    </Link>
                }
                <br/>
                <MDBIcon icon="map-marker-alt"/> {getStringAddress(property?.baseInfo.address)}
                {
                    property?.baseInfo.code && <><br/><strong>{t('properties-Tax code')}:</strong> {property?.baseInfo.code}</>
                }
            </div>
            <div className="row mt-25">
                <div className="col-md-4">
                    <PropertyImage propertyType={property?.baseInfo.propertyType}/>
                </div>
                <div className="col-md-8">

                    <div className="row justify-content-md-center">
                        <div className="col-md-4">
                            <Card className="card-details" data-tip data-for="valueCardTip">
                                <CardHeader style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}}>
                                    <MDBIcon icon="dollar-sign"/> {t('property-card-Value')}
                                    {
                                        !property?.baseInfo.value && getValue() > 0
                                            ? <small> (<i>Inner Properties</i>)</small>
                                            : <></>
                                    }
                                </CardHeader>
                                <CardBody className="vertical-center-container">
                                    <div className="vertical-center">
                                        <div className="text-center">
                                            <Money number={getValue() || 0} currency={property?.baseInfo.currency || ''}/>
                                            {
                                                getTotalExpenses() > 0 &&
                                                <>
                                                    <hr className={"divider"}/>
                                                    + <Money number={getTotalExpenses() || 0} currency={property?.baseInfo.currency || ''}/>
                                                    <small><strong> (Exp)</strong></small>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <ReactTooltip id="valueCardTip" place="top" effect="solid">
                                {t('property-Value')} / {t('property-Expenses')}<br/>
                                {(children?.length || 0) > 0 && t('property-Includes expenses from inner properties')}
                            </ReactTooltip>
                        </div>
                        <div className="col-md-4">
                            <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contract?.id}`}
                                  className={!contract?.id ? 'disabledCursor' : ''}
                                  onClick={(event) => {
                                      if (!contract?.id) {
                                          event.preventDefault()
                                      }
                                  }}>
                                <Card className="card-details" data-tip data-for="contractCardTip">
                                    <CardHeader style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}}>
                                        <MDBIcon icon="file-invoice-dollar"/> {t('property-card-Contract')}
                                        {
                                            !contract?.amount && getContractValue() > 0
                                                ? <small> (<i>{t('property-card-Inner Properties')}</i>)</small>
                                                : <></>
                                        }
                                    </CardHeader>
                                    <CardBody className="vertical-center-container">
                                        <div className="vertical-center">
                                            {
                                                getContractValue() > 0 &&
                                                <div className="text-center">
                                                    <Money number={getContractValue()} currency={contract?.currency || ''}/>
                                                    <hr className={"divider"}/>
                                                    <Money number={getContractValueAfterTaxes()} currency={contract?.currency || ''}/>
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Link>
                            <ReactTooltip id="contractCardTip" place="top" effect="solid">
                                {t('Gross')} / {t('Net')}
                            </ReactTooltip>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className="row justify-content-md-center">
                        <div className="col-md-4">
                            <Card className="card-details" data-tip data-for="profitCardTip">
                                <CardHeader style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}}>
                                    <MDBIcon icon="percent"/> {t('property-card-Profit')}
                                </CardHeader>
                                <CardBody className="vertical-center-container">
                                    <div className="vertical-center">
                                        {
                                            (getGrossProfit() || 0) > 0 &&
                                            <div className="text-center">
                                                {getGrossProfit()} %
                                                <hr className={"divider"}/>
                                                {getNetProfit()} %
                                            </div>
                                        }
                                    </div>
                                </CardBody>
                                <ReactTooltip id="profitCardTip" place="top" effect="solid" className="text-center">
                                    {t('property-Profit-tip')}
                                    <hr className={"divider"}/><br/>
                                    {t('Gross')} / {t('Net')}
                                </ReactTooltip>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Link to={`/entities/${entityId}/properties/${propertyId}/contracts/${contract?.id}`}
                                  className={!contract?.id ? 'disabledCursor' : ''}
                                  onClick={(event) => {
                                      if (!contract?.id) {
                                          event.preventDefault()
                                      }
                                  }}>
                                <Card className="card-details">
                                    <CardHeader style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}}>
                                        <MDBIcon icon="clock"/> {t('property-card-Contract Duration')}
                                    </CardHeader>
                                    <CardBody className="vertical-center-container">
                                        <div className="vertical-center">
                                            {
                                                contract &&
                                                <div className="text-center">
                                                    {getStringDateInCurrentTimeZone(contract?.startDate)}
                                                    <hr className={"divider"}/>
                                                    {getStringDateInCurrentTimeZone(contract?.endDate)}
                                                </div>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <PropertyFinancesChart finances={property?.finances}/>
        </>
    );

    function getValue() {
        return property?.baseInfo.value || children?.reduce((acc, child) => acc + child.valueHierarchy, 0) || 0;
    }

    function getTotalExpenses() {
        return (expenses.reduce((acc, exp) => acc + exp.amount, 0) || 0)
            + (children?.reduce((acc, child) => acc + child.expensesAmountHierarchy, 0) || 0);
    }

    function getYearExpenses() {
        return (expenses.reduce((acc, exp) => acc + (isDateInCurrYear(exp.date) ? exp.amount : 0), 0) || 0)
            + (children?.reduce((acc, child) => acc + child.expensesAmountYearHierarchy, 0) || 0);
    }


    function getContractValue() {
        if (contract) {
            return contract.amount + contract.extraAmount;
        }
        return children?.reduce((acc, child) => acc + child.contractAmountHierarchy, 0) || 0;
    }

    function getContractValueAfterTaxes() {
        if (contract) {
            return contract.amountAfterTaxes;
        }
        return children?.reduce((acc, child) => acc + child.contractNetAmountHierarchy, 0) || 0;
    }

    function getGrossProfit() {
        if (getValue() === 0) {
            return 0;
        }
        return getProfit(getValue(), getContractValue(), getTotalExpenses());
    }

    function getNetProfit() {
        if (getValue() === 0) {
            return 0;
        }
        return getProfit(getValue(), getContractValueAfterTaxes(), getTotalExpenses());
    }
}