import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {tenantService} from "../../../_services";
import {RouteComponentProps, useHistory} from "react-router";
import {Contract} from "../../Contracts/ListContractsPage/ListContracts";
import {MDBIcon} from "mdbreact";
import ReactTooltip from "react-tooltip";
import {permissionService} from "../../../_services/permission.service";
import BootstrapTable from "react-bootstrap-table-next";
import {orderFormatter} from "../../../_helpers/util-functions";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";

export interface Tenant {
    id: number,
    email: string,
    fiscalNumber: string,
    name: string,
    note: string,
    numActiveContracts: number,
    phone: string
}

export const ListTenants = ({match}: RouteComponentProps<Props>) => {
    const entityId = match.params.entityId;

    const {t} = useTranslation();
    const [view, setView] = useState(ACTIVE_TENANTS);
    const [allTenants, setAllTenants] = useState([]);
    const [tenantsView, setTenantsView] = useState([]);

    React.useEffect(() => {
        tenantService.getAll(entityId)
            .then(tenants => {
                setAllTenants(tenants);
                setView(ACTIVE_TENANTS);
                setTenantsView(tenants.filter((t: Tenant) => t.numActiveContracts > 0));
            });
    }, []);

    function viewSelected(selectedView: string) {
        setView(selectedView);
        setTenantsOfSelectedView(selectedView);
    }

    function setTenantsOfSelectedView(selectedView: string) {
        if (selectedView === ACTIVE_TENANTS) {
            setTenantsView(allTenants.filter((tenant: Tenant) => tenant.numActiveContracts > 0));
        } else if (selectedView === OLD_TENANTS) {
            setTenantsView(allTenants.filter((tenant: Tenant) => tenant.numActiveContracts === 0));
        }
    }

    const history = useHistory();

    function getRowEvents() {
        return {
            onClick: (e: Object, row: Contract, rowIndex: number) => {
                history.push(`/entities/${entityId}/tenants/${row.id}`);
            }
        }
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            hidden: true
        }, {
            dataField: 'name',
            text: `${t('tenants-header-Name')}`,
            sort: true,
            headerStyle: () => {
                return {width: '20%'};
            },
            headerFormatter: orderFormatter
        }, {
            dataField: 'fiscalNumber',
            text: `${t('tenants-header-Fiscal Number')}`,
            sort: true,
            headerStyle: () => {
                return {width: '15%'};
            },
            headerFormatter: orderFormatter
        }, {
            dataField: 'phone',
            text: `${t('tenants-header-Contact')}`,
            headerStyle: () => {
                return {width: '20%'};
            },
            formatter: (cell: string, row: Tenant) => (
                <div>
                    {cell} <br/>
                    {row.email}
                </div>
            )
        }, {
            dataField: 'note',
            text: `${t('tenants-header-Note')}`,
            headerStyle: () => {
                return {width: '45%'};
            },
            headerAlign: 'center'
        }
    ];

    return (
        <div>
            <Navigation entityId={entityId}/>
            <div className="row">
                <div className="col-xl-6 mx-auto text-center">
                    <div className="section-title">
                        <h4>{t('navBar-Tenants')}</h4>
                    </div>
                </div>
            </div>
            <br/>
            <div>
                <div className="card card-cascade narrower">
                    <div className="row view view-cascade py-3 gradient-card-header info-color-dark d-flex justify-content-between align-items-center" style={{borderRadius: "5px"}}>
                        <div className="col-md-3">
                                <span className={view === ACTIVE_TENANTS ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="activeContractsTip">
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            onClick={() => viewSelected(ACTIVE_TENANTS)}>
                                        <MDBIcon icon="calendar-check" size="2x"/>
                                    </button>
                                    <ReactTooltip id="activeContractsTip" place="top" effect="solid">
                                            {t('tenants-ACTIVE_TENANTS')}
                                    </ReactTooltip>
                                </span>
                            <span className={view === OLD_TENANTS ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="expiredContractsTip">
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            onClick={() => viewSelected(OLD_TENANTS)}>
                                        <MDBIcon icon="flag-checkered" size="2x"/>
                                    </button>
                                    <ReactTooltip id="expiredContractsTip" place="top" effect="solid">
                                            {t('tenants-OLD_TENANTS')}
                                    </ReactTooltip>
                                </span>

                        </div>

                        <div className="col-md-6 text-center">
                            <h5 className="white-text mx-3">
                                <strong>{t(`tenants-${view}`)}</strong>
                            </h5>
                        </div>

                        <div className="col-md-3 text-right">
                            {
                                permissionService.hasCreateAccess(entityId) &&
                                <>
                                    <Link to={`/entities/${entityId}/tenant/new`}>
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="creteContractTip">
                                            <MDBIcon icon="plus"/> <MDBIcon icon="user" size="2x"/>
                                        </button>
                                        <ReactTooltip id="creteContractTip" place="top" effect="solid">
                                            {t('Create a new Tenant')}
                                        </ReactTooltip>
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                    <div>
                        {
                            tenantsView.length === 0
                                ? <h2 className="no-data-to-display mt-160">{view === ACTIVE_TENANTS ? 'No active tenants' : 'No past tenants'}<br/><br/></h2>
                                : <div className="mt-50 overflow-x:auto">
                                    <BootstrapTable
                                        keyField='id'
                                        data={tenantsView}
                                        columns={columns}
                                        rowEvents={getRowEvents()}
                                        hover={true}
                                        bordered={false}
                                        wrapperClasses={"table-responsive"}/>
                                </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}

const ACTIVE_TENANTS = "ACTIVE_TENANTS";
const OLD_TENANTS = "OLD_TENANTS";

type Props = {
    entityId: string;
}