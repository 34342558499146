import {handleResponse} from "../_helpers/handle-response";
import {authHeader, jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {BehaviorSubject} from "rxjs";
import {getPrimitiveCleanValue, getUtcDateCleanValue} from "../_helpers/util-functions";

const currentEntitySubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentEntity')));

export const entityService = {
    getAll,
    getEntityOverview,
    getEntityFinanceSummary,
    getEntity,
    deleteEntity,
    createEntity,
    updateEntity,
    uploadFile,
    getFileUrl,

    setCurrentEntity,
    currentEntity: currentEntitySubject.asObservable(),
    get currentEntityValue() {
        return currentEntitySubject.value
    }
};

function setCurrentEntity(entityId) {
    if (entityId === 'undefined' || entityId === undefined || !entityId) {
        localStorage.setItem('currentEntity', null);
    } else {
        localStorage.setItem('currentEntity', entityId);
    }
}

function getAll() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/entities', requestOptions).then(handleResponse);
}

function getEntityOverview(entityId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/overview`, requestOptions).then(handleResponse);
}

function getEntityFinanceSummary(entityId, starDate, endDate) {
    let url = SERVER_URL + `/entities/${entityId}/financeSummary`;
    if (starDate) {
        url += `?startDate=${getUtcDateCleanValue(starDate)}`;
    }
    if (endDate) {
        url += (starDate != null ? "&" : "?") + "endDate=" + getUtcDateCleanValue(endDate);
    }
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(url, requestOptions).then(handleResponse);
}

function getEntity(entityId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}`, requestOptions).then(handleResponse);
}

function deleteEntity(entityId) {
    const requestOptions = {method: 'DELETE', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}`, requestOptions).then(handleResponse);
}

function cleanEntityAttributes(name, entityTypeId, legalName, financeNumber, countryId, description, phone, address, postalCode) {
    let attributes = {name, entityTypeId};

    attributes["legalName"] = getPrimitiveCleanValue(legalName);
    attributes["financeNumber"] = getPrimitiveCleanValue(financeNumber);
    attributes["countryId"] = countryId;
    attributes["description"] = getPrimitiveCleanValue(description);
    attributes["phone"] = getPrimitiveCleanValue(phone);
    attributes["address"] = getPrimitiveCleanValue(address);
    attributes["postalCode"] = getPrimitiveCleanValue(postalCode);
    return attributes;
}

function createEntity(name, entityTypeId, legalName, financeNumber, countryId, description, phone, address, postalCode) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanEntityAttributes(name, entityTypeId, legalName, financeNumber, countryId, description, phone, address, postalCode))
    };
    return fetch(SERVER_URL + "/entities", requestOptions).then(handleResponse);
}

function updateEntity(entityId, name, entityTypeId, legalName, financeNumber, countryId, description, phone, address, postalCode) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanEntityAttributes(name, entityTypeId, legalName, financeNumber, countryId, description, phone, address, postalCode))
    };
    return fetch(SERVER_URL + `/entities/${entityId}`, requestOptions).then(handleResponse);
}

function uploadFile(entityId, formData) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken()},
        body: formData
    };
    return fetch(SERVER_URL + `/entities/${entityId}/files`, requestOptions).then(handleResponse);
}

function getFileUrl(entityId, fileId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/files/${fileId}`, requestOptions).then(handleResponse);
}

