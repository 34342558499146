import React from 'react';
import ExpensesTable from "../../../../Expenses/_aux/ExpensesTable";
import {useTranslation} from "react-i18next";

interface Props {
    entityId: number,
    expenses: Object[],
    history: Object
}

export const EntityExpenses = ({entityId, expenses, history}: Props) => {
    const {t} = useTranslation();
    return (
        <ExpensesTable
            entityId={entityId}
            expenses={expenses}
            history={history}
            hideYieldColumn={true}
            emptyMessage={t('overview-Entity Expenses Empty')}
        />

    );
}