import React from 'react';
import {Contract, Tenant} from "../ListContractsPage/ListContracts";
import {useHistory} from "react-router";
import {Money} from "../../../_helpers/NumberUtils";
import {orderFormatter} from "../../../_helpers/util-functions";
import {getStringAddress} from "../../../_helpers/address-functions";
import {getStringDateInCurrentTimeZone} from "../../../_helpers/date-functions";
import BootstrapTable from "react-bootstrap-table-next";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {
    contracts: Contract[]
}

export const ContractsTable = ({contracts}: Props) => {

    const {t} = useTranslation();
    const history = useHistory();

    const rowEvents = {
        onClick: (e: Object, row: Contract, rowIndex: number) => {
            history.push(`/entities/${row.entityId}/properties/${row.property.id}/contracts/${row.id}`);
        }
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            hidden: true
        }, {
            dataField: 'property.address.street',
            text: `${t('contracts-header-Property')}`,
            sort: true,
            headerAlign: "center",
            headerStyle: () => {
                return {width: '320px', minWidth: '320px'};
            },
            headerFormatter: orderFormatter,
            formatter: (cell: String, row: Contract) => {
                return (
                    <div>
                        <b>{t(`properties-${row.property.propertyType.toUpperCase()}`)}</b>
                        {row.property.name && <small> - {row.property.name}</small>}
                        <br/>
                        <small style={{color: "#0062cc"}}>{getStringAddress(row.property.address)}</small>
                    </div>
                );
            }
        }, {
            dataField: 'endDate',
            text: `${t('contracts-header-Duration')}`,
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerFormatter: orderFormatter,
            formatter: (cell: Date, row: Contract) => (
                <div className="text-center">
                    {getStringDateInCurrentTimeZone(row.startDate)}
                    <hr className={"divider"}/>
                    {getStringDateInCurrentTimeZone(cell)}
                </div>
            ),
            headerStyle: () => {
                return {width: '150px', minWidth: '120px'};
            }
        }, {
            dataField: 'endDateRenegotiationPeriod',
            text: `${t('contracts-header-Renegotiation')}`,
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerFormatter: orderFormatter,
            formatter: (cell: Date, row: Contract) => (
                <div className="text-center">
                    {
                        row.startDateRenegotiationPeriod &&
                        <>
                            {getStringDateInCurrentTimeZone(row.startDateRenegotiationPeriod)}
                        </>
                    }
                    {
                        (row.startDateRenegotiationPeriod || cell) &&
                        <hr className={"divider"}/>
                    }
                    {
                        cell &&
                        <>
                            {getStringDateInCurrentTimeZone(cell)}
                        </>
                    }
                </div>
            ),
            headerStyle: () => {
                return {width: '150px', minWidth: '120px'};
            }
        }, {
            dataField: 'tenants',
            text: `${t('contracts-header-Tenants')}`,
            headerAlign: 'center',
            formatter: (cell: [Tenant], row: Contract) => (
                <div>
                    {cell && cell.map(function (t) {
                        return (
                            <li key={t.id}>
                                <Link to={"/entities/" + row.entityId + "/tenants/" + t.id}>
                                    {t.name}
                                </Link>
                            </li>
                        )
                    })}
                    <br/>
                </div>
            ),
            headerStyle: () => {
                return {width: '190px', minWidth: '180px'};
            }
        }, {
            dataField: 'amount',
            text: `${t('contracts-header-Amount')}`,
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerFormatter: orderFormatter,
            formatter: (cell: number, row: Contract) => (
                <div>
                    <Money number={cell} currency={row.currency}/>
                    {
                        row.extraAmount &&
                        <><br/>+ <Money number={row.extraAmount} currency={row.currency}/></>
                    }
                </div>
            ),
            headerStyle: () => {
                return {width: '90px', minWidth: '90px'};
            }
        }, {
            dataField: 'debt',
            text: `${t('contracts-header-Debt')}`,
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerFormatter: orderFormatter,
            formatter: (cell: number, row: Contract) => (
                <>
                    {cell > 0 ? <Money number={cell} currency={row.currency}/> : <></>}
                </>
            ),
            headerStyle: () => {
                return {width: '90px', minWidth: '90px'};
            }
        }
    ];

    return (
        <>
            {
                (contracts && contracts.length === 0)
                    ? <><br/><h3 className="no-data-to-display">{t('contracts-No contracts')}</h3><br/></>
                    : <BootstrapTable
                            keyField='id'
                            data={contracts}
                            columns={columns}
                            rowEvents={rowEvents}
                            hover={true}
                            bordered={false}
                            wrapperClasses={"table-responsive"}/>
            }
        </>
    );
}