import React from "react";
import {withTranslation} from "react-i18next";
import {propertyService} from "../../../_services";
import {errorToast} from "../../../_helpers/toast-functions";
import FilesTableCard from "../../../_components/FilesTableCard";

class PropertyFiles extends React.Component {
    constructor(props) {
        super(props);
    }

    rowEvents = {
        onClick: (e, row, rowIndex) => {
            propertyService.getFileUrl(this.props.entityId, this.props.propertyId, row.id)
                .then(response => {
                        window.open(response.url);
                    },
                    error => {
                        const {t} = this.props;
                        errorToast(`${t('toast-Error while getting file')}.`);
                    });
        }
    };


    render() {
        return (
            <div id="files" className="inner-list">
                <div className="section-title text-center">
                    <h4></h4>
                </div>
                <br/>

                <FilesTableCard
                    files={this.props.files}
                    rowEvents={this.rowEvents}
                />
            </div>
        );
    }
}

export default withTranslation()(PropertyFiles);

