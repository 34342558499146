export function getStringDateInCurrentTimeZone(date) {
    if (date == null) {
        return '';
    }
    return new Date(date).toLocaleString().split(',')[0].replace('/', '-').replace('/', '-');
}

export function getMonthYearOfDate(date) {
    if (date == null) {
        return '';
    }
    const zoneDate = new Date(date);
    const month = ('0' + (zoneDate.getMonth() + 1)).slice(-2);
    return month + " / " + zoneDate.getFullYear();
}

export function isDateInCurrYear(date) {
    return (new Date(date)).getFullYear() === (new Date()).getFullYear();
}

export function isDateBetweenMonths(date, from, to) {
    return areDatesInSameMonth(date, from)
        || areDatesInSameMonth(date, to)
        || (new Date(date) > from && new Date(date) < to)
}

export function areDatesInSameMonth(firstDateStr, secondDateStr) {
    const firstDate = new Date(firstDateStr);
    const secondDate = new Date(secondDateStr);
    return firstDate && secondDate
        && firstDate.getFullYear() === secondDate.getFullYear()
        && firstDate.getMonth() === secondDate.getMonth();
}

export function isFirstDateInSameMonthOrBeforeSecondDate(firstDateStr, secondDateStr) {
    return areDatesInSameMonth(firstDateStr, secondDateStr) || new Date(firstDateStr) < new Date(secondDateStr);
}

export function getLastDayOfMonth() {
    const now = new Date();
    let lastDay;
    if (now.getMonth() == 11) {
        lastDay = new Date(now.getFullYear() + 1, 0, 0);
    } else {
        lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    }
    lastDay.setHours(12);
    return lastDay;
}

export function getNextMonth(date) {
    const d = new Date(date);
    let nextMonth;
    if (d.getMonth() === 11) {
        nextMonth = new Date(d.getFullYear() + 1, 0, 15);
    } else {
        nextMonth = new Date(d.getFullYear(), d.getMonth() + 1, 15);
    }
    nextMonth.setHours(12);
    return nextMonth;
}
