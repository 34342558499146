import {BehaviorSubject} from 'rxjs';
import {handleResponse} from '../_helpers/handle-response';
import {SERVER_URL} from "../App";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const currentEmailSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentEmail')));

export const authenticationService = {
    register,
    activateAccount,
    login,
    logout,
    requestResetPassword,
    resetPassword,
    currentUser: currentUserSubject.asObservable(),
    currentEmail: currentEmailSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    get currentEmailValue() {
        return currentEmailSubject.value
    }
};

function register(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email, password})
    };
    return fetch(SERVER_URL + '/auth/register', requestOptions)
        .then(handleResponse);
}

function activateAccount(userEmail, token) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({userEmail, token})
    };
    return fetch(SERVER_URL + '/auth/activateAccount', requestOptions)
        .then(handleResponse);

}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email, password})
    };
    return fetch(SERVER_URL + '/auth/login', requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('currentEmail', JSON.stringify(email));
            currentUserSubject.next(user);
            currentEmailSubject.next(email);

            return user;
        });
}

function requestResetPassword(userEmail) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({userEmail})
    };
    return fetch(SERVER_URL + '/auth/resetPassword', requestOptions)
        .then(handleResponse);
}

function resetPassword(userEmail, newPassword, token) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({userEmail, newPassword, token})
    };
    return fetch(SERVER_URL + '/auth/resetPassword', requestOptions)
        .then(handleResponse);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    localStorage.removeItem('currentEmail');
    currentEmailSubject.next(null);
    localStorage.removeItem('currentEntity');
}
