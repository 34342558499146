import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {errorToast, successToast} from "../../../../../_helpers/toast-functions";
import {userEntityService} from "../../../../../_services/userEntity.service";
import {MDBIcon} from "mdbreact";
import {useTranslation} from "react-i18next";

export function ConfirmDeleteUser(props) {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDeleteUser = () => {
        userEntityService.removeUser(props.entityId, props.userId)
            .then(
                response => {
                    successToast(`${t('toast-User successfully removed')}!`);
                    props.updateUsers(response);
                    setShow(false);
                },
                error => {
                    errorToast(error.message);
                    setShow(false);
                }
            );
    };

    return (
        <>
            <a onClick={handleShow} style={{"color": "#BC0000"}}>
                <MDBIcon icon="trash" style={{color: "#B22222"}}/>
            </a>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                    {t('permissionsForm-Are you sure you want to remove the user from the entity?')} <br/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        {t('permissionsForm-Cancel')}
                    </Button>
                    <Button variant="danger" onClick={handleDeleteUser}>
                        {t('permissionsForm-Delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
