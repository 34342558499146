import React from 'react';
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {filterBy} from '@progress/kendo-data-query';
import {lookupValuesService} from "../../../_services";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {successToast} from "../../../_helpers/toast-functions";
import {CREATE_RENT} from "../CreateRentPage/CreateRent";
import {EDIT_RENT} from "../EditRentPage/EditRent";
import {rentService} from "../../../_services/rent.service";
import {YearMonthCalendar} from "../../../_helpers/YearMonthCalendar";
import {roundTwoDecimalCases} from "../../../_helpers/util-functions";
import {withTranslation} from "react-i18next";
import MyNumberInput from "../../../_components/MyNumberInput";

class RentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currencies: [],
            allCurrencies: [],
            currency: null,
            paymentDueDate: null,
            paymentPeriodDate: null
        };
    }

    componentDidMount() {
        lookupValuesService.getCurrencies().then(response => {
            this.setState({currencies: response});
            this.setState({allCurrencies: response})
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let currencyValue = {currency: prevState.currency};
        let paymentDueDate = {paymentDueDate: prevState.paymentDueDate};
        let paymentPeriodDate = {paymentPeriodDate: prevState.paymentPeriodDate};

        if (!prevState.currency) {
            currencyValue = {currency: setCurrency(nextProps, prevState)};
        }
        if (!prevState.paymentDueDate && nextProps.paymentDueDate) {
            paymentDueDate = {paymentDueDate: new Date(nextProps.paymentDueDate)};
        }
        if (!prevState.paymentPeriodDate && nextProps.paymentPeriodDate) {
            paymentPeriodDate = {paymentPeriodDate: new Date(nextProps.paymentPeriodDate)};
        }
        return ({...currencyValue, ...paymentDueDate, ...paymentPeriodDate});
    }

    handleChange = (event) => {
        switch (event.target.name) {
            case "currency":
                this.setState({currency: event.target.value});
                break;
            case "paymentDueDate":
                const paymentDueDate = new Date(event.target.value);
                paymentDueDate.setHours(12);
                this.setState({paymentDueDate});
                break;
            case "paymentPeriodDate":
                const tmpPaidPeriodDate = new Date(event.target.value);
                tmpPaidPeriodDate.setDate(15);
                tmpPaidPeriodDate.setHours(12);
                this.setState({paymentPeriodDate: tmpPaidPeriodDate});
                break;
            default:
                console.warn("unhandled change event for " + event.target.name);
        }
    };

    filterChange = (event) => {
        switch (event.target.name) {
            case "currency":
                const currencies = this.state.allCurrencies.slice();
                this.setState({currencies: filterBy(currencies, event.filter)});
                break;
            default:
                console.warn("unhandled filter change for " + event.target.name);
        }
    };

    render() {
        const {t} = this.props;
        return (
            <div className="container up">
                <Formik
                    enableReinitialize
                    initialValues={this.props.initialValues}
                    validationSchema={Yup.object().shape(rentValidationSchema)}
                    onSubmit={(
                        {
                            amount, retainedAmount, taxableAmount
                        },
                        {setStatus, setSubmitting}
                    ) => {
                        if (!this.isValidInput()) {
                            setSubmitting(false);
                            let error = this.getErrorMessages();
                            setStatus(error);
                        } else {
                            setStatus();
                            this.createOrUpdateRent(amount, retainedAmount, taxableAmount, setSubmitting, setStatus);
                            setSubmitting(false);
                        }
                    }}
                    render={({errors, status, values, touched, isSubmitting, handleChange, setFieldValue}) => (
                        <Form>

                            <div className="form-group required">
                                <label htmlFor="paymentPeriodDate" className="control-label">{t('rentForm-Payment Period')}</label> <br/>
                                <DatePicker
                                    format={'MM/yyyy'}
                                    calendar={YearMonthCalendar}
                                    onChange={this.handleChange}
                                    name="paymentPeriodDate"
                                    value={this.state.paymentPeriodDate}
                                />
                                <ErrorMessage name="paymentPeriodDate" component="div" className="invalid-feedback"/>
                                {
                                    status && status.paymentPeriodDate &&
                                    <div className="text-danger invalid-input">
                                        {t('rentForm-Payment Period')} {status.paymentPeriodDate}
                                    </div>
                                }
                            </div>

                            <div className="form-group required">
                                <label htmlFor="currency" className="control-label">{t('rentForm-Currency')}</label> <br/>
                                <ComboBox
                                    data={this.state.currencies}
                                    textField="name"
                                    dataItemKey="id"
                                    value={this.state.currency}
                                    name="currency"
                                    onChange={this.handleChange}
                                    filterable={true}
                                    onFilterChange={this.filterChange}
                                />
                                <ErrorMessage name="currency" component="div" className="invalid-feedback"/>
                                {
                                    status && status.currencyId &&
                                    <div className="text-danger invalid-input">
                                        {t('rentForm-Currency')} {status.currencyId}
                                    </div>
                                }
                            </div>

                            <div className="form-group smallInput required">
                                <label htmlFor="amount" className="control-label">{t('rentForm-Amount')}</label>
                                <MyNumberInput
                                    className={'form-control' + (errors.amount && touched.amount ? ' is-invalid' : '')}
                                    placeholder="Ex: 123,98"
                                    value={values.amount}
                                    readOnly={this.state.finalFieldsReadOnly}
                                    onValueChange={val => setFieldValue('amount', val.floatValue)}
                                />
                                <ErrorMessage name="amount" component="div" className="invalid-feedback"/>
                                {
                                    status && status.amount &&
                                    <div className="text-danger invalid-input">
                                        {t('rentForm-Amount')} {status.amount}
                                    </div>
                                }
                            </div>
                            {
                                this.props.taxRetentionPercentage
                                    ? <>
                                        <div className="form-group smallInput">
                                            <label htmlFor="retainedAmount" className="control-label">{t('rentForm-Retained Amount')}</label>
                                            <MyNumberInput
                                                className={'form-control' + (errors.retainedAmount && touched.retainedAmount ? ' is-invalid' : '')}
                                                placeholder="Ex: 123,98"
                                                value={values.retainedAmount}
                                                onValueChange={val => setFieldValue('retainedAmount', val.floatValue)}
                                            />
                                            <ErrorMessage name="retainedAmount" component="div" className="invalid-feedback"/>
                                            {
                                                status && status.retainedAmount &&
                                                <div className="text-danger invalid-input">
                                                    {t('rentForm-Retained Amount')} {status.retainedAmount}
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group smallInput">
                                            <label htmlFor="amountToPay" className="control-label">{t('rentForm-Amount to Pay')}</label>
                                            <MyNumberInput
                                                className={'form-control'}
                                                value={roundTwoDecimalCases(values.amount - values.retainedAmount)}
                                                readOnly={true}
                                            />
                                        </div>
                                    </>
                                    : <div className="form-group smallInput">
                                        <label htmlFor="taxableAmount" className="control-label">{t('rentForm-Taxable Amount')}</label>
                                        <MyNumberInput
                                            className={'form-control' + (errors.taxableAmount && touched.taxableAmount ? ' is-invalid' : '')}
                                            placeholder="Ex: 123,98"
                                            value={values.taxableAmount}
                                            onValueChange={val => setFieldValue('taxableAmount', val.floatValue)}
                                        />
                                        <ErrorMessage name="taxableAmount" component="div" className="invalid-feedback"/>
                                        {
                                            status && status.taxableAmount &&
                                            <div className="text-danger invalid-input">
                                                {t('rentForm-Taxable Amount')} {status.taxableAmount}
                                            </div>
                                        }
                                    </div>
                            }

                            <div className="form-group required">
                                <label htmlFor="paymentDueDate" className="control-label">{t('rentForm-Payment Due Date')}</label> <br/>
                                <DatePicker
                                    format={"dd-MM-yyyy"}
                                    formatPlaceholder="formatPattern"
                                    name="paymentDueDate"
                                    onChange={this.handleChange}
                                    value={this.state.paymentDueDate}
                                />
                                <ErrorMessage name="paymentDueDate" component="div" className="invalid-feedback"/>
                                {
                                    status && status.paymentDueDate &&
                                    <div className="text-danger invalid-input">
                                        {t('rentForm-Payment Due Date')} {status.paymentDueDate}
                                    </div>
                                }
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                    {this.props.page === CREATE_RENT ? <a>{t('Create')}</a> : <a>{t('Update')}</a>}
                                </button>
                                {
                                    isSubmitting &&
                                    <img
                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                }
                            </div>
                            {
                                status && status.message &&
                                <div>
                                    <div className={'alert alert-danger'}>{status.message}</div>
                                </div>
                            }
                        </Form>
                    )}
                />
            </div>
        );
    }

    isValidInput() {
        return this.state.currency != null
            && this.state.paymentDueDate != null
            && this.state.paymentPeriodDate != null;
    }

    getErrorMessages() {
        let error = {};
        if (!this.state.currency) {
            error["currencyId"] = "is required";
        }
        if (!this.state.paymentDueDate) {
            error["paymentDueDate"] = "is required";
        }
        if (!this.state.paymentPeriodDate) {
            error["paymentPeriodDate"] = "is required";
        }
        return error;
    }

    createOrUpdateRent(amount, retainedAmount, taxableAmount, setSubmitting, setStatus) {
        if (this.props.page === CREATE_RENT) {
            this.createRent(amount, retainedAmount, taxableAmount, setSubmitting, setStatus);
        } else if (this.props.page === EDIT_RENT) {
            this.updateRent(amount, retainedAmount, taxableAmount, setSubmitting, setStatus);
        }
    }

    createRent(amount, retainedAmount, taxableAmount, setSubmitting, setStatus) {
        const {t} = this.props;
        rentService.createRent(
            this.props.entityId, this.props.propertyId, this.props.contractId,
            this.state.currency.id, this.state.paymentDueDate, this.state.paymentPeriodDate, amount, retainedAmount, taxableAmount)
            .then(
                response => {
                    successToast(`${t('toast-Rent successfully created')}!`);
                    this.props.history.push(`/entities/${this.props.entityId}/properties/${this.props.propertyId}/contracts/${this.props.contractId}/#rents`);
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }

    updateRent(amount, retainedAmount, taxableAmount, setSubmitting, setStatus) {
        const {t} = this.props;
        rentService.updateRent(
            this.props.entityId, this.props.propertyId, this.props.contractId, this.props.rentId,
            this.state.currency.id, this.state.paymentDueDate, this.state.paymentPeriodDate, amount, retainedAmount, taxableAmount)
            .then(
                response => {
                    successToast(`${t('toast-Rent successfully updated')}!`);
                    this.props.history.push(`/entities/${this.props.entityId}/properties/${this.props.propertyId}/contracts/${this.props.contractId}/#rents`);
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }
}

export const rentValidationSchema = {
    amount: Yup.number().typeError("Invalid number")
        .moreThan(-1, "Must be a positive number")
        .min(0)
        .required(),
    taxableAmount: Yup.number().typeError("Invalid number")
        .moreThan(-1, "Must be a positive number")
        .min(0)
        .nullable(),
    retainedAmount: Yup.number().typeError("Invalid number")
        .moreThan(-1, "Must be a positive number")
        .min(0)
        .nullable()
};


function setCurrency(prop, prevState) {
    if (prevState.currency || !prop.currencyId || !prevState.allCurrencies.length) {
        return null;
    }
    for (const curr of prevState.allCurrencies) {
        if (curr.id === prop.currencyId) {
            return curr;
        }
    }
    return null;
}

export default withTranslation()(RentForm);