import React from 'react';
import {entityService, propertyService} from "../../../_services";
import './Entity.css';
import {MDBIcon} from "mdbreact";
import PermissionsSection from "./sections/permissions/PermissionsSection";
import {ConfirmDeleteEntity} from "./ConfirmDeleteEntity";
import {permissionService} from "../../../_services/permission.service";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {YearMonthCalendar} from "../../../_helpers/YearMonthCalendar";
import {areDatesInSameMonth, getMonthYearOfDate, isDateBetweenMonths} from "../../../_helpers/date-functions";
import {getStringAddressStreetWithNumber} from "../../../_helpers/address-functions";
import {dateService} from "../../../_services/date.service";
import BootstrapTable from "react-bootstrap-table-next";
import {FinanceDetails} from "./sections/financedetails/FinanceDetails";
import ReactTooltip from "react-tooltip";
import {withTranslation} from 'react-i18next';
import {Money} from "../../../_helpers/NumberUtils";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";
import {EntityExpenses} from "./sections/expenses/EntityExpenses";
import {errorToast, infoToast, successToast} from "../../../_helpers/toast-functions";
import FilesTable from "../../../_components/FilesTable";


const PERMISSIONS = "Permissions";
const FINANCES = "Finances";
const EXPENSES = "Expenses";
const FILES = "Files";


class Entity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: null,
            files: [],
            view: FINANCES,
            financeSummary: {
                activeContracts: 0,
                countContractibleProperties: 0,
                contactsAmount: 0,
                rentsAmountToPayPeriod: 0,
                rentsAmountPaidPeriod: 0,
                rentsAmountToRetainPeriod: 0,
                rentsAmountRetainedPeriod: 0,
                expenseAmountPeriod: 0,
                missingRents: 0,
                contractDebt: 0,
                currency: null
            },
            financeDetails: [],
            financeDetailsStartDate: new Date(),
            financeDetailsEndDate: new Date(),
            expenses: []
        };
    }

    filesRowEvents = {
        onClick: (e, row, rowIndex) => {
            entityService.getFileUrl(this.props.match.params.entityId, row.id)
                .then(response => {
                        window.open(response.url);
                    },
                    error => {
                        const {t} = this.props;
                        errorToast(`${t('toast-Error while getting file')}.`);
                    });
        }
    };

    componentDidMount() {
        const entityId = this.props.match.params.entityId;
        entityService.getEntityOverview(entityId).then(entity => {
            this.setState({entity});
            this.setState({files: entity.files});
        });
        const startDate = this.state.financeDetailsStartDate;
        const endDate = this.state.financeDetailsEndDate;
        this.getEntityFinanceSummary(startDate, endDate);
    }

    handleChange = (event) => {
        if (["financeDetailsStartDate", "financeDetailsEndDate"].includes(event.target.name)) {
            const newDateValue = new Date(event.target.value);
            newDateValue.setDate(15);

            let dateUpdated = false;
            let startDate = this.state.financeDetailsStartDate;
            let endDate = this.state.financeDetailsEndDate;

            if (event.target.name === "financeDetailsStartDate") {
                dateUpdated = startDate.getFullYear() !== newDateValue.getFullYear() || startDate.getMonth() !== newDateValue.getMonth();
                startDate = newDateValue;
                this.setState({financeDetailsStartDate: startDate});
            } else {
                dateUpdated = endDate.getFullYear() !== newDateValue.getFullYear() || endDate.getMonth() !== newDateValue.getMonth();
                endDate = newDateValue;
                this.setState({financeDetailsEndDate: endDate});
            }
            if (!areDatesInSameMonth(endDate, startDate) && endDate < startDate) {
                startDate = newDateValue;
                endDate = newDateValue;
                this.setState({financeDetailsStartDate: startDate});
                this.setState({financeDetailsEndDate: endDate});
            }
            if (dateUpdated && startDate <= endDate) {
                this.getEntityFinanceSummary(startDate, endDate)
            }
        }
    };

    getEntityFinanceSummary(startDate, endDate) {
        entityService.getEntityFinanceSummary(this.props.match.params.entityId, startDate, endDate).then(response => {
            const financeSummary = this.getFinanceSummary(response);
            const financeDetails = this.getFinanceDetails(response.properties);

            financeDetails.sort(this.compareAddressAlphabeticOrder);

            this.setState({expenses: response.expenses});
            this.setState({financeSummary});
            this.setState({financeDetails});
        });
    }

    getFinanceSummary(response) {
        let properties = response.properties;
        let financeSummary = {};
        financeSummary.activeContracts = properties.reduce((accumulator, currentValue) => accumulator + (currentValue.contractId && 1), 0);

        financeSummary.countContractibleProperties = new Set(properties.map(function (item) {
            return item['propertyId'];
        })).size;
        financeSummary.contactsAmount = properties.reduce((accumulator, currentValue) => accumulator + currentValue.contractTotalAmount, 0);
        financeSummary.rentsAmountToPayPeriod = properties.reduce((accumulator, currentValue) => accumulator + currentValue.rentsAmountToPayPeriod, 0);
        financeSummary.rentsAmountPaidPeriod = properties.reduce((accumulator, currentValue) => accumulator + currentValue.rentsAmountPaidPeriod, 0);
        financeSummary.rentsAmountToRetainPeriod = properties.reduce((accumulator, currentValue) => accumulator + currentValue.rentsAmountToRetainPeriod, 0);

        financeSummary.rentsAmountRetainedPeriod = properties.reduce((accumulator, currentValue) => accumulator + currentValue.rentsAmountRetainedPeriod, 0);


        financeSummary.expenseAmountPeriod =
            properties.reduce((accumulator, property) => accumulator + property.propertyExpenseAmountPeriod, 0)
            + response.expenses
                .filter(expense => isDateBetweenMonths(expense.date, this.state.financeDetailsStartDate, this.state.financeDetailsEndDate))
                .reduce((accumulator, expense) => accumulator + expense.amount, 0);

        financeSummary.missingRents = properties.reduce((accumulator, currentValue) => accumulator + (currentValue.contractId && !areDatesInSameMonth(currentValue.lastRentDueDate, this.state.financeDetailsEndDate) && 1), 0);
        financeSummary.contractDebt = properties.reduce((accumulator, currentValue) => accumulator + (currentValue.contractDebt > 0 ? currentValue.contractDebt : 0), 0);
        const currencyObj = properties.find(element => element.currency != null);
        financeSummary.currency = currencyObj ? currencyObj.currency : null;

        return financeSummary;
    }

    getFinanceDetails(response) {
        let grouped = [];
        response.forEach(function (row) {
            const address = {
                street: row.addressStreet,
                number: row.addressNumber,
                postalCode: row.addressPostalCode,
                city: {
                    name: row.addressCity,
                    country: row.addressCountry
                }
            }
            let addressInfo = getStringAddressStreetWithNumber(address);
            this[addressInfo] || grouped.push(this[addressInfo] = []);
            this[addressInfo].push(row);

            row.id = row.propertyId + "-" + row.contractId;
            row.stringAddressStreetWithNumber = addressInfo;
            row.contractAmountInfo = {value: row.contractTotalAmount, currency: row.currency};
            row.rentsPaymentPeriodInfo = {
                toPay: row.rentsAmountToPayPeriod,
                paid: row.rentsAmountPaidPeriod,
                retained: row.rentsAmountToRetainPeriod > 0 ? row.rentsAmountRetainedPeriod : null,
                currency: row.currency
            };
            row.rentsPaymentRetainedPeriodInfo = {toPay: row.rentsAmountToRetainPeriod, paid: row.rentsAmountRetainedPeriod, currency: row.currency};

            row.contractDebtInfo = {toPay: row.contractDebt, currency: row.currency};
            row.propertyExpenseAmountPeriodInfo = {value: row.propertyExpenseAmountPeriod, currency: row.currency};
        }, Object.create(null));
        return grouped;
    }

    compareAddressAlphabeticOrder(a, b) {
        const addressA = (a[0].addressCountry + a[0].addressCity + a[0].stringAddressStreetWithNumber).toUpperCase();
        const addressB = (b[0].addressCountry + b[0].addressCity + b[0].stringAddressStreetWithNumber).toUpperCase();

        if (addressA > addressB) {
            return 1;
        } else if (addressA < addressB) {
            return -1;
        }
        return 0;
    }


    render() {
        const entityId = this.props.match.params.entityId;
        entityService.setCurrentEntity(entityId);

        const oneMonthSummary = areDatesInSameMonth(this.state.financeDetailsEndDate, this.state.financeDetailsStartDate);

        const entity = this.state.entity;
        if (entity === null) {
            return <div>Loading...</div>;
        }

        const {t} = this.props;

        const financeSummaryTable = [
            {
                dataField: 'contactsAmount',
                text: `${t('overview-Contracts')}`,
                headerAlign: "center",
                align: 'center',
                formatter: (cell, row) => (
                    <div>
                        <Money number={cell} currency={row.currency}/>
                        {row.activeContracts > 0 && <b style={{color: "DarkGray"}}> ({row.activeContracts})</b>}
                        <hr className={"divider"}/>
                        <small>({row.countContractibleProperties.toLocaleString()} {t('overview-Properties')})</small>
                    </div>
                )
            },
            {
                dataField: 'rentsAmountPaidPeriod',
                text: `${oneMonthSummary ? `${t(dateService.getMonthName(this.state.financeDetailsStartDate))}` : ''} ${t('overview-Payment')}`,
                headerAlign: "center",
                align: 'center',
                formatter: (cell, row, rowIndex) => (
                    <>
                        <b className={cell >= row.rentsAmountToPayPeriod ? "good" : "danger"}><Money number={cell} currency={row.currency}/></b>
                        {
                            cell !== row.rentsAmountToPayPeriod &&
                            <>
                                <hr className={"divider"}/>
                                <b><Money number={row.rentsAmountToPayPeriod} currency={row.currency}/></b>
                            </>
                        }
                    </>
                )
            },
            {
                dataField: 'rentsAmountRetainedPeriod',
                text: `${oneMonthSummary ? `${t(dateService.getMonthName(this.state.financeDetailsStartDate))}` : ''} ${t('overview-Retained Amount')}`,
                headerAlign: "center",
                align: 'center',
                formatter: (cell, row, rowIndex) => (
                    <>
                        <b className={cell >= row.rentsAmountToRetainPeriod ? "good" : "danger"}><Money number={cell} currency={row.currency}/></b>
                        {
                            cell !== row.rentsAmountToRetainPeriod &&
                            <>
                                <hr className={"divider"}/>
                                <b><Money number={row.rentsAmountToRetainPeriod} currency={row.currency}/></b>
                            </>
                        }
                    </>
                )
            },
            {
                dataField: 'expenseAmountPeriod',
                text: `${oneMonthSummary ? `${t(dateService.getMonthName(this.state.financeDetailsStartDate))}` : ''} ${t('overview-Expenses')}`,
                headerAlign: "center",
                align: 'center',
                formatter: (cell, row, rowIndex) => (
                    <div>
                        <Money number={cell} currency={row.currency}/>
                    </div>
                )
            },
            {
                dataField: 'contractDebt',
                text: `${t('overview-Total Debt')}`,
                headerAlign: "center",
                align: 'center',
                formatter:
                    (cell, row, rowIndex) => (
                        cell > 0
                            ? <b className='danger'><Money currency={row.currency} number={cell}/></b>
                            : <div className='good'> - </div>
                    )
            },
            {
                dataField: 'missingRents',
                text: `# ${t('overview-Action Required')}`,
                headerAlign: "center",
                align: 'center',
                formatter:
                    (cell, row, rowIndex) => (
                        cell > 0
                            ? <b className='danger'>{cell}</b>
                            : <div className='good'> - </div>

                    ),
                headerStyle:
                    (colum, colIndex) => {
                        return {width: '20%'};
                    }
            }
        ];

        if (!oneMonthSummary) {
            // Hide column # Action Required
            financeSummaryTable.splice(5, 1);
        }

        if (this.state.financeSummary.rentsAmountToRetainPeriod === 0) {
            // Hide column Retained Amount
            financeSummaryTable.splice(2, 1);
        }

        const fileChangeHandler = (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                if (selectedFile.size > 10000000) {
                    errorToast(`${t('toast-File cannot have more than 10 MB')}.`);
                } else {
                    const formData = new FormData();
                    formData.append('file', selectedFile);
                    infoToast(`${t('toast-Uploading')}.`);
                    entityService.uploadFile(entityId, formData)
                        .then(response => {
                                successToast(`${t('toast-File uploaded successfully')}.`);
                                if (response && response.length > 0) {
                                    this.setState({files: response});
                                }
                                this.setState({view: FILES});
                            },
                            error => {
                                console.log(error);
                                errorToast(`${t('toast-Error while uploading file')}.`);
                            });
                }
            }
        };

        const triggerUploadButtonClicked = () => {
            // @ts-ignore
            document.querySelector("input[type='file']").click();
        };

        return (
            <div className="container up">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 offset-md-3 text-center">

                        <div className="section-title">
                            <h4>{entity.name}</h4>
                        </div>
                        {
                            permissionService.hasEditAccess(entityId) &&
                            <Link to={`/entities/${entityId}/edit`} style={{marginLeft: "10px", marginRight: "10px"}}>
                                <MDBIcon icon="pen" size="1px"/>
                            </Link>
                        }
                        {
                            permissionService.hasDeleteAccess(entityId) &&
                            <ConfirmDeleteEntity history={this.props.history} ids={this.props.match.params}/>
                        }
                    </div>
                </div>

                <br/>

                <div className="card card-cascade narrower">
                    <div className="row view view-cascade py-3 gradient-card-header info-color-dark d-flex justify-content-between align-items-center"
                         style={{borderRadius: "5px"}}>
                        <div className="col-md-4">
                            <span className={this.state.view === FINANCES && "selected"} style={{display: "inline-block"}} data-tip data-for="financesTip">
                                <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                        onClick={() => this.setState({view: FINANCES})}>
                                    <MDBIcon icon="chart-bar" size="2x"/>
                                </button>
                                <ReactTooltip id="financesTip" place="top" effect="solid">
                                   {t('overview-Finances')}
                                </ReactTooltip>
                            </span>
                            <span className={this.state.view === PERMISSIONS && "selected"} style={{display: "inline-block"}} data-tip data-for="permissionsTip">
                                <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                        onClick={() => this.setState({view: PERMISSIONS})}>
                                    <MDBIcon icon="users" size="2x"/>
                                </button>
                                <ReactTooltip id="permissionsTip" place="top" effect="solid">
                                   {t('overview-Permissions')}
                                </ReactTooltip>
                            </span>
                            <span className={this.state.view === EXPENSES && "selected"} style={{display: "inline-block"}} data-tip data-for="expensesTip">
                                <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                        onClick={() => this.setState({view: EXPENSES})}>
                                    <MDBIcon icon="dollar-sign" size="2x"/>
                                </button>
                                <ReactTooltip id="expensesTip" place="top" effect="solid">
                                   {t('overview-Entity Expenses')}
                                </ReactTooltip>
                            </span>
                            <span className={this.state.view === FILES && "selected"} style={{display: "inline-block"}} data-tip data-for="filesTip">
                                <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                        onClick={() => this.setState({view: FILES})}>
                                    <MDBIcon icon="copy" size="2x"/>
                                </button>
                                <ReactTooltip id="filesTip" place="top" effect="solid">
                                   {t('overview-Entity Files')}
                                </ReactTooltip>
                            </span>
                        </div>
                        <div className="col-md-4 text-center">
                            <h5 className="white-text mx-3">
                                <strong>
                                    {
                                        this.state.view === FINANCES && <>{t('overview-Finances')}</>
                                    }
                                    {
                                        this.state.view === PERMISSIONS && <>{t('overview-Permissions')}</>
                                    }
                                    {
                                        this.state.view === EXPENSES && <>{t('overview-Expenses')}</>
                                    }
                                    {
                                        this.state.view === FILES && <>{t('Files')}</>
                                    }
                                </strong>
                            </h5>
                        </div>
                        <div className="col-md-4 text-right">
                            {
                                permissionService.hasCreateAccess(entityId) &&
                                <>
                                    <>
                                        <input type="file" name="file" onChange={fileChangeHandler} hidden={true}/>
                                        <button onClick={triggerUploadButtonClicked} type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                data-tip data-for="uploadFileTip">
                                            <MDBIcon icon="upload" size="2x"/>
                                        </button>
                                        <ReactTooltip id="uploadFileTip" place="top" effect="solid">
                                            {t('Upload file')}
                                        </ReactTooltip>
                                    </>
                                    <Link to={`/entities/${entityId}/property/new`}>
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="cretePropertyTip">
                                            <MDBIcon icon="plus"/> <MDBIcon icon="home" size="2x"/>
                                        </button>
                                        <ReactTooltip id="cretePropertyTip" place="top" effect="solid">
                                            {t('Create a new Property')}
                                        </ReactTooltip>
                                    </Link>

                                    <Link to={`/entities/${entityId}/contract/new`}>
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="creteContractTip">
                                            <MDBIcon icon="plus"/> <MDBIcon icon="file-contract" size="2x"/>
                                        </button>
                                        <ReactTooltip id="creteContractTip" place="top" effect="solid">
                                            {t('Create a new Contract')}
                                        </ReactTooltip>
                                    </Link>

                                    <Link to={`/entities/${entityId}/expense/new`}>
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="creteExpenseTip">
                                            <MDBIcon icon="plus"/> <MDBIcon icon="dollar-sign" size="2x"/>
                                        </button>
                                        <ReactTooltip id="creteExpenseTip" place="top" effect="solid">
                                            {t('Create a new Expense')}
                                        </ReactTooltip>
                                    </Link>
                                </>
                            }
                        </div>
                    </div>

                    <div className="card-body">
                        {
                            this.state.view === PERMISSIONS
                            && <PermissionsSection ids={this.props.match.params} history={this.props.history}/>
                        }
                        {
                            this.state.view === EXPENSES
                            && <EntityExpenses entityId={entityId} history={this.props.history} expenses={this.state.expenses}/>
                        }
                        {
                            this.state.view === FINANCES
                            && <>
                                <div>
                                    <div className="row">
                                        <div className="col-md-6 text-center">
                                            <b>{t('overview-From')}</b>
                                            <DatePicker
                                                className="ml-5"
                                                format={'MM/yyyy'}
                                                calendar={YearMonthCalendar}
                                                onChange={this.handleChange}
                                                name="financeDetailsStartDate"
                                                value={this.state.financeDetailsStartDate}
                                                width="150px"
                                            />
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <b>{t('overview-To')}</b>
                                            <DatePicker
                                                className="ml-5"
                                                format={'MM/yyyy'}
                                                calendar={YearMonthCalendar}
                                                onChange={this.handleChange}
                                                name="financeDetailsEndDate"
                                                value={this.state.financeDetailsEndDate}
                                                width="150px"
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    {
                                        !areDatesInSameMonth(this.state.financeDetailsEndDate, this.state.financeDetailsStartDate)
                                        && this.state.financeDetailsEndDate < this.state.financeDetailsStartDate &&
                                        <div className="alert alert-danger mt-3">
                                            <div><b>Error: </b>"From" date ({getMonthYearOfDate(this.state.financeDetailsStartDate).replace(/\s/g, '')}) cannot be after "To" date
                                                ({getMonthYearOfDate(this.state.financeDetailsEndDate).replace(/\s/g, '')})
                                            </div>
                                        </div>
                                    }
                                </div>
                                <BootstrapTable
                                    keyField='activeContracts'
                                    data={[this.state.financeSummary]}
                                    columns={financeSummaryTable}
                                    bordered={false}/>
                            </>
                        }
                        {
                            this.state.view === FILES
                            && <FilesTable files={this.state.files} rowEvents={this.filesRowEvents}/>
                        }
                    </div>
                </div>

                <FinanceDetails
                    oneMonthSummary={oneMonthSummary}
                    financeDetails={this.state.financeDetails}
                    financeDetailsStartDate={this.state.financeDetailsStartDate}
                    financeDetailsEndDate={this.state.financeDetailsEndDate}
                    entityId={this.props.match.params.entityId}
                    history={this.props.history}
                />
            </div>
        );
    }
}

export default withTranslation()(Entity);