import React from 'react';
import {entityService, propertyService} from "../../../_services";
import PropertyForm from "../_aux/PropertyForm";
import {coalesceObject, coalesceString} from "../../../_helpers/util-functions";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

export const EDIT_PROPERTY = "EditProperty";

class EditProperty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                isSubProperty: false,
                name: '',
                code: '',
                description: '',
                areaMeters: '',
                value: '',

                hasHeating: undefined,
                hasAirCondition: undefined,
                isFurnished: undefined,
                numBathrooms: '',
                numToilets: '',
                kitchenAreaMeters: '',
                outsideAreaMeters: '',
                numBalconies: '',
                numBedrooms: '',
                numSuits: '',
                hasLaundry: undefined,
                hasStorageRoom: undefined,
                storageRoomAreaMeters: '',
                hasLivingRoom: undefined,
                livingRoomAreaMeters: '',
                hasDiningRoom: undefined,
                diningRoomAreaMeters: '',
                numParkingInside: '',
                numParkingOutside: '',

                locationCode: '',
                articleCode: '',
                fractionCode: ''
            },
            address: null
        };
    }

    componentDidMount() {
        propertyService.getProperty(this.props.match.params.entityId, this.props.match.params.propertyId).then(response => {
            let initialValues = {};
            this.setBaseInformationFields(response, initialValues);
            this.setPropertyDetailsFields(response, initialValues);
            this.setPropertyTaxInformationFields(response, initialValues);

            this.setState({initialValues: initialValues});
            this.setState({parentPropertyId: response.parentPropertyId});
            initialValues.isSubProperty = response.parentPropertyId != null;
        });
    }

    setBaseInformationFields(response, initialValues) {
        this.setState({propertyTypeId: response.baseInfo.propertyTypeId});
        this.setState({currencyId: response.baseInfo.currencyId});

        initialValues.name = coalesceString(response.baseInfo.name);
        initialValues.code = coalesceString(response.baseInfo.code);
        initialValues.description = coalesceString(response.baseInfo.description);
        initialValues.areaMeters = coalesceString(response.baseInfo.areaMeters);
        initialValues.value = coalesceString(response.baseInfo.value);

        this.setAddressInformationFields(response.baseInfo.address, initialValues);
    }

    setAddressInformationFields(address, initialValues) {
        this.setState({address: address});
        initialValues.street = coalesceString(address.street);
        initialValues.number = coalesceString(address.number);
        initialValues.floor = coalesceString(address.floor);
        initialValues.mailBox = coalesceString(address.mailBox);
        initialValues.postalCode = coalesceString(address.postalCode);
        initialValues.latitude = coalesceString(address.latitude);
        initialValues.longitude = coalesceString(address.longitude);
        initialValues.cityId = coalesceString(address.city.id);
    }

    setPropertyDetailsFields(response, initialValues) {
        if (response.details) {
            this.setState({kitchenTypeId: response.details.kitchenTypeId});
            this.setState({outsideAreaTypeId: response.details.outsideAreaTypeId});
            initialValues.hasHeating = coalesceObject(response.details.heating);
            initialValues.hasAirCondition = coalesceObject(response.details.airCondition);
            initialValues.isFurnished = coalesceObject(response.details.furnished);
            initialValues.numBathrooms = coalesceString(response.details.numBathrooms);
            initialValues.numToilets = coalesceString(response.details.numToilets);
            initialValues.kitchenAreaMeters = coalesceString(response.details.kitchenAreaMeters);
            initialValues.outsideAreaMeters = coalesceString(response.details.outsideAreaMeters);
            initialValues.numBalconies = coalesceString(response.details.numBalconies);
            initialValues.numBedrooms = coalesceString(response.details.numBedrooms);
            initialValues.numSuits = coalesceString(response.details.numSuits);
            initialValues.hasLaundry = coalesceObject(response.details.laundry);
            initialValues.hasStorageRoom = coalesceObject(response.details.storageRoom);
            initialValues.storageRoomAreaMeters = coalesceString(response.details.storageRoomAreaMeters);
            initialValues.hasLivingRoom = coalesceObject(response.details.livingRoom);
            initialValues.livingRoomAreaMeters = coalesceString(response.details.livingRoomAreaMeters);
            initialValues.hasDiningRoom = coalesceObject(response.details.diningRoom);
            initialValues.diningRoomAreaMeters = coalesceString(response.details.diningRoomAreaMeters);
            initialValues.numParkingInside = coalesceString(response.details.numParkingInside);
            initialValues.numParkingOutside = coalesceString(response.details.numParkingOutside);
        }
    }

    setPropertyTaxInformationFields(response, initialValues) {
        if (response.taxInformation) {
            this.setState({propertyBusinessTypeId: response.taxInformation.propertyBusinessTypeId});
            this.setState({propertyLocationTypeId: response.taxInformation.propertyLocationTypeId});
            initialValues.locationCode = coalesceString(response.taxInformation.locationCode);
            initialValues.articleCode = coalesceString(response.taxInformation.articleCode);
            initialValues.fractionCode = coalesceString(response.taxInformation.fractionCode);
        }
    }


    render() {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('propertyForm-Edit Property')}</h4>
                        </div>
                    </div>
                </div>
                <PropertyForm
                    entityId={entityId}
                    history={this.props.history}
                    initialValues={this.state.initialValues}
                    address={this.state.address}
                    parentPropertyId={this.state.parentPropertyId}
                    propertyTypeId={this.state.propertyTypeId}
                    currencyId={this.state.currencyId}
                    businessTypeId={this.state.businessTypeId}
                    locationTypeId={this.state.locationTypeId}
                    kitchenTypeId={this.state.kitchenTypeId}
                    outsideAreaTypeId={this.state.outsideAreaTypeId}
                    propertyBusinessTypeId={this.state.propertyBusinessTypeId}
                    propertyLocationTypeId={this.state.propertyLocationTypeId}
                    propertyId={this.props.match.params.propertyId}
                    page={EDIT_PROPERTY}
                />
            </div>
        );
    }
}

export default withTranslation()(EditProperty);
