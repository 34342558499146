import {handleResponse} from "../_helpers/handle-response";
import {authHeader, jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {addAttributeIfExists, getNumberCleanValue, getPrimitiveCleanValue, getUtcDateCleanValue} from "../_helpers/util-functions";


export const expenseService = {
    getAllOfProperty,
    getExpense,
    createExpense,
    updateExpense,
    deleteExpense,
    uploadFile,
    getFileUrl
};

function getAllOfProperty(entityId, propertyId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/expenses?propertyId=${propertyId}`, requestOptions).then(handleResponse);
}

function getExpense(entityId, expenseId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/expenses/${expenseId}`, requestOptions).then(handleResponse);
}

function createExpense(entityId, propertyId, currencyId, expenseDate, amount, name, description, includeInYield) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanExpenseAttributes(propertyId, currencyId, expenseDate, amount, name, description, includeInYield))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/expenses`, requestOptions).then(handleResponse);
}

function updateExpense(entityId, propertyId, expenseId, currencyId, expenseDate, amount, name, description, includeInYield) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanExpenseAttributes(propertyId, currencyId, expenseDate, amount, name, description, includeInYield))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/expenses/${expenseId}`, requestOptions).then(handleResponse);
}

function cleanExpenseAttributes(propertyId, currencyId, expenseDate, amount, name, description, includeInYield) {
    let attributes = {propertyId, currencyId};

    addAttributeIfExists(attributes, "date", getUtcDateCleanValue(expenseDate));
    addAttributeIfExists(attributes, "amount", getNumberCleanValue(amount));
    attributes["name"] = getPrimitiveCleanValue(name);
    attributes["description"] = getPrimitiveCleanValue(description);
    attributes["includeInYield"] = includeInYield;
    return attributes;
}

function deleteExpense(entityId, expenseId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/expenses/${expenseId}`, requestOptions).then(handleResponse);
}

function uploadFile(entityId, expenseId, formData) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken()},
        body: formData
    };
    return fetch(SERVER_URL + `/entities/${entityId}/expenses/${expenseId}/files`, requestOptions).then(handleResponse);
}

function getFileUrl(entityId, expenseId, fileId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/expenses/${expenseId}/files/${fileId}`, requestOptions).then(handleResponse);
}
