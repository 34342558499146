import React from 'react';
import PropertyForm from "../_aux/PropertyForm";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

export const CREATE_PROPERTY = "CreateProperty";

class CreateProperty extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const initialValues = {
            isSubProperty: false,
            name: '',
            code: '',
            description: '',
            areaMeters: '',
            value: '',

            hasHeating: undefined,
            hasAirCondition: undefined,
            isFurnished: undefined,
            numBathrooms: '',
            numToilets: '',
            kitchenAreaMeters: '',
            outsideAreaMeters: '',
            numBalconies: '',
            numBedrooms: '',
            numSuits: '',
            hasLaundry: undefined,
            hasStorageRoom: undefined,
            storageRoomAreaMeters: '',
            hasLivingRoom: undefined,
            livingRoomAreaMeters: '',
            hasDiningRoom: undefined,
            diningRoomAreaMeters: '',
            numParkingInside: '',
            numParkingOutside: '',

            locationCode: '',
            articleCode: '',
            fractionCode: '',

            street: '',
            number: '',
            floor: '',
            mailBox: '',
            postalCode: '',
            latitude: '',
            longitude: ''
        };
        const entityId = this.props.match.params.entityId;
        const {t} = this.props;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('propertyForm-New Property')}</h4>
                        </div>
                    </div>
                </div>
                <PropertyForm
                    entityId={entityId}
                    history={this.props.history}
                    initialValues={initialValues}
                    page={CREATE_PROPERTY}
                    location={this.props.location}/>
            </div>
        );
    }
}

export default withTranslation()(CreateProperty);