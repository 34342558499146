import React from 'react';
import {propertyService} from "../../../_services";
import {MDBIcon} from "mdbreact";
import './ListProperties.css';
import PropertiesTable from "../_aux/PropertiesTable";
import {permissionService} from "../../../_services/permission.service";
import ReactTooltip from "react-tooltip";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";

class ListProperties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: PARENT_PROPERTIES,
            properties: [],
            propertiesView: [],
            open: false,
            propertyTypes: new Set(),
            propertyTypeFilter: null,
            searching: false,
            countContractibleProperties: null,
            countActiveContracts: null
        };
    }

    componentDidMount() {
        const queryFilters = require('query-string').parse(this.props.location.search);
        this.setState({searching: Object.keys(queryFilters).length > 0});

        const entityId = this.props.match.params.entityId;
        propertyService.getAll(entityId, null).then(response => {
            response.forEach(p => p.entityId = entityId);
            this.setState({properties: response});
            this.setState({propertyTypes: new Set(response.map(property => property.propertyType.id))});
            this.setState({propertiesView: this.state.properties.filter(p => p.parentPropertyId == null)});
            this.setState({countContractibleProperties: response.reduce((acc, prop) => acc + prop.countContractibleProperties, 0)});
            this.setState({countActiveContracts: response.reduce((acc, prop) => acc + prop.countActiveContracts, 0)});
        });
    }

    render() {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;
        return (
            <div>
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('properties-Entity Properties')}</h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div>
                    <div className="card card-cascade narrower">
                        <div className="row view view-cascade py-3 gradient-card-header info-color-dark d-flex justify-content-between align-items-center" style={{borderRadius: "5px"}}>
                            <div className="col-md-8">
                                <span className={this.state.view === PARENT_PROPERTIES && "selected"} style={{display: "inline-block"}} data-tip data-for="parentPropertiesTip">
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            onClick={this.changeProperties(PARENT_PROPERTIES)}>
                                        <MDBIcon icon="sitemap" size="2x"/>
                                    </button>
                                    <ReactTooltip id="parentPropertiesTip" place="top" effect="solid">
                                            {t('properties-Show Parent Properties')}
                                    </ReactTooltip>
                                </span>
                                {
                                    this.state.propertyTypes.has(this.getPropertyTypeId(HOUSES)) &&
                                    <span className={this.state.view === HOUSES && "selected"} style={{display: "inline-block"}} data-tip data-for="housesTip">
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                onClick={this.changeProperties(HOUSES)}>
                                            <MDBIcon icon="home" size="2x"/>
                                        </button>
                                        <ReactTooltip id="housesTip" place="top" effect="solid">
                                                {t('properties-HOUSES')}
                                        </ReactTooltip>
                                    </span>
                                }
                                {
                                    this.state.propertyTypes.has(this.getPropertyTypeId(BUILDINGS)) &&
                                    <span className={this.state.view === BUILDINGS && "selected"} style={{display: "inline-block"}} data-tip data-for="buildingsTip">
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                onClick={this.changeProperties(BUILDINGS)}>
                                            <MDBIcon icon="building" size="2x"/>
                                        </button>
                                        <ReactTooltip id="buildingsTip" place="top" effect="solid">
                                                {t('properties-BUILDINGS')}
                                        </ReactTooltip>
                                    </span>
                                }
                                {
                                    this.state.propertyTypes.has(this.getPropertyTypeId(APARTMENTS)) &&
                                    <span className={this.state.view === APARTMENTS && "selected"} style={{display: "inline-block"}} data-tip data-for="apartmentsTip">
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                onClick={this.changeProperties(APARTMENTS)}>
                                            <MDBIcon icon="couch" size="2x"/>
                                        </button>
                                        <ReactTooltip id="apartmentsTip" place="top" effect="solid">
                                                {t('properties-APARTMENTS')}
                                        </ReactTooltip>
                                    </span>
                                }
                                {
                                    this.state.propertyTypes.has(this.getPropertyTypeId(BEDROOMS)) &&
                                    <span className={this.state.view === BEDROOMS && "selected"} style={{display: "inline-block"}} data-tip data-for="bedroomsTip">
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                onClick={this.changeProperties(BEDROOMS)}>
                                            <MDBIcon icon="bed" size="2x"/>
                                        </button>
                                        <ReactTooltip id="bedroomsTip" place="top" effect="solid">
                                                {t('properties-BEDROOMS')}
                                        </ReactTooltip>
                                    </span>
                                }
                                {
                                    this.state.propertyTypes.has(this.getPropertyTypeId(GARAGES)) &&
                                    <span className={this.state.view === GARAGES && "selected"} style={{display: "inline-block"}} data-tip data-for="garagesTip">
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                onClick={this.changeProperties(GARAGES)}>
                                            <MDBIcon icon="car-side" size="2x"/>
                                        </button>
                                        <ReactTooltip id="garagesTip" place="top" effect="solid">
                                                {t('properties-GARAGES')}
                                        </ReactTooltip>
                                    </span>
                                }
                                {
                                    this.state.propertyTypes.has(this.getPropertyTypeId(WAREHOUSES)) &&
                                    <span className={this.state.view === WAREHOUSES && "selected"} style={{display: "inline-block"}} data-tip data-for="warehousesTip">
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                onClick={this.changeProperties(WAREHOUSES)}>
                                            <MDBIcon icon="warehouse" size="2x"/>
                                        </button>
                                        <ReactTooltip id="warehousesTip" place="top" effect="solid">
                                                {t('properties-WAREHOUSES')}
                                        </ReactTooltip>
                                    </span>
                                }
                                {
                                    this.state.propertyTypes.has(this.getPropertyTypeId(LANDS)) &&
                                    <span className={this.state.view === LANDS && "selected"} style={{display: "inline-block"}} data-tip data-for="landsTip">
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                onClick={this.changeProperties(LANDS)}>
                                            <MDBIcon icon="tree" size="2x"/>
                                        </button>
                                        <ReactTooltip id="landsTip" place="top" effect="solid">
                                                {t('properties-LANDS')}
                                        </ReactTooltip>
                                    </span>
                                }
                                {
                                    this.state.propertyTypes.has(this.getPropertyTypeId(OTHER)) &&
                                    <span className={this.state.view === OTHER && "selected"} style={{display: "inline-block"}} data-tip data-for="otherTip">
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                                onClick={this.changeProperties(OTHER)}>
                                            <MDBIcon icon="question" size="2x"/>
                                        </button>
                                        <ReactTooltip id="otherTip" place="top" effect="solid">
                                                {t('properties-OTHER')}
                                        </ReactTooltip>
                                    </span>
                                }
                                <span className={this.state.view === TAXES && "selected"} style={{display: "inline-block"}} data-tip data-for="taxesTip">
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            onClick={this.changeProperties(TAXES)}>
                                        <MDBIcon icon="percent" size="2x"/>
                                    </button>
                                    <ReactTooltip id="taxesTip" place="top" effect="solid">
                                        {t('properties-Show Taxes Properties')}
                                    </ReactTooltip>
                                </span>
                            </div>

                            <div className="col-md-3">
                                <h5 className="white-text mx-3">
                                    <strong>{t(`properties-${this.state.view}`)}</strong>
                                </h5>
                            </div>

                            <div className="col-md-1 text-right">
                                {
                                    permissionService.hasCreateAccess(entityId) &&
                                    <>
                                        <Link to={`/entities/${entityId}/property/new`}>
                                            <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="cretePropertyTip">
                                                <MDBIcon icon="plus"/> <MDBIcon icon="home" size="2x"/>
                                            </button>
                                            <ReactTooltip id="cretePropertyTip" place="top" effect="solid">
                                                {t('Create a new Property')}
                                            </ReactTooltip>
                                        </Link>
                                    </>
                                }
                            </div>
                        </div>

                        <PropertiesTable
                            properties={this.state.propertiesView}
                            entityId={entityId}
                            hideType={this.state.view !== PARENT_PROPERTIES && this.state.view !== TAXES}
                            showTaxCode={this.state.view === TAXES}
                            history={this.props.history}/>

                    </div>
                </div>
            </div>
        );
    }

    changeProperties(selection) {
        let propertiesView = this.state.propertiesView;
        if (selection === PARENT_PROPERTIES) {
            propertiesView = this.state.properties.filter(p => p.parentPropertyId == null);
        } else if (selection === TAXES) {
            propertiesView = this.state.properties.filter(p => p.code != null);
        } else {
            const propertyTypeId = this.getPropertyTypeId(selection);
            propertiesView = this.state.properties.filter(p => p.propertyType.id === propertyTypeId);
        }
        return () => this.setState({view: selection, propertiesView: propertiesView});
    }

    getPropertyTypeId(selection) {
        if (selection === HOUSES) {
            return 1;
        }
        if (selection === BUILDINGS) {
            return 2;
        }
        if (selection === APARTMENTS) {
            return 3;
        }
        if (selection === BEDROOMS) {
            return 4;
        }
        if (selection === GARAGES) {
            return 5;
        }
        if (selection === WAREHOUSES) {
            return 6;
        }
        if (selection === LANDS) {
            return 7;
        }
        if (selection === OTHER) {
            return 8;
        }
    }
}

export default withTranslation()(ListProperties);

const PARENT_PROPERTIES = "PARENT_PROPERTIES";
const HOUSES = "HOUSES";
const BUILDINGS = "BUILDINGS";
const APARTMENTS = "APARTMENTS";
const BEDROOMS = "BEDROOMS";
const GARAGES = "GARAGES";
const WAREHOUSES = "WAREHOUSES";
const LANDS = "LANDS";
const OTHER = "OTHER";
const TAXES = "TAXES";
