import {handleResponse} from "../_helpers/handle-response";
import {authHeader, jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {addAttributeIfExists, getNumberCleanValue, getUtcDateCleanValue} from "../_helpers/util-functions";


export const rentService = {
    getAll,
    getRent,
    createRent,
    updateRent,
    deleteRent
};

function getAll(entityId, propertyId, contractId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents`, requestOptions).then(handleResponse);
}

function getRent(entityId, propertyId, contractId, rentId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${rentId}`, requestOptions).then(handleResponse);
}

function createRent(entityId, propertyId, contractId, currencyId, paymentDueDate, paymentPeriodDate, amount, retainedAmount, taxableAmount) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanRentAttributes(currencyId, paymentDueDate, paymentPeriodDate, amount, retainedAmount, taxableAmount))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents`, requestOptions).then(handleResponse);
}

function updateRent(entityId, propertyId, contractId, rentId, currencyId, paymentDueDate, paymentPeriodDate, amount, retainedAmount, taxableAmount) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanRentAttributes(currencyId, paymentDueDate, paymentPeriodDate, amount, retainedAmount, taxableAmount))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${rentId}`, requestOptions).then(handleResponse);
}

function cleanRentAttributes(currencyId, paymentDueDate, paymentPeriodDate, amount, retainedAmount, taxableAmount) {
    let attributes = {currencyId};

    addAttributeIfExists(attributes, "paymentDueDate", getUtcDateCleanValue(paymentDueDate));
    addAttributeIfExists(attributes, "paymentPeriodDate", getUtcDateCleanValue(paymentPeriodDate));
    addAttributeIfExists(attributes, "amount", getNumberCleanValue(amount));
    addAttributeIfExists(attributes, "retainedAmount", getNumberCleanValue(retainedAmount));
    addAttributeIfExists(attributes, "taxableAmount", getNumberCleanValue(taxableAmount));
    return attributes;
}

function deleteRent(entityId, propertyId, contractId, rentId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${rentId}`, requestOptions).then(handleResponse);
}
