import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {expenseService} from "../../../../_services/expense.service";
import {errorToast, successToast} from "../../../../_helpers/toast-functions";
import {useTranslation} from "react-i18next";
import {MDBIcon} from "mdbreact";

export function ConfirmDeleteExpense(props) {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDeleteExpense = () => {
        expenseService.deleteExpense(props.entityId, props.expenseId)
            .then(
                response => {
                    successToast(`${t('toast-Expense successfully deleted')}!`);
                    if (props.propertyId) {
                        props.history.replace(`/entities/${props.entityId}/properties/${props.propertyId}/#expenses`);
                    } else {
                        props.history.replace(`/entities/${props.entityId}`);
                    }
                    window.location.reload();
                },
                error => {
                    errorToast(error.message);
                    setShow(false);
                }
            );
    };

    return (
        <>
            <a onClick={handleShow} style={{"color": "#BC0000"}}>
                <MDBIcon icon="trash" style={{color: "#B22222"}}/>
            </a>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                    Are you sure you want to delete this expense? <br/>
                    This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteExpense}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
