import React from "react";

export function CurrencyHmlCode(props) {
    const supportedCurrencies = ["Euro"];
    const isSupportedCurrency = supportedCurrencies.includes(props.currency);
    return (
        <>
            {
                !isSupportedCurrency && <>{props.currency}</>
            }
            {
                isSupportedCurrency && props.currency == supportedCurrencies[0] && <>&euro;</>
            }
        </>
    );
}
