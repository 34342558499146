import React from "react";
import {ErrorMessage, Field} from "formik";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {lookupValuesService} from "../../../../_services";
import * as Yup from "yup";
import {createEventWithName} from "../../../../_helpers/event-functions";
import {CREATE_PROPERTY} from "../../CreatePropertyPage/CreateProperty";
import {withTranslation} from "react-i18next";

class AddressForm extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            cities: [],
            city: null,
            cityPlaceholder: `${t('propertyForm-Address-City-Placeholder')}`,
            countries: [],
            country: null,
            address: null,
            parentProperty: null
        };
    }

    componentDidMount() {
        lookupValuesService.getCountries().then(response => this.setState({countries: response}));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {parentProperty: nextProps.parentProperty};

        // Set address based on parent address. Runs every time the parent changes
        if (prevState.parentProperty != nextProps.parentProperty && (nextProps.parentPropertyUpdated || nextProps.page === CREATE_PROPERTY)) {
            const parentAddress = nextProps.parentProperty ? nextProps.parentProperty.address : null;
            if (parentAddress) {
                nextProps.setFieldValue('street', parentAddress.street || '');
                nextProps.setFieldValue('number', parentAddress.number || '');
                nextProps.setFieldValue('floor', parentAddress.floor || '');
                nextProps.setFieldValue('mailBox', parentAddress.mailBox || '');
                nextProps.setFieldValue('postalCode', parentAddress.postalCode || '');
                nextProps.setFieldValue('latitude', parentAddress.latitude || '');
                nextProps.setFieldValue('longitude', parentAddress.longitude || '');
                let city = parentAddress.city;
                nextProps.setFieldValue('cityId', city.id);
                newState.country = {id: city.countryId, name: city.country};
                newState.city = {id: city.id, name: city.name};
            }
        }
        return newState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //    Todo: If I get at startup all cities of all countries, this code should move to getDerivedStateFromProps
        if (prevState.address === null && prevProps.address) {
            let city = prevProps.address.city;
            this.setState({address: prevProps.address});
            this.handleChange(createEventWithName({id: city.countryId, name: city.country}, "country"));
            this.handleChange(createEventWithName({id: city.id, name: city.name}, "city"));
        }
    }

    handleChange = (event) => {
        switch (event.target.name) {
            case "city":
                this.setState({city: event.target.value});
                this.props.setFieldValue('cityId', event.target.value ? event.target.value.id : null);
                break;
            case "country":
                this.setState({country: event.target.value});
                this.allowCitySelection(event.target.value, event);
                break;
            default:
                console.warn("unhandled change event for " + event.target.name);
        }
    };

    allowCitySelection(country) {
        const {t} = this.props;
        this.setState({city: null});
        if (country != null) {
            lookupValuesService.getCities(country.id).then(response => this.setState({cities: response}));
            this.setState({cityPlaceholder: ""});
        } else {
            this.setState({cities: []});
            this.setState({cityPlaceholder: `${t('propertyForm-Address-City-Placeholder')}`});
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div className="box" style={{"padding": "20px", "marginTop": "1rem", "marginBottom": "1rem"}}>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h6>{t('propertyForm-Address')}</h6>
                        </div>
                    </div>
                </div>
                <div className="form-group required">
                    <label htmlFor="street" className="control-label">{t('propertyForm-Address-Street')}</label>
                    <Field name="street" type="text"
                           className={'form-control' + (this.props.errors.street && this.props.touched.street ? ' is-invalid' : '')}/>
                    <ErrorMessage name="street" component="div" className="invalid-feedback"/>
                    {
                        this.props.status && this.props.status.street &&
                        <div className="text-danger invalid-input">
                            {t('propertyForm-Address-Street')} {this.props.status.street}
                        </div>
                    }
                </div>
                {
                    (this.state.parentProperty !== null) && (this.state.parentProperty.address.street !== this.props.values.street) &&
                    <div className={'alert alert-warning'}>
                        <b>{t('Warning')}: </b> {t('propertyForm-Address-Property has a different street than its parent address')}
                    </div>
                }
                <div className="row">
                    <div className="form-group required col-md-4">
                        <label htmlFor="number" className="control-label">{t('propertyForm-Address-Number')}</label>
                        <Field name="number" type="text"
                               className={'form-control' + (this.props.errors.number && this.props.touched.number ? ' is-invalid' : '')}/>
                        <ErrorMessage name="number" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.number &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Address-Number')} {this.props.status.number}
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="floor">{t('propertyForm-Address-Floor')}</label>
                        <Field name="floor" type="text"
                               className={'form-control' + (this.props.errors.floor && this.props.touched.floor ? ' is-invalid' : '')}/>
                        <ErrorMessage name="floor" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.floor &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Address-Floor')} {this.props.status.floor}
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="mailBox">{t('propertyForm-Address-Mail Box')}</label>
                        <Field name="mailBox" type="text"
                               className={'form-control' + (this.props.errors.mailBox && this.props.touched.mailBox ? ' is-invalid' : '')}/>
                        <ErrorMessage name="mailBox" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.mailBox &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Address-Mail Box')} {this.props.status.mailBox}
                            </div>
                        }
                    </div>
                </div>
                {
                    this.propertyHasSameAddressAsParent() && this.props.propertyType && this.props.propertyType.id !== 4 &&
                    <div className={'alert alert-warning'}>
                        <b>{t('Warning')}: </b> {t('propertyForm-Address-Property has the exact same address of parent property')}
                    </div>
                }
                {
                    (!this.propertyHasSameAddressAsParent()) && this.props.propertyType && this.props.propertyType.id === 4 &&
                    <div className={'alert alert-warning'}>
                        <b>{t('Warning')}: </b> {t('propertyForm-Address-Property has a different address than its parent property')}
                    </div>
                }
                <div className="row">
                    <div className="form-group required col-md-4">
                        <label htmlFor="country" className="control-label">{t('propertyForm-Address-Country')}</label> <br/>
                        <ComboBox
                            data={this.state.countries}
                            textField="name"
                            dataItemKey="id"
                            value={this.state.country}
                            name="country"
                            onChange={this.handleChange}
                            style={{"width": "100%"}}
                        />
                        <ErrorMessage name="city" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.cityId &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Address-Country is required')}
                            </div>
                        }
                    </div>
                    <div className="form-group required col-md-4">
                        <label htmlFor="city" className="control-label">{t('propertyForm-Address-City')}</label> <br/>
                        <ComboBox
                            data={this.state.cities}
                            textField="name"
                            dataItemKey="id"
                            value={this.state.city}
                            name="city"
                            onChange={this.handleChange}
                            placeholder={this.state.cityPlaceholder}
                            style={{"width": "100%"}}
                        />
                        <ErrorMessage name="city" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.cityId &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Address-City is required')}
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="postalCode">{t('propertyForm-Address-Postal Code')}</label>
                        <Field name="postalCode" type="text"
                               className={'form-control' + (this.props.errors.postalCode && this.props.touched.postalCode ? ' is-invalid' : '')}/>
                        <ErrorMessage name="postalCode" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.postalCode &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Address-Postal Code')} {this.props.status.postalCode}
                            </div>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="latitude">Latitude</label>
                        <Field name="latitude" type="text"
                               className={'form-control' + (this.props.errors.latitude && this.props.touched.latitude ? ' is-invalid' : '')}/>
                        <ErrorMessage name="latitude" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.latitude &&
                            <div className="text-danger invalid-input">
                                Latitude {this.props.status.latitude}
                            </div>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="longitude">Longitude</label>
                        <Field name="longitude" type="text"
                               className={'form-control' + (this.props.errors.longitude && this.props.touched.longitude ? ' is-invalid' : '')}/>
                        <ErrorMessage name="longitude" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.longitude &&
                            <div className="text-danger invalid-input">
                                Longitude {this.props.status.longitude}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    propertyHasSameAddressAsParent() {
        return this.state.parentProperty !== null
            && (this.state.parentProperty.address.street || '') == this.props.values.street
            && (this.state.parentProperty.address.number || '') == this.props.values.number
            && (this.state.parentProperty.address.floor || '') == this.props.values.floor
            && (this.state.parentProperty.address.mailBox || '') == this.props.values.mailBox
            && (this.state.parentProperty.address.postalCode || '') == this.props.values.postalCode;
    }
}
export default withTranslation()(AddressForm);

export const addressValidationSchema = {
    street: Yup.string()
        .max(200, 'Cannot contain more than 200 characters')
        .required('Street is required'),
    number: Yup.string()
        .max(5, 'Cannot contain more than 5 characters')
        .required('Number is required'),
    floor: Yup.string()
        .max(5, 'Cannot contain more than 5 characters').nullable(),
    mailBox: Yup.string()
        .max(50, 'Cannot contain more than 50 characters').nullable(),
    postalCode: Yup.string()
        .max(30, 'Cannot contain more than 30 characters').nullable(),
    latitude: Yup.number().typeError("Invalid decimal number").nullable(),
    longitude: Yup.number().typeError("Invalid decimal number").nullable()
};
