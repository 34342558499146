import {authenticationService} from '../_services/authentication.service';
import {errorToast} from "./toast-functions";

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized response returned from api
                authenticationService.logout();
                // window.location.reload();
            }
            if ([403].indexOf(response.status) !== -1) {
                errorToast(data.message);
            }

            // const error = (data && data.message) || response.statusText;
            return Promise.reject(data);
        }
        return data;
    });
}
