import React from "react";
import {MDBIcon} from "mdbreact";
import {Card, CardBody, Collapse} from "reactstrap";
import {withTranslation} from "react-i18next";
import FilesTable from "./FilesTable";

class FilesTableCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        }
    }

    toggle() {
        return () => this.setState({isOpen: !this.state.isOpen});
    }

    render() {
        const {t} = this.props;
        return (
            <div className="card">

                <div className="card-header" role="tab" id="headingThree" style={{color: "white", backgroundColor: "rgb(29, 161, 242)"}} onClick={this.toggle()}>
                    <div id="folder" className="row">
                        <span className="mt-1 mb-0 col-md-10">
                            {t('Files')}
                        </span>
                        {
                            this.state.isOpen
                                ? <MDBIcon icon="angle-up col-md-2 text-right" size="2x"/>
                                : <MDBIcon icon="angle-down col-md-2 text-right" size="2x"/>
                        }
                    </div>
                </div>

                <Collapse isOpen={this.state.isOpen}>
                    <Card>
                        <CardBody>
                            <FilesTable
                                files={this.props.files}
                                rowEvents={this.props.rowEvents}
                            />
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    }
}

export default withTranslation()(FilesTableCard);

