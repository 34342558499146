import React from "react";
import './Welcome.css';
import {useTranslation} from "react-i18next";


function Welcome() {
    const {t} = useTranslation();
    return (
        <div className="background">
            <img className="background-image" src="/background-image-street.jpg" alt=""/>
            <div className="center">
                <h1 className="title">
                    {t('home-You build your empire')}
                </h1>
                <h1 className="sub-title">
                    {t('home-We manage it with you')}
                </h1>
            </div>
        </div>
    );
}

export default Welcome;
