import React, {useState} from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {errorToast, successToast} from "../../../_helpers/toast-functions";
import {MDBIcon} from "mdbreact";
import {authenticationService, contractService} from "../../../_services";
import {Input} from "@progress/kendo-react-inputs";
import {useTranslation} from "react-i18next";

export function ConfirmDeleteContract(props) {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [correctEmail, setCorrectEmail] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setCorrectEmail(false);
    }
    const handleDeleteContract = () => {
        contractService.deleteContract(props.ids.entityId, props.ids.propertyId, props.ids.contractId)
            .then(
                response => {
                    successToast(`${t('toast-Contract successfully deleted')}!`);
                    props.history.push(`/entities/${props.ids.entityId}/properties/${props.ids.propertyId}`);
                },
                error => {
                    errorToast(error.message);
                    setShow(false);
                }
            );
    };
    const emailChanged = (event) => {
        setCorrectEmail(authenticationService.currentEmailValue === event.value);
    }

    return (
        <>
            <a onClick={handleShow} style={{marginLeft: "10px"}}>
                <MDBIcon icon="trash" style={{color:"#B22222"}}/>
            </a>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                    Are you sure you want to delete this contract? <br/>
                    {
                        props.numRents > 0
                        && <>Both the <strong>contract</strong> and the <strong>{props.numRents}</strong> rents will be <strong><u>permanently deleted.</u></strong><br/></>
                    }
                    This action <strong><u>cannot be undone.</u></strong>
                    <div className="mb-3">
                        <Input
                            name="confirmPassword"
                            style={{width: '100%'}}
                            label="Type your email for confirmation"
                            minLength={1}
                            maxLength={200}
                            onChange={emailChanged}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteContract} disabled={!correctEmail}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
