import * as Yup from "yup";

export const validationSchema = {
    name: Yup.string().required('Name is required')
        .max(60, 'Cannot contain more than 60 characters'),
    email: Yup.string().nullable().email('Must be a valid email')
        .max(60, 'Cannot contain more than 60 characters'),
    phone: Yup.string()
        .max(20, 'Cannot contain more than 20 characters'),
    fiscalNumber: Yup.string().nullable()
        .max(50, 'Cannot contain more than 50 characters'),
    note: Yup.string().nullable()
        .max(255, 'Cannot contain more than 255 characters'),
    address: Yup.string().nullable()
        .max(250, 'Cannot contain more than 250 characters'),
    postalCode: Yup.string().nullable()
        .max(30, 'Cannot contain more than 30 characters')
};
