import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';
import {MDBContainer} from 'mdbreact';
import {roundTwoDecimalCases} from "../../../_helpers/util-functions";
import {getNumber} from "../../../_helpers/NumberUtils";
import {withTranslation} from "react-i18next";

let backgroundColor = ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)'];
let borderColor = ['rgb(255, 99, 132)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)'];

function getLabels(t) {
    return ['Total', `${t('Taxable')}`, `${t('contract-Amounts-Chart-Net')}`];
}

class ContractAmountsCharts extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            dataHorizontal: {
                labels: getLabels(t),
                datasets: [
                    {
                        data: [0, 0, 0],
                        fill: false,
                        label: '',
                        backgroundColor: backgroundColor,
                        borderColor: borderColor,
                        borderWidth: 1
                    }
                ],
            },
            options: {
                responsive: true,
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        }
                    }]
                },
                plugins: {
                    datalabels: {
                        formatter: function (value, context) {
                            if (value === 0) {
                                return '';
                            }
                            return getNumber(value) + ' €';
                        }
                    }
                }
            }
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            let data = [roundTwoDecimalCases(this.props.totalAmount), roundTwoDecimalCases(this.props.taxableAmount), roundTwoDecimalCases(this.props.netAmount)];
            const {t} = this.props;
            const labels = getLabels(t);
            if (this.props.taxRetentionPercentage > 0) {
                this.addTaxRetentionValues(data);
                labels.push(`${t('contract-Amounts-Chart-Retained')}`);
            }
            let dataHorizontal = {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        fill: false,
                        label: '',
                        backgroundColor: backgroundColor,
                        borderColor: borderColor,
                        borderWidth: 1
                    }
                ],
            };
            this.setState({dataHorizontal});
        }
    }


    addTaxRetentionValues(data) {
        const {t} = this.props;
        backgroundColor.push('rgba(54, 162, 235, 0.2)');
        borderColor.push('rgb(54, 162, 235)');

        const retainedAmount = roundTwoDecimalCases(this.props.taxableAmount * (this.props.taxRetentionPercentage / 100));
        data.push(roundTwoDecimalCases(retainedAmount));
    }

    render() {
        const {t} = this.props;
        return (
            <MDBContainer>
                <h3 className='mt-5'>{t('contract-Amounts')}</h3>
                <HorizontalBar
                    height={100}
                    data={this.state.dataHorizontal}
                    options={this.state.options}
                />
            </MDBContainer>
        );
    }
}

export default withTranslation()(ContractAmountsCharts);
