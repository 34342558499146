import React from "react";
import {lookupValuesService, propertyService, tenantService} from "../../../_services";
import {filterBy} from "@progress/kendo-data-query";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {CREATE_CONTRACT} from "../CreateContractPage/CreateContract";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import FormGroup from "react-bootstrap/FormGroup";
import {Input, Label, Tooltip} from "reactstrap";
import MultiSelect from "@progress/kendo-react-dropdowns/dist/es/MultiSelect/MultiSelect";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import {contractService} from "../../../_services/contract.service";
import {EDIT_CONTRACT} from "../EditContractPage/EditContract";
import {successToast} from "../../../_helpers/toast-functions";
import {MDBIcon} from "mdbreact";
import {getMapOfStringProperties} from "../../Properties/_aux/properties-functions";
import {roundTwoDecimalCases} from "../../../_helpers/util-functions";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import MyNumberInput from "../../../_components/MyNumberInput";


class ContractForm extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        this.toggle = this.toggle.bind(this);
        let baseFrequencyTypes = [
            {id: 1, name: `${t('contractForm-baseFrequencyTypes-Monthly')}`, frequencyTypeId: 3, frequencyTypeValue: 1},
            {id: -1, name: `${t('contractForm-baseFrequencyTypes-Other')}`}
        ];
        this.state = {
            tooltipTaxableAmount: false,
            tooltipExtraAmount: false,
            tooltipFrequency: false,

            propertiesFetched: false,
            properties: [],
            allProperties: [],
            property: null,

            currencies: [],
            allCurrencies: [],
            currency: null,
            startDate: null,
            endDate: null,
            startDateRenegotiationPeriod: null,
            endDateRenegotiationPeriod: null,

            allBaseFrequencyTypes: baseFrequencyTypes,
            baseFrequencyTypes: baseFrequencyTypes,
            baseFrequencyType: null,

            frequencyTypes: [],
            allFrequencyTypes: [],
            frequencyType: null,

            tenants: [],
            allTenants: [],
            selectedTenants: [],

            yesNoMap: [{display: `${t('No')}`, value: false}, {display: `${t('Yes')}`, value: true}]
        };
    }

    toggle = (event) => {
        switch (event.target.id) {
            case "tooltipTaxableAmount":
                this.setState({tooltipTaxableAmount: !this.state.tooltipTaxableAmount});
                break;
            case "tooltipExtraAmount":
                this.setState({tooltipExtraAmount: !this.state.tooltipExtraAmount});
                break;
            case "tooltipFrequency":
                this.setState({tooltipFrequency: !this.state.tooltipFrequency});
                break;
            default:
                console.warn("unhandled change event for " + event.target.id);
        }
    };

    componentDidMount() {
        const {t} = this.props;
        propertyService.getAllSummary(this.props.entityId).then(response => {
            this.setState({propertiesFetched: true});
            const propertiesTranslated = response.map(x => {
                let translatedObj = x;
                translatedObj.propertyType = `${t(`properties-${x.propertyType.toUpperCase()}`)}`;
                return translatedObj;
            });
            const mapOfStringProperties = getMapOfStringProperties(propertiesTranslated);
            this.setState({properties: mapOfStringProperties});
            this.setState({allProperties: mapOfStringProperties});
            this.setState({property: this.getProperty(mapOfStringProperties)});
        });
        lookupValuesService.getCurrencies().then(response => {
            this.setState({currencies: response});
            this.setState({allCurrencies: response})
        });
        lookupValuesService.getFrequencyTypes().then(response => {

            const frequencyTypesTranslated = response.map(x => {
                let translatedObj = x;
                translatedObj.name = `${t(`frequencyType-${x.name.toUpperCase()}`)}`;
                return translatedObj;
            });

            this.setState({frequencyTypes: frequencyTypesTranslated});
            this.setState({allFrequencyTypes: frequencyTypesTranslated})
        });
        tenantService.getAll(this.props.entityId).then(response => {
            this.setState({tenants: response});
            this.setState({allTenants: response});
        });
    }

    getProperty(allProperties) {
        const propertyId = this.props.propertyId;
        for (const p of allProperties) {
            if (p.id == propertyId) {
                return p;
            }
        }
        return null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {};

        if (!prevState.currency) {
            newState.currency = setCurrency(nextProps, prevState);
        }
        if (!prevState.startDate && nextProps.startDate) {
            newState.startDate = new Date(nextProps.startDate);
        }
        if (!prevState.endDate && nextProps.endDate) {
            newState.endDate = new Date(nextProps.endDate);
        }
        if (!prevState.startDateRenegotiationPeriod && nextProps.startDateRenegotiationPeriod) {
            newState.startDateRenegotiationPeriod = new Date(nextProps.startDateRenegotiationPeriod);
        }
        if (!prevState.endDateRenegotiationPeriod && nextProps.endDateRenegotiationPeriod) {
            newState.endDateRenegotiationPeriod = new Date(nextProps.endDateRenegotiationPeriod);
        }
        if (!prevState.frequencyType && !prevState.baseFrequencyType && !prevState.frequencyTypeChanged) {
            newState.frequencyType = setFrequencyType(nextProps, prevState);
            if (newState.frequencyType) {
                newState.baseFrequencyType = setBaseFrequencyType(prevState, newState, nextProps);
            }
        }
        if (!prevState.selectedTenants.length) {
            newState.selectedTenants = setSelectedTenants(nextProps, prevState);
        }
        return newState;
    }

    handleChange = (event) => {
        switch (event.target.name) {
            case "property":
                this.setState({property: event.target.value});
                break;
            case "currency":
                this.setState({currency: event.target.value});
                break;
            case "baseFrequencyType":
                let baseFrequencyType = event.target.value;
                this.setState({baseFrequencyType});
                if (baseFrequencyType) {
                    const frequencyType = this.state.allFrequencyTypes.find(f => f.id == baseFrequencyType.frequencyTypeId);
                    if (frequencyType) {
                        event.setFieldValue("frequencyValue", baseFrequencyType.frequencyTypeValue);
                    } else {
                        event.setFieldValue("frequencyValue", null);
                    }
                    this.setState({frequencyType: frequencyType});
                } else {
                    this.setState({frequencyType: null})
                    event.setFieldValue("frequencyValue", null);
                }
                this.setState({frequencyTypeChanged: true});
                break;
            case "frequencyType":
                this.setState({frequencyType: event.target.value, frequencyTypeChanged: true});
                break;
            case "tenants":
                this.setState({selectedTenants: event.target.value});
                break;
            case "startDate":
                const startDate = new Date(event.target.value);
                startDate.setHours(10);
                this.setState({startDate});
                break;
            case "endDate":
                const endDate = new Date(event.target.value);
                endDate.setHours(16);
                this.setState({endDate});
                break;
            case "startDateRenegotiationPeriod":
                const startDateRenegotiationPeriod = new Date(event.target.value);
                startDateRenegotiationPeriod.setHours(10);
                this.setState({startDateRenegotiationPeriod});
                break;
            case "endDateRenegotiationPeriod":
                const endDateRenegotiationPeriod = new Date(event.target.value);
                endDateRenegotiationPeriod.setHours(16);
                this.setState({endDateRenegotiationPeriod});
                break;
            default:
                console.warn("unhandled change event for " + event.target.name);
        }
    };

    filterChange = (event) => {
        switch (event.target.name) {
            case "property":
                const properties = this.state.allProperties.slice();
                this.setState({properties: filterBy(properties, event.filter)});
                break;
            case "currency":
                const currencies = this.state.allCurrencies.slice();
                this.setState({currencies: filterBy(currencies, event.filter)});
                break;
            case "baseFrequencyType":
                const baseFrequencyTypes = this.state.allBaseFrequencyTypes.slice();
                this.setState({baseFrequencyTypes: filterBy(baseFrequencyTypes, event.filter)});
                break;
            case "frequencyType":
                const frequencyTypes = this.state.allFrequencyTypes.slice();
                this.setState({frequencyTypes: filterBy(frequencyTypes, event.filter)});
                break;
            case "tenants":
                const tenants = this.state.allTenants.slice();
                this.setState({tenants: filterBy(tenants, event.filter)});
                break;
            default:
                console.warn("unhandled filter change for " + event.target.name);
        }
    };

    render() {
        const {t} = this.props;
        const entityId = this.props.entityId;
        return (
            <Formik
                enableReinitialize
                initialValues={this.props.initialValues}
                validationSchema={Yup.object().shape(contractValidationSchema)}
                onSubmit={(
                    {
                        guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue
                    },
                    {setStatus, setSubmitting}
                ) => {
                    const taxRetentionBoolean = taxRetention == true || taxRetention == 'true';
                    if (!this.isValidInput(amount, frequencyValue, taxRetentionBoolean, taxableAmount)) {
                        setSubmitting(false);
                        let error = this.getErrorMessages(amount, frequencyValue, taxRetentionBoolean, taxableAmount);
                        setStatus(error);
                    } else {
                        setStatus();
                        this.createOrUpdateContract(guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue, setSubmitting, setStatus);
                        setSubmitting(false);
                    }
                }}
                render={({errors, status, values, touched, isSubmitting, handleChange, setFieldValue}) => (
                    <Form>
                        {
                            !this.state.allProperties.length && this.state.propertiesFetched &&
                            <div>
                                <div className={'alert alert-danger'}>{t('contractForm-You have no properties created. Please')} <Link
                                    to={`/entities/${entityId}/property/new`}><u>{t('contractForm-click here')}</u></Link> {t('contractForm-to create one first')}.
                                </div>
                            </div>
                        }

                        <div className="form-group required">
                            <label htmlFor="selectedTenants" className="control-label">{t('contractForm-Tenants')}</label> <br/>
                            <MultiSelect
                                placeholder={`${t('contractForm-Tenants-placeholder')}`}
                                data={this.state.tenants}
                                textField="name"
                                dataItemKey="id"
                                value={this.state.selectedTenants}
                                name="tenants"
                                onChange={this.handleChange}
                                filterable={true}
                                onFilterChange={this.filterChange}
                                style={{backgroundColor: "inherit", "width": "90%", "marginRight": "10px"}}/>
                            <Link to={`/entities/${this.props.entityId}/tenant/new`}>
                                <MDBIcon icon="plus" style={{color: 'green'}}> {t('New')}</MDBIcon>
                            </Link>
                            <ErrorMessage name="selectedTenants" component="div" className="invalid-feedback"/>
                            {
                                status && status.tenantsId &&
                                <div className="text-danger invalid-input">
                                    {t('contractForm-Tenants')} {status.tenantsId}
                                </div>
                            }
                        </div>

                        <div className="form-group required">
                            <label htmlFor="property" className="control-label">{t('contractForm-Property')}</label>
                            {
                                this.props.page === CREATE_CONTRACT
                                    ? <ComboBox
                                        data={this.state.properties}
                                        textField="name"
                                        dataItemKey="id"
                                        value={this.state.property}
                                        name="property"
                                        onChange={this.handleChange}
                                        filterable={true}
                                        onFilterChange={this.filterChange}
                                        style={{"width": "100%"}}/>
                                    : <Field readOnly={true} name="property" type="text" value={this.state.property && this.state.property.name}
                                             className={'form-control' + (errors.property && touched.property ? ' is-invalid' : '')}/>
                            }
                            <ErrorMessage name="property" component="div" className="invalid-feedback"/>
                            {
                                status && status.property &&
                                <div className="text-danger invalid-input">
                                    {t('contractForm-Property')} {status.property}
                                </div>
                            }
                        </div>

                        <div className="form-group required">
                            <label htmlFor="currency" className="control-label">{t('contractForm-Currency')}</label> <br/>
                            <ComboBox
                                data={this.state.currencies}
                                textField="name"
                                dataItemKey="id"
                                value={this.state.currency}
                                name="currency"
                                onChange={this.handleChange}
                                filterable={true}
                                onFilterChange={this.filterChange}
                            />
                            <ErrorMessage name="currency" component="div" className="invalid-feedback"/>
                            {
                                status && status.currencyId &&
                                <div className="text-danger invalid-input">
                                    {t('contractForm-Currency')} {status.currencyId}
                                </div>
                            }
                        </div>

                        <div className="form-group smallInput">
                            <label htmlFor="guarantee">{t('contractForm-Guarantee')}</label>
                            <MyNumberInput
                                className={'form-control' + (errors.guarantee && touched.guarantee ? ' is-invalid' : '')}
                                placeholder="Ex: 123,98"
                                value={values.guarantee}
                                onValueChange={val => setFieldValue('guarantee', val.floatValue)}
                            />
                            <ErrorMessage name="guarantee" component="div" className="invalid-feedback"/>
                            {
                                status && status.constraints && status.constraints.guarantee &&
                                <div className="text-danger invalid-input">
                                    {t('contractForm-Guarantee')} {status.constraints.guarantee}
                                </div>
                            }
                        </div>

                        <div className="form-group smallInput required">
                            <label htmlFor="amount" className="control-label">{t('contractForm-Amount')}</label>
                            <MyNumberInput
                                className={'form-control' + (errors.amount && touched.amount ? ' is-invalid' : '')}
                                placeholder="Ex: 123,98"
                                value={values.amount}
                                onValueChange={val => setFieldValue('amount', val.floatValue)}
                            />
                            <ErrorMessage name="amount" component="div" className="invalid-feedback"/>
                            {
                                status && status.amount &&
                                <div className="text-danger invalid-input">
                                    {t('contractForm-Amount')} {status.amount}
                                </div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group smallInput">
                                    <label htmlFor="extraAmount">{t('contractForm-Extra Amount')}</label>
                                    <MDBIcon id="tooltipExtraAmount" icon="question" style={{color: "#00008B", paddingLeft: "5px"}}/>
                                    <Tooltip placement="right" isOpen={this.state.tooltipExtraAmount} target="tooltipExtraAmount" toggle={this.toggle}>
                                        {t('contractForm-Extra Amount-Tooltip')}
                                    </Tooltip>
                                    <MyNumberInput
                                        className={'form-control' + (errors.extraAmount && touched.extraAmount ? ' is-invalid' : '')}
                                        placeholder="Ex: 123,98"
                                        value={values.extraAmount}
                                        onValueChange={val => setFieldValue('extraAmount', val.floatValue)}
                                    />
                                    <ErrorMessage name="extraAmount" component="div" className="invalid-feedback"/>
                                    {
                                        status && status.extraAmount &&
                                        <div className="text-danger invalid-input">
                                            {t('contractForm-Extra Amount')} {status.extraAmount}
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                values.extraAmount &&
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="extraAmountDescription">{t('contractForm-Extra Amount Description')}</label>
                                        <Field name="extraAmountDescription" component="textarea"
                                               className={'form-control' + (errors.extraAmountDescription && touched.extraAmountDescription ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="extraAmountDescription" component="div" className="invalid-feedback"/>
                                        {
                                            status && status.constraints && status.constraints.extraAmountDescription &&
                                            <div className="text-danger invalid-input">
                                                {t('contractForm-Extra Amount Description')} {status.constraints.extraAmountDescription}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="form-group smallInput">
                            <label htmlFor="amount">{t('contractForm-Total Contract Amount')}</label>
                            <Field name="amount" type="text" readOnly={true} value={roundTwoDecimalCases(values.amount) + roundTwoDecimalCases(values.extraAmount)}
                                   className="form-control"/>
                        </div>

                        <div className="form-group smallInput">
                            <label htmlFor="taxableAmount">{t('contractForm-Taxable Amount')}</label>
                            <MDBIcon id="tooltipTaxableAmount" icon="question" style={{color: "#00008B", paddingLeft: "5px"}}/>
                            <Tooltip placement="right" isOpen={this.state.tooltipTaxableAmount} target="tooltipTaxableAmount" toggle={this.toggle}>
                                {t('contractForm-Taxable Amount-Tooltip')}.
                            </Tooltip>
                            <MyNumberInput
                                className={'form-control' + (errors.taxableAmount && touched.taxableAmount ? ' is-invalid' : '')}
                                placeholder="Ex: 123,98"
                                value={values.taxableAmount}
                                onValueChange={val => setFieldValue('taxableAmount', val.floatValue)}
                            />
                            <ErrorMessage name="taxableAmount" component="div" className="invalid-feedback"/>
                            {
                                status && status.taxableAmount &&
                                <div className="text-danger invalid-input">
                                    {t('contractForm-Taxable Amount')} {status.taxableAmount}
                                </div>
                            }
                        </div>

                        <div className="form-group smallInput">
                            <label htmlFor="amount">{t('contractForm-Untaxed Amount')}</label>
                            <MyNumberInput
                                readOnly={true}
                                className="form-control"
                                value={roundTwoDecimalCases(values.amount) + roundTwoDecimalCases(values.extraAmount) - roundTwoDecimalCases(values.taxableAmount)}
                            />
                        </div>

                        <div className="row">
                            <div className="form-group required col-md-4 col-sm-6">
                                <label htmlFor="startDate" className="control-label">{t('contractForm-Start Date')}</label> <br/>
                                <DatePicker
                                    format={"dd-MM-yyyy"}
                                    formatPlaceholder="formatPattern"
                                    name="startDate"
                                    onChange={this.handleChange}
                                    value={this.state.startDate}
                                />
                                <ErrorMessage name="startDate" component="div" className="invalid-feedback"/>
                                {
                                    status && status.startDate &&
                                    <div className="text-danger invalid-input">
                                        {t('contractForm-Start Date')} {status.startDate}
                                    </div>
                                }
                            </div>
                            <div className="form-group required col-md-4 col-sm-6">
                                <label htmlFor="endDate" className="control-label">{t('contractForm-End Date')}</label> <br/>
                                <DatePicker
                                    format={"dd-MM-yyyy"}
                                    formatPlaceholder="formatPattern"
                                    name="endDate"
                                    onChange={this.handleChange}
                                    value={this.state.endDate}
                                />
                                <ErrorMessage name="endDate" component="div" className="invalid-feedback"/>
                                {
                                    status && status.endDate &&
                                    <div className="text-danger invalid-input">
                                        {t('contractForm-End Date')} {status.endDate}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-4 col-sm-6">
                                <label htmlFor="startDateRenegotiationPeriod">{t('contractForm-Start Renegotiation')}</label> <br/>
                                <DatePicker
                                    format={"dd-MM-yyyy"}
                                    formatPlaceholder="formatPattern"
                                    name="startDateRenegotiationPeriod"
                                    onChange={this.handleChange}
                                    value={this.state.startDateRenegotiationPeriod}
                                />
                                <ErrorMessage name="startDateRenegotiationPeriod" component="div" className="invalid-feedback"/>
                                {
                                    status && status.startDateRenegotiationPeriod &&
                                    <div className="text-danger invalid-input">
                                        {t('contractForm-Start Renegotiation')} {status.startDateRenegotiationPeriod}
                                    </div>
                                }
                            </div>
                            <div className="form-group col-md-4 col-sm-6">
                                <label htmlFor="endDateRenegotiationPeriod">{t('contractForm-End Renegotiation')}</label> <br/>
                                <DatePicker
                                    format={"dd-MM-yyyy"}
                                    formatPlaceholder="formatPattern"
                                    name="endDateRenegotiationPeriod"
                                    onChange={this.handleChange}
                                    value={this.state.endDateRenegotiationPeriod}
                                />
                                <ErrorMessage name="endDateRenegotiationPeriod" component="div" className="invalid-feedback"/>
                                {
                                    status && status.endDateRenegotiationPeriod &&
                                    <div className="text-danger invalid-input">
                                        {t('contractForm-End Renegotiation')} {status.endDateRenegotiationPeriod}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-4 col-sm-6">
                                <FormGroup className="smallInput">
                                    <Label for="exampleSelect">{t('contractForm-Tax Retention')}</Label>
                                    <Input type="select" name="taxRetention" value={values.taxRetention} onChange={handleChange}>
                                        {this.state.yesNoMap.map((option, index) =>
                                            <option key={index} value={option.value}>
                                                {option.display}
                                            </option>
                                        )}
                                    </Input>
                                </FormGroup>
                                <ErrorMessage name="taxRetention" component="div" className="invalid-feedback"/>
                                {
                                    this.props.status && this.props.status.constraints && this.props.status.constraints.taxRetention &&
                                    <div className="text-danger invalid-input">
                                        {t('contractForm-Tax Retention')} {this.props.status.constraints.taxRetention}
                                    </div>

                                }
                            </div>
                            {
                                (values.taxRetention === "true" || values.taxRetentionPercentage) &&
                                <div className="form-group smallInput col-md-4 col-sm-6">
                                    <label htmlFor="taxRetentionPercentage">{t('contractForm-Tax Retention %')}</label>
                                    <MyNumberInput
                                        className={'form-control' + (errors.taxRetentionPercentage && touched.taxRetentionPercentage ? ' is-invalid' : '')}
                                        placeholder="Ex: 20"
                                        value={values.taxRetentionPercentage}
                                        onValueChange={val => setFieldValue('taxRetentionPercentage', val.floatValue)}
                                    />
                                    <ErrorMessage name="taxRetentionPercentage" component="div" className="invalid-feedback"/>
                                    {
                                        status && status.constraints && status.constraints.taxRetentionPercentage &&
                                        <div className="text-danger invalid-input">
                                            {t('contractForm-Tax Retention %')} {status.constraints.taxRetentionPercentage}
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        <div className="row">
                            <div className="form-group required smallInput col-md-4">
                                <label htmlFor="frequencyType" className="control-label">{t('contractForm-Frequency')}</label> <br/>
                                <ComboBox
                                    data={this.state.baseFrequencyTypes}
                                    textField="name"
                                    dataItemKey="id"
                                    value={this.state.baseFrequencyType}
                                    name="baseFrequencyType"
                                    onChange={e => {
                                        e.setFieldValue = setFieldValue;
                                        this.handleChange(e);
                                    }}
                                    filterable={true}
                                    onFilterChange={this.filterChange}
                                />
                                <ErrorMessage name="frequencyType" component="div" className="invalid-feedback"/>
                                {
                                    status && status.frequencyTypeId &&
                                    <div className="text-danger invalid-input">
                                        {t('contractForm-Frequency')} {status.frequencyTypeId}
                                    </div>
                                }
                            </div>
                            {
                                this.state.baseFrequencyType && this.state.baseFrequencyType.id === -1 &&
                                <div className="col-md-4">
                                    <div className="form-group required smallInput">
                                        <label htmlFor="frequencyType" className="control-label">{t('contractForm-Frequency Type')}</label> <br/>
                                        <ComboBox
                                            data={this.state.frequencyTypes}
                                            textField="name"
                                            dataItemKey="id"
                                            value={this.state.frequencyType}
                                            name="frequencyType"
                                            onChange={this.handleChange}
                                            filterable={true}
                                            onFilterChange={this.filterChange}
                                        />
                                        <ErrorMessage name="frequencyType" component="div" className="invalid-feedback"/>
                                        {
                                            status && status.frequencyTypeId &&
                                            <div className="text-danger invalid-input">
                                                {t('contractForm-Frequency Type')} {status.frequencyTypeId}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.baseFrequencyType && this.state.baseFrequencyType.id === -1 && this.state.frequencyType &&
                                <div className="col-md-4">
                                    <div className="form-group smallInput required">
                                        <label htmlFor="frequencyValue" className="control-label">{t('contractForm-Every')} __ {this.state.frequencyType.name}</label>
                                        <MDBIcon id="tooltipFrequency" icon="question" style={{color: "#00008B", paddingLeft: "5px"}}/>
                                        <Tooltip placement="right" isOpen={this.state.tooltipFrequency} target="tooltipFrequency" toggle={this.toggle}>
                                            {t('contractForm-A quarterly contract is')}:<br/>
                                            {t('contractForm-Frequency Type')}: <u><b>{t('contractForm-Month')}</b></u><br/>
                                            {t('contractForm-Every')} <u><b>3</b></u> {t('contractForm-Month')}
                                        </Tooltip>
                                        <Field name="frequencyValue" type="text"
                                               className={'form-control' + (errors.frequencyValue && touched.frequencyValue ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="frequencyValue" component="div" className="invalid-feedback"/>
                                        {
                                            status && status.frequencyValue &&
                                            <div className="text-danger invalid-input">
                                                {t('contractForm-Frequency Value')} {status.frequencyValue}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {this.props.page === CREATE_CONTRACT ? <a>{t('Create')}</a> : <a>{t('Update')}</a>}
                            </button>
                            {
                                isSubmitting &&
                                <img
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                            }
                        </div>
                        {
                            status && status.message &&
                            <div>
                                <div className={'alert alert-danger'}>
                                    {status.message}
                                    <ul>
                                        {status.messageParams && status.messageParams.map(
                                            contract =>
                                                <li>
                                                    <Link to={`/entities/${entityId}/properties/${contract.propertyId}/contracts/${contract.contractId}`} target="_blank"
                                                          rel="noopener noreferrer">
                                                        Contract {contract.contractId}
                                                    </Link>
                                                </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        }
                    </Form>
                )}
            />
        );
    }

    createOrUpdateContract(guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue, setSubmitting, setStatus) {
        if (this.props.page === CREATE_CONTRACT) {
            this.createContract(guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue, setSubmitting, setStatus);
        } else if (this.props.page === EDIT_CONTRACT) {
            this.updateContract(guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue, setSubmitting, setStatus);
        }
    }

    createContract(guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue, setSubmitting, setStatus) {
        const {t} = this.props;
        const propertyId = this.state.property.id;
        contractService.createContract(
            this.props.entityId, propertyId,
            this.state.currency.id, this.state.startDate, this.state.endDate, this.state.startDateRenegotiationPeriod,
            this.state.endDateRenegotiationPeriod, this.state.frequencyType.id, this.state.selectedTenants,
            guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue
        )
            .then(
                response => {
                    successToast(`${t('toast-Contract successfully created')}!`);
                    this.props.history.push(`/entities/${this.props.entityId}/properties/${propertyId}/contracts/${response}`);
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }

    updateContract(guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue, setSubmitting, setStatus) {
        const {t} = this.props;
        const propertyId = this.state.property.id;
        contractService.updateContract(
            this.props.entityId, propertyId, this.props.contractId,
            this.state.currency.id, this.state.startDate, this.state.endDate, this.state.startDateRenegotiationPeriod,
            this.state.endDateRenegotiationPeriod, this.state.frequencyType.id, this.state.selectedTenants,
            guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue
        )
            .then(
                response => {
                    successToast(`${t('toast-Contract successfully updated')}!`);
                    this.props.history.push(`/entities/${this.props.entityId}/properties/${propertyId}/contracts/${this.props.contractId}`);
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }

    isValidInput(amount, frequencyValue, taxRetention, taxableAmount) {
        return ((taxRetention && taxableAmount > 0) || !taxRetention)
            && this.state.property != null
            && this.state.currency != null
            && amount != null
            && this.state.frequencyType != null
            && frequencyValue != null
            && this.state.selectedTenants.length > 0
            && this.state.startDate != null
            && this.state.endDate != null
            && this.state.startDate <= this.state.endDate
            && (!this.state.startDateRenegotiationPeriod
                || !this.state.endDateRenegotiationPeriod
                || this.state.startDateRenegotiationPeriod <= this.state.endDateRenegotiationPeriod)
            && (!this.state.startDateRenegotiationPeriod
                || (this.state.startDateRenegotiationPeriod >= this.state.startDate
                    && this.state.startDateRenegotiationPeriod <= this.state.endDate))
            && (!this.state.endDateRenegotiationPeriod
                || (this.state.endDateRenegotiationPeriod >= this.state.startDate
                    && this.state.endDateRenegotiationPeriod <= this.state.endDate));
    }

    getErrorMessages(amount, frequencyValue, taxRetention, taxableAmount) {
        let error = {};
        if (taxRetention && !taxableAmount) {
            error["taxableAmount"] = "is required when contract has Tax Retention. Suggestion: Taxable Amount should be " + amount;
        }
        if (!this.state.property) {
            error["property"] = "is required";
        }
        if (!this.state.currency) {
            error["currencyId"] = "is required";
        }
        if (!amount) {
            error["amount"] = "is required";
        }
        if (!this.state.frequencyType) {
            error["frequencyTypeId"] = "is required";
        }
        if (!frequencyValue) {
            error["frequencyValue"] = "is required";
        }
        if (this.state.selectedTenants.length === 0) {
            error["tenantsId"] = "is missing"
        }
        if (!this.state.startDate) {
            error["startDate"] = "is required";
        }
        if (!this.state.endDate) {
            error["endDate"] = "is required";
        }
        if (this.state.startDate
            && this.state.endDate
            && this.state.startDate > this.state.endDate) {
            error["startDate"] = "must be before End Date";
            error["endDate"] = "must be after Start Date";
        }
        if (this.state.startDateRenegotiationPeriod
            && this.state.endDateRenegotiationPeriod
            && this.state.startDateRenegotiationPeriod > this.state.endDateRenegotiationPeriod) {
            error["startDateRenegotiationPeriod"] = "must be before the End of Renegotiation Date";
            error["endDateRenegotiationPeriod"] = "must be after the Start of Renegotiation Date";
        }
        if (this.state.startDateRenegotiationPeriod
            && (this.state.startDateRenegotiationPeriod < this.state.startDate
                || this.state.startDateRenegotiationPeriod > this.state.endDate)) {
            error["startDateRenegotiationPeriod"] = "must be during the contract";
        }
        if (this.state.endDateRenegotiationPeriod
            && (this.state.endDateRenegotiationPeriod < this.state.startDate
                || this.state.endDateRenegotiationPeriod > this.state.endDate)) {
            error["endDateRenegotiationPeriod"] = "must be during the contract";
        }
        return error;
    }
}

export default withTranslation()(ContractForm);

export const contractValidationSchema = {
    guarantee: Yup.number().typeError("Invalid number")
        .positive("Must be a positive number")
        .nullable(),
    amount: Yup.number().typeError("Invalid number")
        .positive("Must be a positive number")
        .nullable(),
    taxableAmount: Yup.number().typeError("Invalid number")
        .positive("Must be a positive number")
        .nullable(),
    extraAmount: Yup.number().typeError("Invalid number")
        .positive("Must be a positive number")
        .nullable(),
    extraAmountDescription: Yup.string()
        .max(255, 'Cannot contain more than 255 characters')
        .nullable(),
    taxRetentionPercentage: Yup.number().typeError("Invalid number")
        .positive("Must be a positive number")
        .nullable(),
    frequencyValue: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number")
        .nullable()
};

function setCurrency(prop, prevState) {
    if (prevState.currency || !prop.currencyId || !prevState.allCurrencies.length) {
        return null;
    }
    for (const curr of prevState.allCurrencies) {
        if (curr.id === prop.currencyId) {
            return curr;
        }
    }
    return null;
}

function setFrequencyType(prop, prevState) {
    if (prevState.frequencyType || !prop.frequencyTypeId || !prevState.allFrequencyTypes.length) {
        return null;
    }
    for (const ft of prevState.allFrequencyTypes) {
        if (ft.id === prop.frequencyTypeId) {
            return ft;
        }
    }
    return null;
}

function setBaseFrequencyType(prevState, newState, nextProps) {
    let baseFrequencyType = prevState.allBaseFrequencyTypes
        .find(f => f.frequencyTypeId === newState.frequencyType.id && f.frequencyTypeValue == nextProps.initialValues.frequencyValue);

    if (!baseFrequencyType) {
        baseFrequencyType = prevState.allBaseFrequencyTypes
            .find(f => f.id === -1);
    }
    return baseFrequencyType;
}

function setSelectedTenants(prop, prevState) {
    let selectedTenants = [];
    if (prevState.selectedTenants.length || !prop.tenantsId || !prop.tenantsId.length || !prevState.allTenants.length) {
        return selectedTenants;
    }
    for (const tenantId of prop.tenantsId) {
        for (const tenant of prevState.allTenants) {
            if (tenantId === tenant.id) {
                selectedTenants.push(tenant);
            }
        }
    }
    return selectedTenants;
}
