import React from 'react';
import ExpenseForm from "../_aux/ExpenseForm";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

export const CREATE_EXPENSE = "CreateExpense";

class CreateExpense extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;
        const queryFilters = require('query-string').parse(this.props.location.search);
        const initialValues = {
            amount: '',
            name: '',
            description: '',
            includeInYield: true
        };
        const entityId = this.props.match.params.entityId;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('expenseForm-New Expense')}</h4>
                        </div>
                    </div>
                </div>
                <ExpenseForm
                    entityId={entityId}
                    propertyId={queryFilters["propertyId"]}
                    history={this.props.history}
                    initialValues={initialValues}
                    page={CREATE_EXPENSE}/>
            </div>
        );
    }
}

export default withTranslation()(CreateExpense);
