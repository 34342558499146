import React from 'react';
import ContractForm from "../_aux/ContractForm";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

export const CREATE_CONTRACT = "CreateContract";

class CreateContract extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;
        const queryFilters = require('query-string').parse(this.props.location.search);
        const initialValues = {
            guarantee: '',
            amount: '',
            taxableAmount: '',
            extraAmount: '',
            extraAmountDescription: '',
            taxRetention: false,
            taxRetentionPercentage: '',
            frequencyValue: ''
        };
        const entityId = this.props.match.params.entityId;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('contractForm-New Contract')}</h4>
                        </div>
                    </div>
                </div>
                <ContractForm
                    entityId={entityId}
                    propertyId={queryFilters["property"]}
                    history={this.props.history}
                    initialValues={initialValues}
                    page={CREATE_CONTRACT}/>
            </div>
        );
    }
}

export default withTranslation()(CreateContract);