import React from "react";

export function orderFormatter(column, colIndex) {
    return (
        <div>
            {column.text}
            {/*<MDBIcon icon="sort" style={{color: 'gray'}}/>*/}
        </div>
    );
}

export function coalesceString(value) {
    return value ? value : '';
}

export function coalesceObject(value) {
    return value ? value : undefined;
}

export function getNumberCleanValueIfParentNotNull(parentValue, childValue) {
    if (parentValue == null) {
        return null;
    } else {
        return getNumberCleanValue(childValue);
    }
}

export function getPrimitiveCleanValue(value) {
    if (value == null || value.trim() === "") {
        return null;
    } else {
        return value;
    }
}

export function getNumberCleanValue(value) {
    if (isNullOrEmpty(value)) {
        return null;
    } else {
        return value.toString().replace(/\s/g, '');
    }
}

export function getUtcDateCleanValue(value) {
    if (isNullOrEmpty(value)) {
        return null;
    } else {
        return value.toISOString();
    }
}

export function getObjectCleanId(object) {
    if (object == null) {
        return null;
    } else {
        return object.id;
    }
}

export function getCountEmptyAttributes(object) {
    let countEmptyAttributes = 0;
    for (const key of Object.keys(object)) {
        if ((!object[key]) || (object[key] == "false")) {
            countEmptyAttributes++;
        }
    }
    return countEmptyAttributes;
}

export function addAttributeIfExists(attributes, attributeName, attributeValue) {
    if (attributeValue) {
        attributes[attributeName] = attributeValue;
    }
}

function isNullOrEmpty(value) {
    if (!value) {
        return true;
    }
    const valueNoSpaces = value.toString().replace(/\s/g, '');
    return valueNoSpaces === "";
}

export function roundTwoDecimalCases(value) {
    if (!value) {
        return 0;
    }
    return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
}

export function deepCopyFunction(inObject) {
    let outObject, value, key

    if (typeof inObject !== "object" || inObject === null) {
        return inObject // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}

    for (key in inObject) {
        value = inObject[key]

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = deepCopyFunction(value)
    }

    return outObject
}