import React from 'react';
import {ErrorMessage} from "formik";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import Collapse from "react-bootstrap/Collapse";
import * as Yup from "yup";
import {Input, Label} from "reactstrap";
import FormGroup from "react-bootstrap/FormGroup";
import {createEventWithName} from "../../../../_helpers/event-functions";
import {withTranslation} from "react-i18next";
import MyNumberInput from "../../../../_components/MyNumberInput";


class DetailedInformationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setKitchenType: false,
            setOutsideAreaType: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let setKitchenTypeValue = {setKitchenType: prevState.setKitchenType};
        let setOutsideAreaTypeValue = {setOutsideAreaType: prevState.setOutsideAreaType};

        if (!prevState.setKitchenType) {
            setKitchenTypeValue = {setKitchenType: setKitchenType(nextProps)};
        }
        if (!prevState.setOutsideAreaType) {
            setOutsideAreaTypeValue = {setOutsideAreaType: setOutsideAreaType(nextProps)};
        }

        return ({...setKitchenTypeValue, ...setOutsideAreaTypeValue});
    }

    render() {
        const {t} = this.props;
        return (
            <Collapse in={!this.props.collapseDetailedInformation}>
                <div id="base-info-div">

                    <div className="form-group">
                        <FormGroup className="smallInput">
                            <Label for="exampleSelect">{t('propertyForm-Heating')}</Label>
                            <Input type="select" name="hasHeating" value={this.props.values.hasHeating} onChange={this.props.handleChange}>
                                {this.props.state.yesNoMap.map((option, index) =>
                                    <option key={index} value={option.value}>
                                        {option.display}
                                    </option>
                                )}
                            </Input>
                        </FormGroup>
                        <ErrorMessage name="hasHeating" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.hasHeating &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Heating')} {this.props.status.hasHeating}
                            </div>

                        }
                    </div>

                    <div className="form-group">
                        <FormGroup className="smallInput">
                            <Label for="exampleSelect">{t('propertyForm-Air Condition')}</Label>
                            <Input type="select" name="hasAirCondition" value={this.props.values.hasAirCondition} onChange={this.props.handleChange}>
                                {this.props.state.yesNoMap.map((option, index) =>
                                    <option key={index} value={option.value}>
                                        {option.display}
                                    </option>
                                )}
                            </Input>
                        </FormGroup>
                        <ErrorMessage name="hasAirCondition" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.hasAirCondition &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Air Condition')} {this.props.status.hasAirCondition}
                            </div>

                        }
                    </div>

                    <div className="form-group">
                        <FormGroup className="smallInput">
                            <Label for="exampleSelect">{t('propertyForm-Furnished')}</Label>
                            <Input type="select" name="isFurnished" value={this.props.values.isFurnished} onChange={this.props.handleChange}>
                                {this.props.state.yesNoMap.map((option, index) =>
                                    <option key={index} value={option.value}>
                                        {option.display}
                                    </option>
                                )}
                            </Input>
                        </FormGroup>
                        <ErrorMessage name="isFurnished" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.isFurnished &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Furnished')} {this.props.status.isFurnished}
                            </div>

                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="numBathrooms">N<sup>o</sup> {t('propertyForm-Bathrooms')}</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.numBathrooms && this.props.touched.numBathrooms ? ' is-invalid' : '')}
                            placeholder="Ex: 20"
                            value={this.props.values.numBathrooms}
                            onValueChange={val => this.props.setFieldValue('numBathrooms', val.floatValue)}
                        />
                        <ErrorMessage name="numBathrooms" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.numBathrooms &&
                            <div className="text-danger invalid-input">
                                N<sup>o</sup> {t('propertyForm-Bathrooms')} {this.props.status.numBathrooms}
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="numToilets">N<sup>o</sup> {t('propertyForm-Toilets')}</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.numToilets && this.props.touched.numToilets ? ' is-invalid' : '')}
                            placeholder="Ex: 20"
                            value={this.props.values.numToilets}
                            onValueChange={val => this.props.setFieldValue('numToilets', val.floatValue)}
                        />
                        <ErrorMessage name="numToilets" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.numToilets &&
                            <div className="text-danger invalid-input">
                                N<sup>o</sup> {t('propertyForm-Toilets')} {this.props.status.numToilets}
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4 col-sm-6">
                            <label htmlFor="kitchenType">{t('propertyForm-Kitchen Type')}</label> <br/>
                            <ComboBox
                                name="kitchenType"
                                data={this.props.state.kitchenTypes}
                                textField="name"
                                dataItemKey="id"
                                value={this.props.state.kitchenType}
                                filterable={true}
                                onFilterChange={this.props.handleFilterChange}
                                onChange={this.props.handleChangeComboBox}
                            />
                            <ErrorMessage name="kitchenType" component="div" className="invalid-feedback"/>
                            {
                                this.props.status && this.props.status.kitchenTypeId &&
                                <div className="text-danger invalid-input">
                                    {t('propertyForm-Kitchen Type')} {this.props.status.kitchenTypeId}
                                </div>
                            }
                            {
                                this.props.status && this.props.status.kitchenType &&
                                <div className="text-danger invalid-input">{this.props.status.kitchenType}</div>
                            }
                        </div>

                        {
                            this.props.state.kitchenType &&
                            <div className="form-group smallInput col-md-4 col-sm-6">
                                <label htmlFor="kitchenAreaMeters">{t('propertyForm-Kitchen Area')} (m<sup>2</sup>)</label>
                                <MyNumberInput
                                    className={'form-control' + (this.props.errors.kitchenAreaMeters && this.props.touched.kitchenAreaMeters ? ' is-invalid' : '')}
                                    placeholder="Ex: 20"
                                    value={this.props.values.kitchenAreaMeters}
                                    onValueChange={val => this.props.setFieldValue('kitchenAreaMeters', val.floatValue)}
                                />
                                <ErrorMessage name="kitchenAreaMeters" component="div" className="invalid-feedback"/>
                                {
                                    this.props.status && this.props.status.kitchenAreaMeters &&
                                    <div className="text-danger invalid-input">
                                        {t('propertyForm-Kitchen Area')} {this.props.status.kitchenAreaMeters}
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4 col-sm-6">
                            <label htmlFor="outsideAreaType">{t('propertyForm-Outside Area Type')}</label> <br/>
                            <ComboBox
                                name="outsideAreaType"
                                data={this.props.state.outsideAreaTypes}
                                textField="name"
                                dataItemKey="id"
                                value={this.props.state.outsideAreaType}
                                filterable={true}
                                onFilterChange={this.props.handleFilterChange}
                                onChange={this.props.handleChangeComboBox}
                            />
                            <ErrorMessage name="outsideAreaType" component="div" className="invalid-feedback"/>
                            {
                                this.props.status && this.props.status.outsideAreaTypeId &&
                                <div className="text-danger invalid-input">
                                    {t('propertyForm-Outside Area Type')} {this.props.status.outsideAreaTypeId}
                                </div>
                            }
                            {
                                this.props.status && this.props.status.outsideAreaType &&
                                <div className="text-danger invalid-input">{this.props.status.outsideAreaType}</div>
                            }
                        </div>

                        {
                            this.props.state.outsideAreaType &&
                            <div className="form-group smallInput col-md-4 col-sm-6">
                                <label htmlFor="outsideAreaMeters">{t('propertyForm-Outside Area')} (m<sup>2</sup>)</label>
                                <MyNumberInput
                                    className={'form-control' + (this.props.errors.outsideAreaMeters && this.props.touched.outsideAreaMeters ? ' is-invalid' : '')}
                                    placeholder="Ex: 20"
                                    value={this.props.values.outsideAreaMeters}
                                    onValueChange={val => this.props.setFieldValue('outsideAreaMeters', val.floatValue)}
                                />
                                <ErrorMessage name="outsideAreaMeters" component="div" className="invalid-feedback"/>
                                {
                                    this.props.status && this.props.status.outsideAreaMeters &&
                                    <div className="text-danger invalid-input">
                                        {t('propertyForm-Outside Area')} {this.props.status.outsideAreaMeters}
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="numBalconies">N<sup>o</sup> {t('propertyForm-Balconies')}</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.numBalconies && this.props.touched.numBalconies ? ' is-invalid' : '')}
                            placeholder="Ex: 20"
                            value={this.props.values.numBalconies}
                            onValueChange={val => this.props.setFieldValue('numBalconies', val.floatValue)}
                        />
                        <ErrorMessage name="numBalconies" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.numBalconies &&
                            <div className="text-danger invalid-input">
                                N<sup>o</sup> {t('propertyForm-Balconies')} {this.props.status.numBalconies}
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="numBedrooms">N<sup>o</sup> {t('propertyForm-Bedrooms')}</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.numBedrooms && this.props.touched.numBedrooms ? ' is-invalid' : '')}
                            placeholder="Ex: 20"
                            value={this.props.values.numBedrooms}
                            onValueChange={val => this.props.setFieldValue('numBedrooms', val.floatValue)}
                        />
                        <ErrorMessage name="numBedrooms" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.numBedrooms &&
                            <div className="text-danger invalid-input">
                                N<sup>o</sup> {t('propertyForm-Bedrooms')} {this.props.status.numBedrooms}
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="numSuits">N<sup>o</sup> {t('propertyForm-Suits')}</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.numSuits && this.props.touched.numSuits ? ' is-invalid' : '')}
                            placeholder="Ex: 20"
                            value={this.props.values.numSuits}
                            onValueChange={val => this.props.setFieldValue('numSuits', val.floatValue)}
                        />
                        <ErrorMessage name="numSuits" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.numSuits &&
                            <div className="text-danger invalid-input">
                                N<sup>o</sup> {t('propertyForm-Suits')} {this.props.status.numSuits}
                            </div>
                        }
                    </div>

                    <div className="form-group">
                        <FormGroup className="smallInput">
                            <Label for="exampleSelect">{t('propertyForm-Laundry')}</Label>
                            <Input type="select" name="hasLaundry" value={this.props.values.hasLaundry} onChange={this.props.handleChange}>
                                {this.props.state.yesNoMap.map((option, index) =>
                                    <option key={index} value={option.value}>
                                        {option.display}
                                    </option>
                                )}
                            </Input>
                        </FormGroup>
                        <ErrorMessage name="hasLaundry" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.hasLaundry &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Laundry')} {this.props.status.hasLaundry}
                            </div>

                        }
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4 col-sm-6">
                            <FormGroup className="smallInput">
                                <Label for="exampleSelect">{t('propertyForm-Storage Room')}</Label>
                                <Input type="select" name="hasStorageRoom" value={this.props.values.hasStorageRoom} onChange={this.props.handleChange}>
                                    {this.props.state.yesNoMap.map((option, index) =>
                                        <option key={index} value={option.value}>
                                            {option.display}
                                        </option>
                                    )}
                                </Input>
                            </FormGroup>
                            <ErrorMessage name="hasStorageRoom" component="div" className="invalid-feedback"/>
                            {
                                this.props.status && this.props.status.hasStorageRoom &&
                                <div className="text-danger invalid-input">
                                    {t('propertyForm-Storage Room')} {this.props.status.hasStorageRoom}
                                </div>

                            }
                        </div>

                        {
                            this.props.values.hasStorageRoom === "true" &&
                            <div className="form-group smallInput col-md-4 col-sm-6">
                                <label htmlFor="storageRoomAreaMeters">{t('propertyForm-Area of Storage Room')} (m<sup>2</sup>)</label>
                                <MyNumberInput
                                    className={'form-control' + (this.props.errors.storageRoomAreaMeters && this.props.touched.storageRoomAreaMeters ? ' is-invalid' : '')}
                                    placeholder="Ex: 20"
                                    value={this.props.values.storageRoomAreaMeters}
                                    onValueChange={val => this.props.setFieldValue('storageRoomAreaMeters', val.floatValue)}
                                />
                                <ErrorMessage name="storageRoomAreaMeters" component="div" className="invalid-feedback"/>
                                {
                                    this.props.status && this.props.status.storageRoomAreaMeters &&
                                    <div className="text-danger invalid-input">
                                        {t('propertyForm-Area of Storage Room')} {this.props.status.storageRoomAreaMeters}
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4 col-sm-6">
                            <FormGroup className="smallInput">
                                <Label for="exampleSelect">{t('propertyForm-Living Room')}</Label>
                                <Input type="select" name="hasLivingRoom" value={this.props.values.hasLivingRoom} onChange={this.props.handleChange}>
                                    {this.props.state.yesNoMap.map((option, index) =>
                                        <option key={index} value={option.value}>
                                            {option.display}
                                        </option>
                                    )}
                                </Input>
                            </FormGroup>
                            <ErrorMessage name="hasLivingRoom" component="div" className="invalid-feedback"/>
                            {
                                this.props.status && this.props.status.hasLivingRoom &&
                                <div className="text-danger invalid-input">
                                    {t('propertyForm-Living Room')} {this.props.status.hasLivingRoom}
                                </div>

                            }
                        </div>

                        {
                            this.props.values.hasLivingRoom === "true" &&
                            <div className="form-group smallInput col-md-4 col-sm-6">
                                <label htmlFor="livingRoomAreaMeters">{t('propertyForm-Area of Living Room')} (m<sup>2</sup>)</label>
                                <MyNumberInput
                                    className={'form-control' + (this.props.errors.livingRoomAreaMeters && this.props.touched.livingRoomAreaMeters ? ' is-invalid' : '')}
                                    placeholder="Ex: 20"
                                    value={this.props.values.livingRoomAreaMeters}
                                    onValueChange={val => this.props.setFieldValue('livingRoomAreaMeters', val.floatValue)}
                                />
                                <ErrorMessage name="livingRoomAreaMeters" component="div" className="invalid-feedback"/>
                                {
                                    this.props.status && this.props.status.livingRoomAreaMeters &&
                                    <div className="text-danger invalid-input">
                                        {t('propertyForm-Area of Living Room')} {this.props.status.livingRoomAreaMeters}
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4 col-sm-6">
                            <FormGroup className="smallInput">
                                <Label for="exampleSelect">{t('propertyForm-Dining Room')}</Label>
                                <Input type="select" name="hasDiningRoom" value={this.props.values.hasDiningRoom} onChange={this.props.handleChange}>
                                    {this.props.state.yesNoMap.map((option, index) =>
                                        <option key={index} value={option.value}>
                                            {option.display}
                                        </option>
                                    )}
                                </Input>
                            </FormGroup>
                            <ErrorMessage name="hasDiningRoom" component="div" className="invalid-feedback"/>
                            {
                                this.props.status && this.props.status.hasDiningRoom &&
                                <div className="text-danger invalid-input">
                                    {t('propertyForm-Dining Room')} {this.props.status.hasDiningRoom}
                                </div>

                            }
                        </div>

                        {
                            this.props.values.hasDiningRoom === "true" &&
                            <div className="form-group smallInput col-md-4 col-sm-6">
                                <label htmlFor="diningRoomAreaMeters">{t('propertyForm-Area of Dining Room')} (m<sup>2</sup>)</label>
                                <MyNumberInput
                                    className={'form-control' + (this.props.errors.diningRoomAreaMeters && this.props.touched.diningRoomAreaMeters ? ' is-invalid' : '')}
                                    placeholder="Ex: 20"
                                    value={this.props.values.diningRoomAreaMeters}
                                    onValueChange={val => this.props.setFieldValue('diningRoomAreaMeters', val.floatValue)}
                                />
                                <ErrorMessage name="diningRoomAreaMeters" component="div" className="invalid-feedback"/>
                                {
                                    this.props.status && this.props.status.diningRoomAreaMeters &&
                                    <div className="text-danger invalid-input">
                                        {t('propertyForm-Area of Dining Room')} {this.props.status.diningRoomAreaMeters}
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="numParkingInside">N<sup>o</sup> {t('propertyForm-Parking Spots (Inside)')}</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.numParkingInside && this.props.touched.numParkingInside ? ' is-invalid' : '')}
                            placeholder="Ex: 20"
                            value={this.props.values.numParkingInside}
                            onValueChange={val => this.props.setFieldValue('numParkingInside', val.floatValue)}
                        />
                        <ErrorMessage name="numParkingInside" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.numParkingInside &&
                            <div className="text-danger invalid-input">
                                N<sup>o</sup> {t('propertyForm-Parking Spots (Inside)')} {this.props.status.numParkingInside}
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="numParkingOutside">N<sup>o</sup> {t('propertyForm-Parking Spots (Outside)')}</label>
                        <MyNumberInput
                            className={'form-control' + (this.props.errors.numParkingOutside && this.props.touched.numParkingOutside ? ' is-invalid' : '')}
                            placeholder="Ex: 20"
                            value={this.props.values.numParkingOutside}
                            onValueChange={val => this.props.setFieldValue('numParkingOutside', val.floatValue)}
                        />
                        <ErrorMessage name="numParkingOutside" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.numParkingOutside &&
                            <div className="text-danger invalid-input">
                                N<sup>o</sup> {t('propertyForm-Parking Spots (Outside)')} {this.props.status.numParkingOutside}
                            </div>
                        }
                    </div>
                </div>
            </Collapse>
        );
    }
}

export default withTranslation()(DetailedInformationForm);

export const detailedInfoValidationSchema = {
    numBathrooms: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    numToilets: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    kitchenAreaMeters: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    outsideAreaMeters: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    numBalconies: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    numBedrooms: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    numSuits: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    storageRoomAreaMeters: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    livingRoomAreaMeters: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    diningRoomAreaMeters: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    numParkingInside: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number"),
    numParkingOutside: Yup.number().typeError("Invalid number")
        .integer("Cannot contain decimal numbers")
        .positive("Must be a positive number")
};

function setKitchenType(prop) {
    if (prop.state.kitchenType || !prop.kitchenTypeId || !prop.state.allKitchenTypes.length) {
        return false;
    }
    for (const kt of prop.state.allKitchenTypes) {
        if (kt.id === prop.kitchenTypeId) {
            prop.handleChangeComboBox(createEventWithName(kt, "kitchenType"));
            return true;
        }
    }
    return false;
}

function setOutsideAreaType(prop) {
    if (prop.state.outsideAreaType || !prop.outsideAreaTypeId || !prop.state.allOutsideAreaTypes.length) {
        return false;
    }
    for (const oat of prop.state.allOutsideAreaTypes) {
        if (oat.id === prop.outsideAreaTypeId) {
            prop.handleChangeComboBox(createEventWithName(oat, "outsideAreaType"));
            return true;
        }
    }
    return false;
}
