import {handleResponse} from "../_helpers/handle-response";
import {authHeader, jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {addAttributeIfExists, getNumberCleanValue, getPrimitiveCleanValue, getUtcDateCleanValue} from "../_helpers/util-functions";


export const rentPaymentService = {
    createRentPayment,
    deleteRentPayment,
    getInvoicePdfInformation,
};

function createRentPayment(entityId, propertyId, contractId, rentId, currencyId, paymentDate, amount, taxableAmount, description) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanRentPaymentAttributes(currencyId, paymentDate, amount, taxableAmount, description))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${rentId}/payments`, requestOptions).then(handleResponse);
}

function cleanRentPaymentAttributes(currencyId, paymentDate, amount, taxableAmount, description) {
    let attributes = {currencyId};

    addAttributeIfExists(attributes, "paymentDate", getUtcDateCleanValue(paymentDate));
    addAttributeIfExists(attributes, "amount", getNumberCleanValue(amount));
    addAttributeIfExists(attributes, "taxableAmount", getNumberCleanValue(taxableAmount));
    attributes["description"] = getPrimitiveCleanValue(description);

    return attributes;
}

function deleteRentPayment(entityId, propertyId, contractId, rentId, rentPaymentId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${rentId}/payments/${rentPaymentId}`, requestOptions).then(handleResponse);
}

function getInvoicePdfInformation(entityId, propertyId, contractId, rentId, paymentId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${rentId}/payments/${paymentId}/pdf`, requestOptions).then(handleResponse);
}
