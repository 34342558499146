import {authenticationService} from "../_services/authentication.service";

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.accessToken) {
        return { Authorization: `Bearer ${currentUser.accessToken}` };
    } else {
        return {};
    }
}

export function jwtToken() {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.accessToken) {
        return `Bearer ${currentUser.accessToken}`;
    } else {
        return "";
    }
}
