import React, {useState} from 'react';
import {MDBIcon} from "mdbreact";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {permissionService} from "../../../_services/permission.service";
import {contractService} from "../../../_services";
import {RouteComponentProps, useHistory} from "react-router";
import {ContractsTable} from "../_aux/ContractsTable";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";

export interface Tenant {
    id: number,
    name: string
}

export interface Property {
    id: number,
    name: string,
    propertyType: string,
    address: Object
}

export interface Contract {
    id: number,
    entityId: number,
    property: Property,
    startDate: Date,
    endDate: Date,
    startDateRenegotiationPeriod: Date,
    frequencyType: number,
    currency: string,
    amount: number,
    extraAmount: number,
    debt: number
}

export const ListContracts = ({match}: RouteComponentProps<Props>) => {
    const entityId = match.params.entityId;

    const {t} = useTranslation();
    const [view, setView] = useState(ACTIVE_CONTRACTS);
    const [allContracts, setAllContracts] = useState<Contract[]>([]);
    const [contractsView, setContractsView] = useState<Contract[]>([]);

    React.useEffect(() => {
        contractService.getAll(entityId)
            .then(contracts => {
                for (let i = 0; i < contracts.length; i++) {
                    contracts[i].entityId = entityId;
                }
                setAllContracts(contracts);
                setView(ACTIVE_CONTRACTS);
                setContractsView(contracts.filter((c: Contract) => (new Date(c.endDate)) >= new Date()));
            });
    }, []);

    function viewSelected(selectedView: string) {
        setView(selectedView);
        setContractsOfSelectedView(selectedView);
    }

    function setContractsOfSelectedView(selectedView: string) {
        if (selectedView === ACTIVE_CONTRACTS) {
            setContractsView(allContracts.filter((c: Contract) => (new Date(c.endDate)) >= new Date()))
        } else if (selectedView === EXPIRED_CONTRACTS) {
            setContractsView(allContracts.filter((c: Contract) => (new Date(c.endDate)) < new Date()))
        } else if (selectedView === IN_DEBT) {
            setContractsView(allContracts.filter((c: Contract) => c.debt > 0));
        }
    }


    return (
        <div>
            <Navigation entityId={entityId}/>
            <div className="row">
                <div className="col-xl-6 mx-auto text-center">
                    <div className="section-title">
                        <h4>{t('navBar-Contracts')}</h4>
                    </div>
                </div>
            </div>
            <br/>
            <div>
                <div className="card card-cascade narrower">
                    <div className="row view view-cascade py-3 gradient-card-header info-color-dark d-flex justify-content-between align-items-center" style={{borderRadius: "5px"}}>
                        <div className="col-md-3">
                                <span className={view === ACTIVE_CONTRACTS ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="activeContractsTip">
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            onClick={() => viewSelected(ACTIVE_CONTRACTS)}>
                                        <MDBIcon icon="calendar-check" size="2x"/>
                                    </button>
                                    <ReactTooltip id="activeContractsTip" place="top" effect="solid">
                                            {t('contracts-Show Active Contracts')}
                                    </ReactTooltip>
                                </span>
                            <span className={view === EXPIRED_CONTRACTS ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="expiredContractsTip">
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            onClick={() => viewSelected(EXPIRED_CONTRACTS)}>
                                        <MDBIcon icon="flag-checkered" size="2x"/>
                                    </button>
                                    <ReactTooltip id="expiredContractsTip" place="top" effect="solid">
                                            {t('contracts-Show Expired Contracts')}
                                    </ReactTooltip>
                                </span>
                            <span className={view === IN_DEBT ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="inDebtContractsTip">
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            onClick={() => viewSelected(IN_DEBT)}>
                                        <MDBIcon icon="dollar-sign" size="2x"/>
                                    </button>
                                    <ReactTooltip id="inDebtContractsTip" place="top" effect="solid">
                                            {t('contracts-Show Contracts in Debt')}
                                    </ReactTooltip>
                                </span>

                        </div>

                        <div className="col-md-6 text-center">
                            <h5 className="white-text mx-3">
                                <strong>{t(`contracts-${view}`)}</strong>
                            </h5>
                        </div>

                        <div className="col-md-3 text-right">
                            {
                                permissionService.hasCreateAccess(entityId) &&
                                <>
                                    <Link to={`/entities/${entityId}/contract/new`}>
                                        <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="creteContractTip">
                                            <MDBIcon icon="plus"/> <MDBIcon icon="file-contract" size="2x"/>
                                        </button>
                                        <ReactTooltip id="creteContractTip" place="top" effect="solid">
                                            {t('Create a new Contract')}
                                        </ReactTooltip>
                                    </Link>
                                </>
                            }
                        </div>
                    </div>
                    <ContractsTable contracts={contractsView}/>
                </div>
            </div>
        </div>
    );
}

const ACTIVE_CONTRACTS = "ACTIVE_CONTRACTS";
const EXPIRED_CONTRACTS = "EXPIRED_CONTRACTS";
const IN_DEBT = "IN_DEBT";

type Props = {
    entityId: string;
}