import {handleResponse} from "../_helpers/handle-response";
import {authHeader} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";


export const lookupValuesService = {
    getEntityTypes,
    getCountries,
    getCities,
    getPropertyTypes,
    getCurrencies,
    getPropertyBusinessTypes,
    getPropertyLocationTypes,
    getKitchenTypes,
    getOutsideAreaTypes,
    getAccessTypes,
    getFrequencyTypes
};

function getEntityTypes() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/entityTypes', requestOptions).then(handleResponse);
}

function getCountries() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/countries', requestOptions).then(handleResponse);
}

function getCities(countryId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/lov/countries/${countryId}/cities`, requestOptions).then(handleResponse);
}

function getPropertyTypes() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/propertyTypes', requestOptions).then(handleResponse);
}

function getCurrencies() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/currencies', requestOptions).then(handleResponse);
}

function getPropertyBusinessTypes() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/propertyBusinessTypes', requestOptions).then(handleResponse);
}

function getPropertyLocationTypes() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/propertyLocationTypes', requestOptions).then(handleResponse);
}

function getKitchenTypes() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/kitchenTypes', requestOptions).then(handleResponse);
}

function getOutsideAreaTypes() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/outsideAreaTypes', requestOptions).then(handleResponse);
}

function getAccessTypes() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/accessTypes', requestOptions).then(handleResponse);
}

function getFrequencyTypes() {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + '/lov/frequencyTypes', requestOptions).then(handleResponse);
}

