import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {withTranslation} from "react-i18next";
import {orderFormatter} from "../_helpers/util-functions";
import {getStringDateInCurrentTimeZone} from "../_helpers/date-functions";

class FilesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        }
    }


    render() {
        const {t} = this.props;
        const columns = [{
            dataField: 'id',
            text: 'Id',
            hidden: true
        }, {
            dataField: 'name',
            text: `${t('files-header-Name')}`,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {width: '80%'};
            },
            headerFormatter: orderFormatter
        }, {
            dataField: 'createdAt',
            text: `${t('files-header-Upload Date')}`,
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerStyle: (colum, colIndex) => {
                return {width: '20%'};
            },
            headerFormatter: orderFormatter,
            formatter: (cell, row, rowIndex) => (
                <div>{getStringDateInCurrentTimeZone(cell)}</div>
            )
        }];


        return (
            <>
                {
                    (!this.props.files || this.props.files.length === 0)
                        ? <h3 className="no-data-to-display">{t('No files')}</h3>
                        : <BootstrapTable
                            keyField='id'
                            data={this.props.files || []}
                            columns={columns}
                            rowEvents={this.props.rowEvents}
                            hover={true}
                            bordered={false}/>
                }
            </>

        );
    }
}

export default withTranslation()(FilesTable);

