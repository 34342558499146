import {withTranslation} from "react-i18next";
import NumberFormat from 'react-number-format';
import React from "react";

class MyNumberInput extends React.Component {
    state = {
        value: '',
    };
    render() {
        return (
            <NumberFormat
                isNumericString={true}
                thousandSeparator={'.'}
                decimalSeparator={','}
                allowedDecimalSeparators={[',']}
                value={this.state.value}
                onValueChange={vals => this.setState({ value: vals.formattedValue })}
                {...this.props}
            />
        );
    }
}
export default withTranslation()(MyNumberInput);