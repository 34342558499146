import React from 'react';
import {Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown} from 'reactstrap';

import {authenticationService} from '../_services/authentication.service';
import {entityService} from "../_services";
import {withTranslation} from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import {Link} from "react-router-dom";

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    setIsOpen = (option) => {
        this.setState({
            open: option
        });
    };

    logout() {
        authenticationService.logout();
        window.location.href = "/?logout=true";
    }

    render() {
        const entityId = this.props.entityId || entityService.currentEntityValue;
        const {t} = this.props;
        return (
            <div>
                <Navbar className="navbar-dark bg-dark" light expand="md" fixed="top" style={{"paddingTop": "0px", "paddingBottom": "0px"}}>
                    <NavbarBrand style={{"paddingTop": "0px", "paddingBottom": "0px"}}>
                        <Link to="/">
                            <img
                                alt=""
                                src="/logo.svg"
                                width="70"
                                height="70"
                                className="d-inline-block align-top"
                            />{' '}
                        </Link>
                    </NavbarBrand>
                    <NavbarToggler onClick={() => this.setIsOpen(!this.state.open)}/>
                    <Collapse isOpen={this.state.open} navbar>
                        <Nav navbar>
                            <NavItem>
                                <Link to="/entities/" style={{color: "white"}}>
                                    <NavLink>
                                        {t('navBar-My Koncrete')}
                                    </NavLink>
                                </Link>
                            </NavItem>
                            {
                                authenticationService.currentUserValue && entityId &&
                                <NavItem>
                                    <Link to={"/entities/" + entityId} style={{color: "white"}}>
                                        <NavLink>
                                            {t('navBar-Overview')}
                                        </NavLink>
                                    </Link>
                                </NavItem>
                            }
                            {
                                authenticationService.currentUserValue && entityId &&
                                <NavItem>
                                    <Link to={"/entities/" + entityId + "/properties"} style={{color: "white"}}>
                                        <NavLink>
                                            {t('navBar-Properties')}
                                        </NavLink>
                                    </Link>
                                </NavItem>
                            }
                            {
                                authenticationService.currentUserValue && entityId &&
                                <NavItem>
                                    <Link to={"/entities/" + entityId + "/contracts"} style={{color: "white"}}>
                                        <NavLink>
                                            {t('navBar-Contracts')}
                                        </NavLink>
                                    </Link>
                                </NavItem>
                            }
                            {
                                authenticationService.currentUserValue && entityId &&
                                <NavItem>
                                    <Link to={"/entities/" + entityId + "/tenants"} style={{color: "white"}}>
                                        <NavLink>
                                            {t('navBar-Tenants')}
                                        </NavLink>
                                    </Link>
                                </NavItem>
                            }
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Link to="/#contact" style={{color: "white"}}>
                                    <NavLink>
                                        {t('navBar-Contacts')}
                                    </NavLink>
                                </Link>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    {t('navBar-Account')}
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {
                                        authenticationService.currentUserValue
                                            ? <DropdownItem>{t('navBar-Profile')}</DropdownItem>
                                            : <Link to="/registration">
                                                <DropdownItem>

                                                    {t('navBar-Register')}
                                                </DropdownItem>
                                            </Link>
                                    }
                                    <DropdownItem divider/>
                                    {
                                        authenticationService.currentUserValue
                                            ?
                                            <DropdownItem onClick={() => this.logout()}>{t('navBar-Logout')}</DropdownItem>
                                            : <Link to="/login">
                                                <DropdownItem>

                                                    {t('navBar-Login')}
                                                </DropdownItem>
                                            </Link>
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <LanguageSwitcher/>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

export default withTranslation()(Navigation)