import {handleResponse} from "../_helpers/handle-response";
import {authHeader, jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {addAttributeIfExists, getNumberCleanValue, getPrimitiveCleanValue, getUtcDateCleanValue} from "../_helpers/util-functions";


export const contractService = {
    getAllActive,
    getAll,
    getAllOfProperty,
    getContract,
    getContractOverview,
    createContract,
    updateContract,
    deleteContract,
    uploadFile,
    getFileUrl
};

function getAllActive(entityId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/activeContracts`, requestOptions).then(handleResponse);
}

function getAll(entityId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/contracts`, requestOptions).then(handleResponse);
}

function getAllOfProperty(entityId, propertyId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts`, requestOptions).then(handleResponse);
}

function getContract(entityId, propertyId, contractId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}`, requestOptions).then(handleResponse);
}

function getContractOverview(entityId, propertyId, contractId) {
    const requestOptions = {method: 'GET', headers: authHeader()};
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/overview`, requestOptions).then(handleResponse);
}

function createContract(entityId, propertyId, currencyId, startDate, endDate, startDateRenegotiationPeriod, endDateRenegotiationPeriod, frequencyTypeId,
                        tenants, guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanContractAttributes(
            currencyId, startDate, endDate, startDateRenegotiationPeriod, endDateRenegotiationPeriod, frequencyTypeId, tenants,
            guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts`, requestOptions).then(handleResponse);
}

function updateContract(entityId, propertyId, contractId, currencyId, startDate, endDate, startDateRenegotiationPeriod, endDateRenegotiationPeriod, frequencyTypeId,
                        tenants, guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanContractAttributes(
            currencyId, startDate, endDate, startDateRenegotiationPeriod, endDateRenegotiationPeriod, frequencyTypeId, tenants,
            guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}`, requestOptions).then(handleResponse);
}

function cleanContractAttributes(currencyId, startDate, endDate, startDateRenegotiationPeriod, endDateRenegotiationPeriod, frequencyTypeId, tenants,
                                 guarantee, amount, taxableAmount, extraAmount, extraAmountDescription, taxRetention, taxRetentionPercentage, frequencyValue) {
    let attributes = {currencyId, frequencyTypeId};

    addAttributeIfExists(attributes, "frequencyValue", getNumberCleanValue(frequencyValue));
    addAttributeIfExists(attributes, "amount", getNumberCleanValue(amount));
    addAttributeIfExists(attributes, "taxableAmount", getNumberCleanValue(taxableAmount));
    addAttributeIfExists(attributes, "startDate", getUtcDateCleanValue(startDate));
    addAttributeIfExists(attributes, "endDate", getUtcDateCleanValue(endDate));
    attributes["startDateRenegotiationPeriod"] = getUtcDateCleanValue(startDateRenegotiationPeriod);
    attributes["endDateRenegotiationPeriod"] = getUtcDateCleanValue(endDateRenegotiationPeriod);
    addAttributeIfExists(attributes, "guarantee", getNumberCleanValue(guarantee));
    addAttributeIfExists(attributes, "extraAmount", getNumberCleanValue(extraAmount));
    attributes["extraAmountDescription"] = getPrimitiveCleanValue(extraAmountDescription);
    attributes["taxRetention"] = taxRetention;
    attributes["taxRetentionPercentage"] = getNumberCleanValue(taxRetentionPercentage);
    attributes["tenantsId"] = Array.from(tenants, tenant => tenant.id);
    return attributes;
}

function deleteContract(entityId, propertyId, contractId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}`, requestOptions).then(handleResponse);
}

function uploadFile(entityId, propertyId, contractId, formData) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken()},
        body: formData
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/files`, requestOptions).then(handleResponse);
}

function getFileUrl(entityId, propertyId, contractId, fileId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Authorization': jwtToken()}
    };
    return fetch(SERVER_URL + `/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/files/${fileId}`, requestOptions).then(handleResponse);
}
