import React from 'react';
import {entityService} from "../../../_services";
import {MDBIcon} from "mdbreact";
import './ListEntities.css';
import {successToast, warningToast} from "../../../_helpers/toast-functions";
import {userEntityService} from "../../../_services/userEntity.service";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";

class ListEntities extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            entities: []
        };
        const queryFilters = require('query-string').parse(this.props.location.search);
        if (queryFilters["selected"] == "false") {
            warningToast(`${t('toast-Please select an entity first')}.`);
        }
        if (queryFilters["login"] == "successful") {
            successToast(`${t('toast-Login Successful')}!`);
        }
    }

    componentDidMount() {
        entityService.getAll().then(entities => {
            this.setState({entities});
            const entityAccessMap = Object.fromEntries(
                entities.map(e => [e.id, e.accessType])
            );
            userEntityService.setUserEntityAccessMap(entityAccessMap);
        });
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Navigation/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('entities-My entities')}</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 mx-auto text-right section-title">
                        <Link to="/entity/new">
                            <p className="pt-20"><MDBIcon icon="plus"/> {t('Create Entity')}</p>
                        </Link>
                    </div>
                </div>
                {
                    this.state.entities.length == 0
                        ? <h2 className="no-data-to-display mt-100">{t('entities-You have no entities')}</h2>
                        : <div className="row">
                            {this.state.entities
                                .sort(({id: previousId}, {id: currentId}) => previousId - currentId)
                                .map(function (d, idx) {
                                    return (
                                        <div id="entity-box" key={d.id} className="col-lg-4 col-md-6">
                                            <Link key={d.id} to={`/entities/${d.id}`}>
                                                <div className="single-entity box">
                                                    {
                                                        d.entityType === 'Personal'
                                                            ? <MDBIcon icon="user-alt"/>
                                                            : <MDBIcon icon="suitcase"/>
                                                    }
                                                    <h4>{d.name}</h4>
                                                    <h6>{d.country}</h6>
                                                    <small>{d.countProperties} {t('entities-Properties')}</small><br/><br/>
                                                    <strong>{t('entities-Access')}: {d.accessType}</strong>
                                                    <br/>
                                                    <br/>
                                                    <p style={{"textAlign": "left"}}>{d.description}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                        </div>
                }
            </div>
        )
    }
}

export default withTranslation()(ListEntities)