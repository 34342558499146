
import {handleResponse} from "../_helpers/handle-response";
import {authHeader, jwtToken} from "../_helpers/auth-header";
import {SERVER_URL} from "../App";
import {getNumberCleanValue, getPrimitiveCleanValue} from "../_helpers/util-functions";


export const addressService = {
    createAddress,
    updateAddress,
    getAll
};


function getAll(entityId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(SERVER_URL + `/entities/${entityId}/addresses`, requestOptions).then(handleResponse);
}

function createAddress(entityId, cityId, street, number, floor, mailBox, postalCode, latitude, longitude) {
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanAddressAttributes(cityId, street, number, floor, mailBox, postalCode, latitude, longitude))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/addresses`, requestOptions).then(handleResponse);
}

function updateAddress(entityId, addressId, cityId, street, number, floor, mailBox, postalCode, latitude, longitude) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': jwtToken(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cleanAddressAttributes(cityId, street, number, floor, mailBox, postalCode, latitude, longitude))
    };
    return fetch(SERVER_URL + `/entities/${entityId}/addresses/${addressId}`, requestOptions).then(handleResponse);
}

function cleanAddressAttributes(cityId, street, number, floor, mailBox, postalCode, latitude, longitude) {
    let attributes = {street};

    attributes["cityId"] = getNumberCleanValue(cityId);
    attributes["number"] = getPrimitiveCleanValue(number);
    attributes["floor"] = getPrimitiveCleanValue(floor);
    attributes["mailBox"] = getPrimitiveCleanValue(mailBox);
    attributes["postalCode"] = getPrimitiveCleanValue(postalCode);
    attributes["latitude"] = getNumberCleanValue(latitude);
    attributes["longitude"] = getNumberCleanValue(longitude);

    return attributes;
}
