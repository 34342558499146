import React from 'react';

interface MoneyProps {
    readonly number: number,
    readonly currency: string
}

export const Money = ({number, currency}: MoneyProps) => {
    return <>
        {getNumber(number)} {getCurrencySymbol(currency)}
    </>
}

export function getNumber(value: number) {
    if (!value) {
        return 0
    }
    return roundTwoDecimalCases(value).toLocaleString()
        .replace(',', '?')
        .replace('.', ',')
        .replace('?', '.');
}

function roundTwoDecimalCases(value: number) {
    if (!value) {
        return 0;
    }
    return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function getCurrencySymbol(currency: string) {
    if (!currency) {
        return <>&euro;</>;
    }
    const supportedCurrencies = ["Euro"];
    const isSupportedCurrency = supportedCurrencies.includes(currency);
    return (
        <>
            {
                !isSupportedCurrency && <>{currency}</>
            }
            {
                isSupportedCurrency && currency == supportedCurrencies[0] && <>&euro;</>
            }
        </>
    );
}