import React from 'react';
import './ValidateInvoicePdfDocument.css';
import {PDFDownloadLink} from "@react-pdf/renderer";
import InvoicePdfDocument from "../InvoicePdfDocument/InvoicePdfDocument";
import Button from "react-bootstrap/Button";
import {roundTwoDecimalCases} from "../../../_helpers/util-functions";
import Navigation from "../../../_components/Navigation";

class ValidateInvoicePdfDocument extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const entityId = this.props.match.params.entityId;
        const values = this.props.location.state;
        return (
            <div style={{"overflow-x": "scroll"}}>
                <Navigation entityId={entityId}/>
                <div id="rent">
                    <div className="row">
                        <div id="landlord" className="col-md-4">
                            <p style={{"paddingBottom": "5px"}}>{values.landlordName}</p>
                            <p>{values.landlordAddress}</p>
                            <p>{values.landlordPostalCode}</p>
                            <p>Telefone {values.landlordPhoneNumber}</p>
                            <p>Contribuinte: {values.landlordTaxNumber}</p>
                        </div>
                        <div className="offset-md-4 col-md-4">
                            <div id="rent-data">
                                <p>Original</p>
                                <p>Recibo n° {values.receiptNumber}</p>
                                <p>Pagamento {values.paymentDate}</p>
                                <p>Emissão {values.emissionDate}</p>
                                <p>Recibo válido após boa cobrança</p>
                            </div>
                        </div>
                    </div>
                    <div id="tenant" className="row mt-25">
                        <div className="offset-md-5">
                            <p>{values.tenantName}</p>
                            <p>{values.tenantAddress}</p>
                            <p>{values.tenantPostalCode}</p>
                        </div>
                    </div>
                    <div id="rent-tenant">
                        <p>V. Contribuinte: {values.tenantTaxNumber}</p>
                        <p>Valores relativos a: {values.propertyAddress}</p>
                    </div>
                    <div id="amount" className="row">
                        <div className="col-md-6">
                            <p>Renda de {values.rentPeriod}</p>
                            {values.retainedAmount && <p>Retenção à taxa de 25,00 %</p>}
                        </div>
                        <div id="amount-values" className="col-md-6">
                            <p>{values.rentAmount} €</p>
                            {values.retainedAmount && <p>{values.retainedAmount} €</p>}
                            <div id="total-amount" className="row">
                                <div id="total-label" className="offset-md-5 col-md-3"><b>Total</b></div>
                                <div id="total-value" className="col-md-4"><b>{roundTwoDecimalCases(values.rentAmount) - roundTwoDecimalCases(values.retainedAmount ? values.retainedAmount : 0)} €</b></div>
                            </div>
                            <div id="total-description">{values.amountToPayText}</div>
                        </div>
                    </div>
                    <div id="legal-warning" className="row">
                        <div className="col-md-12">
                            <p>As rendas pagas depois do prazo legal, terão um acréscimo</p>
                            <p>de 50% nos termos do art°. 1041 do Cód Civil (Renda em</p>
                            <p>R.A.U.).</p>
                        </div>
                    </div>
                    <div className="row" style={{"margin-right": "7px"}}>
                        <div id="signature" className="col-md-6 offset-md-6"/>
                    </div>
                </div>
                <PDFDownloadLink document={<InvoicePdfDocument data={values} generate={true}/>}
                                 fileName={values.propertyAddress + " - "  + values.rentPeriod + ".pdf"}>
                    {({blob, url, loading, error}) =>
                        (loading
                                ? 'Loading document...'
                                : <button type="button" className="btn btn-primary">Download</button>
                        )
                    }
                </PDFDownloadLink>
                <Button variant="danger" onClick={this.handleEditInvoicePdf}>Edit</Button>
            </div>
        );
    }

    handleEditInvoicePdf = () => {
        const entityId = this.props.match.params.entityId;
        const propertyId = this.props.match.params.propertyId;
        const contractId = this.props.match.params.contractId;
        const rentId = this.props.match.params.rentId;
        const paymentId = this.props.match.params.paymentId;
        const formValues = this.props.location.state;
        this.props.history.push(`/entities/${entityId}/properties/${propertyId}/contracts/${contractId}/rents/${rentId}/payments/${paymentId}/pdf/create`, formValues);
    }
}

export default ValidateInvoicePdfDocument;
