import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router";
import {MDBIcon} from "mdbreact";
import {permissionService} from "../../../_services/permission.service";
import {ConfirmDeleteProperty} from "./ConfirmDeleteProperty";
import {contractService, propertyService} from "../../../_services";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import {expenseService} from "../../../_services/expense.service";
import {DetailsTab} from "./tabs/DetailsTab";
import {TaxDetailsTab} from "./tabs/TaxDetailsTab";
import PropertiesTable from "../_aux/PropertiesTable";
import {Contract} from "../../Contracts/ListContractsPage/ListContracts";
import {ContractsTable} from "../../Contracts/_aux/ContractsTable";
import {PropertyOverview} from "./PropertyOverview";
import ExpensesTab from "./tabs/ExpensesTab";
import Navigation from "../../../_components/Navigation";
import PropertyFiles from "./PropertyFiles";
import {errorToast, infoToast, successToast} from "../../../_helpers/toast-functions";

interface BaseInfo {
    id: number,
    name: string,
    propertyType: string,
    propertyTypeId: number,
    code: string,
    address: object,
    value: number,
    currencyId: number,
    currency: string
}

interface Finances {
    years: [string],
    revenues: [number],
    expenses: [number]
}

export interface Property {
    baseInfo: BaseInfo,
    details: Object,
    taxInformation: Object,
    parent: BaseInfo,
    finances: Finances,
    activeContractId: number
}

export interface PropertyOverviewI {
    entityId: string,
    currency: string,
    expensesAmountHierarchy: number,
    expensesAmountYearHierarchy: number,
    contractAmountHierarchy: number,
    contractNetAmountHierarchy: number
    valueHierarchy: number
}

export interface ActiveContract {
    id: number,
    currencyId: string,
    currency: string,
    amount: number,
    extraAmount: number,
    amountAfterTaxes: number,
    startDate: Date,
    endDate: Date
}

export interface Expense {
    amount: number,
    date: Date
}

const OVERVIEW = "Overview";
const DETAILS = "Details";
const INNER_PROPERTIES = "Inner Properties";
const CONTRACTS = "Contracts";

export const Property = () => {
    const match = useRouteMatch<Props>();
    const entityId = match.params.entityId;
    const propertyId = match.params.propertyId;

    const {t} = useTranslation();
    const history = useHistory();

    const [property, setProperty] = useState<Property>();
    const [files, setFiles] = useState([]);
    const [contract, setContract] = useState<ActiveContract>();
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [expenses, setExpenses] = useState<Expense[]>([]);
    const [children, setChildren] = useState<PropertyOverviewI[]>([]);
    const [view, setView] = useState(OVERVIEW);

    const filesDivRef = useRef<HTMLInputElement>(null);


    React.useEffect(() => {
        propertyService.getPropertyOverview(entityId, propertyId)
            .then(p => {
                setProperty(p);
                setFiles(p.files);
                setView(OVERVIEW);
                if (p.activeContractId) {
                    contractService.getContractOverview(entityId, propertyId, p.activeContractId)
                        .then(contractResponse => setContract(contractResponse));
                } else {
                    setContract(undefined);
                }
            });
        propertyService.getAll(entityId, propertyId)
            .then(response => {
                response.forEach((p: PropertyOverviewI) => p.entityId = entityId);
                setChildren(response);
            });
        expenseService.getAllOfProperty(entityId, propertyId).then(expensesResponse => {
            setExpenses(expensesResponse);
        });
        contractService.getAllOfProperty(entityId, propertyId).then(contractsResponse => {
            for (let i = 0; i < contractsResponse.length; i++) {
                contractsResponse[i].entityId = entityId;
            }
            setContracts(contractsResponse);
        });
    }, [entityId, propertyId]);

    const fileChangeHandler = (event: any) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 10000000) {
                errorToast(`${t('toast-File cannot have more than 10 MB')}.`);
            } else {
                const formData = new FormData();
                formData.append('file', selectedFile);
                infoToast(`${t('toast-Uploading')}.`);
                propertyService.uploadFile(entityId, propertyId, formData)
                    .then(response => {
                            successToast(`${t('toast-File uploaded successfully')}.`);
                            if (response && response.length > 0) {
                                setFiles(response);
                            }
                            if (filesDivRef && filesDivRef.current) {
                                filesDivRef.current.scrollIntoView();
                            }
                        },
                        error => {
                            console.log(error);
                            errorToast(`${t('toast-Error while uploading file')}.`);
                        });
            }
        }
    };

    const triggerUploadButtonClicked = () => {
        // @ts-ignore
        document.querySelector("input[name='propFile']").click();
    };

    return (
        <div>
            <div className="text-center">
                <Navigation entityId={entityId}/>
                <div className="section-title">
                    <h4>
                        {
                            property?.baseInfo.name
                                ? property?.baseInfo.name
                                : `${t(`properties-${property?.baseInfo.propertyType.toUpperCase()}`)}`
                        }
                    </h4>
                </div>
                {
                    permissionService.hasEditAccess(entityId) &&
                    <Link to={`/entities/${entityId}/properties/${propertyId}/edit`}
                          style={{marginLeft: "10px", marginRight: "10px"}}>
                        <MDBIcon icon="pen"/>
                    </Link>
                }
                {
                    permissionService.hasDeleteAccess(entityId) &&
                    <ConfirmDeleteProperty history={history} ids={match.params}/>
                }
            </div>
            <br/>
            <div className="card card-cascade narrower">
                <div className="row view view-cascade py-3 gradient-card-header info-color-dark d-flex justify-content-between align-items-center white-text"
                     style={{borderRadius: "5px"}}>
                    <div className="col-md-4">
                        <span className={view === OVERVIEW ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="overviewTip">
                            <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                    onClick={() => setView(OVERVIEW)}>
                                <MDBIcon icon="chart-bar" size="2x"/>
                            </button>
                            <ReactTooltip id="overviewTip" place="top" effect="solid">
                               {t('property-Overview')}
                            </ReactTooltip>
                        </span>
                        <span className={view === DETAILS ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="detailsTip">
                            <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                    onClick={() => setView(DETAILS)}>
                                <MDBIcon icon="search" size="2x"/>
                            </button>
                            <ReactTooltip id="detailsTip" place="top" effect="solid">
                               {t('property-Details')}
                            </ReactTooltip>
                        </span>
                        {
                            children.length > 0 &&
                            <span className={view === INNER_PROPERTIES ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="innerPropertiesTip">
                                <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                        onClick={() => setView(INNER_PROPERTIES)}>
                                    <MDBIcon icon="sitemap" size="2x"/>
                                </button>
                                <ReactTooltip id="innerPropertiesTip" place="top" effect="solid">
                                   {t('property-Inner Properties')}
                                </ReactTooltip>
                            </span>
                        }
                        {
                            contracts.length > 0 &&
                            <span className={view === CONTRACTS ? "selected" : ""} style={{display: "inline-block"}} data-tip data-for="contractsTip">
                                <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                        onClick={() => setView(CONTRACTS)}>
                                    <MDBIcon icon="file-invoice-dollar" size="2x"/>
                                </button>
                                <ReactTooltip id="contractsTip" place="top" effect="solid">
                                   {t('property-Contracts')}
                                </ReactTooltip>
                            </span>
                        }
                    </div>
                    <div className="col-md-3 text-center">
                        {t(`property-${view}`)}
                    </div>
                    <div className="col-md-5 text-right">
                        {
                            permissionService.hasCreateAccess(entityId) &&
                            <>
                                <>
                                    <input type="file" name="propFile" onChange={fileChangeHandler} hidden={true}/>
                                    <button onClick={triggerUploadButtonClicked} type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square"
                                            data-tip data-for="uploadFileTip">
                                        <MDBIcon icon="upload" size="2x"/>
                                    </button>
                                    <ReactTooltip id="uploadFileTip" place="top" effect="solid">
                                        {t('Upload file')}
                                    </ReactTooltip>
                                </>
                                <Link to={`/entities/${entityId}/property/new?parent=${propertyId}`}>
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="cretePropertyTip">
                                        <MDBIcon icon="plus"/> <MDBIcon icon="home" size="2x"/>
                                    </button>
                                    <ReactTooltip id="cretePropertyTip" place="top" effect="solid">
                                        {t('Create Inner Property')}
                                    </ReactTooltip>
                                </Link>
                                <Link to={`/entities/${entityId}/contract/new?property=${propertyId}`}>
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="creteContractTip">
                                        <MDBIcon icon="plus"/> <MDBIcon icon="file-contract" size="2x"/>
                                    </button>
                                    <ReactTooltip id="creteContractTip" place="top" effect="solid">
                                        {t('Create a new Contract')}
                                    </ReactTooltip>
                                </Link>
                                <Link to={`/entities/${entityId}/expense/new?propertyId=${propertyId}`}>
                                    <button type="button" className="btn btn-outline-white btn-rounded btn-sm px-2 rounded-circle square" data-tip data-for="creteExpenseTip">
                                        <MDBIcon icon="plus"/> <MDBIcon icon="dollar-sign" size="2x"/>
                                    </button>
                                    <ReactTooltip id="creteExpenseTip" place="top" effect="solid">
                                        {t('Create a new Expense')}
                                    </ReactTooltip>
                                </Link>
                            </>
                        }
                    </div>
                </div>
                <div className="card-body">
                    {
                        view === OVERVIEW &&
                        <PropertyOverview
                            entityId={entityId}
                            propertyId={propertyId}
                            property={property}
                            contract={contract}
                            children={children}
                            expenses={expenses}/>
                    }
                    {
                        view === DETAILS &&
                        <>
                            <DetailsTab details={property?.details || {}}/>
                            <TaxDetailsTab taxInformation={property?.taxInformation || {}}/>
                        </>
                    }
                    {
                        view === INNER_PROPERTIES &&
                        <PropertiesTable
                            properties={children}
                            entityId={entityId}
                            history={history}/>
                    }
                    {
                        view === CONTRACTS &&
                        <ContractsTable contracts={contracts}/>
                    }
                </div>
            </div>
            <br/><br/>
            <ExpensesTab entityId={entityId}
                         propertyId={propertyId}
                         expenses={expenses}
                         history={history}/>

            <div ref={filesDivRef}>
                <PropertyFiles
                    files={files}
                    entityId={entityId}
                    propertyId={propertyId}
                    history={history}
                />
            </div>

        </div>
    );
}


type Props = {
    entityId: string;
    propertyId: string;
}