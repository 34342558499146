import React from 'react';
import {expenseService} from "../../../_services/expense.service";
import ExpenseForm from "../_aux/ExpenseForm";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

export const EDIT_EXPENSE = "EditExpense";

class EditExpense extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                amount: '',
                name: '',
                description: '',
                includeInYield: false
            },
            expenseDate: null
        };
    }

    componentDidMount() {
        const entityId = this.props.match.params.entityId;
        const expenseId = this.props.match.params.expenseId;

        expenseService.getExpense(entityId, expenseId).then(response => {
            let initialValues = {};
            initialValues.amount = response.amount;
            initialValues.name = response.name;
            initialValues.description = response.description;
            initialValues.includeInYield = response.includeInYield;
            this.setState({initialValues: initialValues});
            this.setState({currencyId: response.currencyId});
            this.setState({propertyId: response.propertyId});
            this.setState({expenseDate: response.date});
        });
    }

    render() {
        const {t} = this.props;
        const entityId = this.props.match.params.entityId;

        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-12 col-md-12 text-center">
                        <div className="section-title">
                            <h4>{t('expenseForm-Edit Expense')}</h4>
                        </div>
                    </div>
                </div>
                <ExpenseForm
                    entityId={this.props.match.params.entityId}
                    propertyId={this.state.propertyId}
                    expenseId={this.props.match.params.expenseId}
                    history={this.props.history}
                    initialValues={this.state.initialValues}
                    currencyId={this.state.currencyId}
                    expenseDate={this.state.expenseDate}
                    page={EDIT_EXPENSE}/>
            </div>
        );
    }
}

export default withTranslation()(EditExpense);