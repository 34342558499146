import React from 'react';
import {entityService, lookupValuesService} from "../../../_services";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {validationSchema} from "../validation";
import {successToast} from "../../../_helpers/toast-functions";
import {withTranslation} from "react-i18next";
import Navigation from "../../../_components/Navigation";

class EditEntity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            entityTypes: [],
            country: null,
            entityType: null,
            entity: {undefined}
        };
    }

    changeCountry = (event) => {
        this.setState({
            country: event.target.value
        });
    };

    changeEntityType = (event) => {
        this.setState({
            entityType: event.target.value
        });
    };

    componentDidMount() {
        const entityId = this.props.match.params.entityId;
        entityService.getEntity(entityId).then(response => {
            this.setState({entity: response});
            this.setState({entityType: response.entityType});
            this.setState({country: response.country});
        });
        lookupValuesService.getCountries().then(response => this.setState({countries: response}));
        lookupValuesService.getEntityTypes().then(response => this.setState({entityTypes: response}));
    }

    render() {
        const entityId = this.props.match.params.entityId;
        const {t} = this.props;
        return (
            <div className="entity-form box">
                <Navigation entityId={entityId}/>
                <div className="row">
                    <div className="col-xl-6 mx-auto text-center">
                        <div className="section-title">
                            <h4>{t('entityForm-Edit Entity')}</h4>
                        </div>
                    </div>
                </div>
                <Formik enableReinitialize
                        initialValues={{
                            name: this.state.entity.name || '',
                            legalName: this.state.entity.legalName || '',
                            description: this.state.entity.description || '',
                            financeNumber: this.state.entity.financeNumber || '',
                            phone: this.state.entity.phone || '',
                            address: this.state.entity.address || '',
                            postalCode: this.state.entity.postalCode || ''
                        }}
                        validationSchema={Yup.object().shape(validationSchema)}
                        onSubmit={({name, legalName, description, financeNumber, phone, address, postalCode}, {setStatus, setSubmitting}) => {
                            if (this.state.entityType == null) {
                                setSubmitting(false);
                                const error = {"entityType": "Cannot be empty"};
                                setStatus(error);
                            } else {
                                setStatus();
                                let countryId = null;
                                if (this.state.country != null) {
                                    countryId = this.state.country.id;
                                }
                                entityService.updateEntity(this.props.match.params.entityId, name, this.state.entityType.id, legalName, financeNumber, countryId, description, phone, address, postalCode)
                                    .then(
                                        response => {
                                            successToast(`${t('toast-Entity successfully updated')}!`);
                                            this.props.history.push('/entities/' + this.props.match.params.entityId);
                                        },
                                        error => {
                                            setSubmitting(false);
                                            setStatus(error);
                                        }
                                    );
                            }
                        }}
                        render={({errors, status, touched, isSubmitting}) => (
                            <Form>
                                <div className="form-group required">
                                    <label htmlFor="name" className="control-label">{t('entityForm-Name')}</label>
                                    <Field name="name" type="text" checked={this.state.entity.name}
                                           className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                                    {status && status.name &&
                                    <div className="text-danger invalid-input">
                                        Name {status.name}
                                    </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="legalName">{t('entityForm-Legal Name')}</label>
                                    <Field name="legalName" type="text"
                                           className={'form-control' + (errors.legalName && touched.legalName ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="legalName" component="div" className="invalid-feedback"/>
                                    {status && status.legalName &&
                                    <div className="text-danger invalid-input">
                                        Name {status.legalName}
                                    </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="entityType">{t('entityForm-Entity Type')}</label> <br/>
                                    <ComboBox
                                        data={this.state.entityTypes}
                                        textField="name"
                                        dataItemKey="id"
                                        value={this.state.entityType}
                                        onChange={this.changeEntityType}
                                    />
                                    <ErrorMessage name="legalName" component="div" className="invalid-feedback"/>
                                    {status && status.entityType &&
                                    <div className="text-danger invalid-input">{status.entityType}</div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="financeNumber">{t('entityForm-Finance Number')}</label>
                                    <Field name="financeNumber" type="text"
                                           className={'form-control' + (errors.financeNumber && touched.financeNumber ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="financeNumber" component="div" className="invalid-feedback"/>
                                    {status && status.financeNumber &&
                                    <div className="text-danger invalid-input">
                                        Name {status.financeNumber}
                                    </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="country">{t('entityForm-Country')}</label> <br/>
                                    <ComboBox
                                        data={this.state.countries}
                                        textField="name"
                                        dataItemKey="id"
                                        value={this.state.country}
                                        onChange={this.changeCountry}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone">{t('entityForm-Phone Number')}</label>
                                    <Field name="phone" type="text"
                                           className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                    {status && status.phone &&
                                    <div className="text-danger invalid-input">
                                        Phone Number {status.phone}
                                    </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="address">{t('entityForm-Address')}</label>
                                    <Field name="address" type="text"
                                           className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="address" component="div" className="invalid-feedback"/>
                                    {status && status.address &&
                                    <div className="text-danger invalid-input">
                                        Address {status.address}
                                    </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="postalCode">{t('entityForm-Postal Code')}</label>
                                    <Field name="postalCode" type="text"
                                           className={'form-control' + (errors.postalCode && touched.postalCode ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="postalCode" component="div" className="invalid-feedback"/>
                                    {status && status.postalCode &&
                                    <div className="text-danger invalid-input">
                                        Postal Code {status.postalCode}
                                    </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">{t('entityForm-Description')}</label>
                                    <Field name="description" component="textarea"
                                           className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')}/>
                                    <ErrorMessage name="description" component="div" className="invalid-feedback"/>
                                    {status && status.description &&
                                    <div className="text-danger invalid-input">
                                        Name {status.description}
                                    </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Update
                                    </button>
                                    {isSubmitting &&
                                    <img
                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                    }
                                </div>
                                {status && status.message &&
                                <div>
                                    <div className={'alert alert-danger'}>{status.message}</div>
                                </div>
                                }
                            </Form>
                        )}
                />
            </div>
        )
    }
}

export default withTranslation()(EditEntity);
