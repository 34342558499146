import React from 'react';
import {ErrorMessage, Field} from "formik";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import Collapse from "react-bootstrap/Collapse";
import * as Yup from "yup";
import {createEventWithName} from "../../../../_helpers/event-functions";
import {withTranslation} from "react-i18next";


class TaxInformationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setBusinessTypes: false,
            setLocationType: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let setBusinessTypesValue = {setBusinessTypes: prevState.setBusinessTypes};
        let setLocationTypeValue = {setLocationType: prevState.setLocationType};

        if (!prevState.setBusinessTypes) {
            setBusinessTypesValue = {setBusinessTypes: setBusinessTypes(nextProps)};
        }
        if (!prevState.setLocationType) {
            setLocationTypeValue = {setLocationType: setLocationType(nextProps)};
        }

        return ({...setBusinessTypesValue, ...setLocationTypeValue});
    }

    render() {
        const {t} = this.props;
        return (
            <Collapse in={!this.props.collapseTaxInformation}>
                <div id="base-info-div">

                    <div className="form-group">

                        <label htmlFor="businessType">{t('propertyForm-Business Type')}</label>
                        <br/>
                        <ComboBox
                            name="businessType"
                            data={this.props.state.businessTypes}
                            textField="name"
                            dataItemKey="id"
                            value={this.props.state.businessType}
                            onChange={this.props.handleChange}
                            filterable={true}
                            onFilterChange={this.props.handleFilterChange}
                        />
                        <ErrorMessage name="businessType" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.propertyBusinessTypeId &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Business Type')} {this.props.status.propertyBusinessTypeId}
                            </div>

                        }
                        {
                            this.props.status && this.props.status.propertyBusinessTypeId &&
                            <div className="text-danger invalid-input">{this.props.status.propertyBusinessTypeId}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="locationType">{t('propertyForm-Location Type')}</label> <br/>
                        <ComboBox
                            name="locationType"
                            data={this.props.state.locationTypes}
                            textField="name"
                            dataItemKey="id"
                            value={this.props.state.locationType}
                            filterable={true}
                            onFilterChange={this.props.handleFilterChange}
                            onChange={this.props.handleChange}
                        />
                        <ErrorMessage name="locationType" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.locationTypeId &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Location Type')} {this.props.status.locationTypeId}
                            </div>
                        }
                        {
                            this.props.status && this.props.status.locationType &&
                            <div className="text-danger invalid-input">{this.props.status.locationType}</div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="locationCode">{t('propertyForm-Location Code')}</label>
                        <Field name="locationCode" type="text"
                               className={'form-control' + (this.props.errors.locationCode && this.props.touched.locationCode ? ' is-invalid' : '')}/>
                        <ErrorMessage name="locationCode" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.locationCode &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Location Code')} {this.props.status.locationCode}
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="articleCode">{t('propertyForm-Article Code')}</label>
                        <Field name="articleCode" type="text"
                               className={'form-control' + (this.props.errors.articleCode && this.props.touched.articleCode ? ' is-invalid' : '')}/>
                        <ErrorMessage name="articleCode" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.articleCode &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Article Code')} {this.props.status.articleCode}
                            </div>
                        }
                    </div>

                    <div className="form-group smallInput">
                        <label htmlFor="fractionCode">{t('propertyForm-Fraction Code')}</label>
                        <Field name="fractionCode" type="text"
                               className={'form-control' + (this.props.errors.fractionCode && this.props.touched.fractionCode ? ' is-invalid' : '')}/>
                        <ErrorMessage name="fractionCode" component="div" className="invalid-feedback"/>
                        {
                            this.props.status && this.props.status.fractionCode &&
                            <div className="text-danger invalid-input">
                                {t('propertyForm-Fraction Code')} {this.props.status.fractionCode}
                            </div>
                        }
                    </div>
                </div>
            </Collapse>
        );
    }
}

export default withTranslation()(TaxInformationForm);

export const taxInfoValidationSchema = {
    locationCode: Yup.string()
        .max(30, 'Cannot contain more than 30 characters'),
    articleCode: Yup.string()
        .max(30, 'Cannot contain more than 30 characters'),
    fractionCode: Yup.string()
        .max(30, 'Cannot contain more than 30 characters'),
};

function setBusinessTypes(prop) {
    if (prop.state.businessType || !prop.propertyBusinessTypeId || !prop.state.allBusinessTypes.length) {
        return false;
    }
    for (const bt of prop.state.allBusinessTypes) {
        if (bt.id === prop.propertyBusinessTypeId) {
            prop.handleChange(createEventWithName(bt, "businessType"));
            return true;
        }
    }
    return false;
}

function setLocationType(prop) {
    if (prop.state.locationType || !prop.propertyLocationTypeId || !prop.state.allLocationTypes.length) {
        return false;
    }
    for (const alt of prop.state.allLocationTypes) {
        if (alt.id === prop.propertyLocationTypeId) {
            prop.handleChange(createEventWithName(alt, "locationType"));
            return true;
        }
    }
    return false;
}
