import React from 'react';

export function PropertyImage(params) {
    if(!params.propertyType) {
        return <></>;
    }
    let image = `/propertyType/${params.propertyType.toLowerCase()}.svg`;
    return (
        <img
            alt=""
            src= {image}
            className="d-inline-block align-top"
            style={{maxWidth: "100%", maxHeight: "100%"}}
        />
    );
}
