import React from 'react';

export class TaxDetailsTab extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const tax = this.props.taxInformation;
        return (
            <div className="tab-content entity-overview-tab" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel"
                     aria-labelledby="home-tab">

                    <div className="row">
                        <div className="col-md-4">
                            <p>Business Type</p>
                        </div>
                        <div className="col-md-8">
                            {tax.propertyBusinessType}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <p>Location Type</p>
                        </div>
                        <div className="col-md-8">
                            {tax.propertyLocationType}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <p>Location Code</p>
                        </div>
                        <div className="col-md-8">
                            {tax.locationCode}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <p>Article Code</p>
                        </div>
                        <div className="col-md-8">
                            {tax.articleCode}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <p>Fraction Code</p>
                        </div>
                        <div className="col-md-8">
                            {tax.fractionCode}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

/*
<div label="Details" className="tab-content">
                            <div className="tab-content entity-overview-tab" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel"
                                     aria-labelledby="home-tab">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p>Entity Type</p>
                                        </div>
                                        <div className="col-md-8">
                                            type
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p>Legal Name</p>
                                        </div>
                                        <div className="col-md-8">
                                            legalName
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p>Finance Number</p>
                                        </div>
                                        <div className="col-md-8">
                                            FinanceNumber
                                        </div>
                                    </div>
                                    <a href="id/properties">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <p>
                                                    <MDBIcon icon="angle-right"/> Properties (Eligible for
                                                    Contracts)
                                                </p>
                                            </div>
                                            <div className="col-md-8">
                                                888
                                            </div>
                                        </div>
                                    </a>
                                    <a href="2/contracts">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <p>
                                                    <MDBIcon icon="angle-right"/> Active Contracts
                                                </p>
                                            </div>
                                            <div className="col-md-8">
                                                asdasd
                                            </div>
                                        </div>
                                    </a>
                                    <a href="1/tenants">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <p>
                                                    <MDBIcon icon="angle-right"/> Tenants
                                                </p>
                                            </div>
                                            <div className="col-md-8">
                                                adsad
                                            </div>
                                        </div>
                                    </a>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p>Description</p>
                                        </div>
                                        <div className="col-md-8">
                                            sdfsdf
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
 */
