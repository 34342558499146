import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';

import * as Yup from 'yup';

import {authenticationService} from '../../../_services/authentication.service';
import {infoToast} from "../../../_helpers/toast-functions";
import '../Authetication.css';
import {Link} from "react-router-dom";
import Navigation from "../../../_components/Navigation";
import {withTranslation} from "react-i18next";

class Login extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            infoToast(`${t('toast-You are already logged in')}.`);
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div className="auth-div">
                <Navigation/>
                <img className="auth-background-image" src="/background-image-street-light.jpg" alt=""/>
                <div className="auth-content">
                    <div className="auth-container box">
                        <div className="col-md-5 ml-auto mr-auto">
                            <div className="auth-card box card-plain">
                                <Form className="form" method="" action="">
                                    <div className="card-header text-center">
                                        <div className="auth-logo-container">
                                            <img src="/logo.svg"/>
                                            <h2><b>Koncrete</b></h2>
                                        </div>
                                    </div>
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: ''
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string().required('Email is required').email('Must be a valid email'),
                                            password: Yup.string().required('Password is required')
                                        })}
                                        onSubmit={({email, password}, {setStatus, setSubmitting}) => {
                                            setStatus();
                                            authenticationService.login(email, password)
                                                .then(
                                                    user => {
                                                        window.location.href = "/entities?login=successful";
                                                    },
                                                    error => {
                                                        setSubmitting(false);
                                                        setStatus(error.message);
                                                    }
                                                );
                                        }}
                                        render={({errors, status, touched, isSubmitting}) => (
                                            <Form className="form" method="" action="">
                                                <div>
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <Field name="email" type="text"
                                                                   placeholder="Email"
                                                                   className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                                            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <Field name="password" type="password"
                                                                   leftIcon="camera-retro"
                                                                   placeholder="Password"
                                                                   className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                                            <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                                                        </div>
                                                    </div>


                                                    <div className="card-footer auth-card-footer">
                                                        <button type="submit" className="btn btn-primary btn-round btn-lg btn-block" disabled={isSubmitting}>
                                                            Login
                                                        </button>
                                                        {
                                                            isSubmitting &&
                                                            <img
                                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                                        }
                                                        {status &&
                                                        <div className={'alert alert-danger'}>{status}</div>
                                                        }

                                                        <div className="row auth-alternatives">
                                                            <div className="col-md-6 auth-opt-left">
                                                                <b><Link to="/registration" style={{"color": "white"}}>Create an Account</Link></b>
                                                            </div>
                                                            <div className="col-md-6 auth-opt-right">
                                                                <b><Link to="/requestResetPassword" style={{"color": "white"}}>Forgot Password</Link></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Login);