import React from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import {orderFormatter} from "../../../_helpers/util-functions";
import {useTranslation} from "react-i18next";
import {Money} from "../../../_helpers/NumberUtils";
import {getStringDateInCurrentTimeZone} from "../../../_helpers/date-functions";
import {MDBIcon} from "mdbreact";
import {permissionService} from "../../../_services/permission.service";
import {Link} from "react-router-dom";
import {ConfirmDeleteExpense} from "../../Properties/PropertyPage/tabs/ConfirmDeleteExpense";
import ReactTooltip from "react-tooltip";
import {errorToast, infoToast, successToast} from "../../../_helpers/toast-functions";
import {expenseService} from "../../../_services/expense.service";

function ExpensesTable(params) {
    const {t} = useTranslation();

    let entityId = params.entityId;
    let propertyId = params.propertyId;
    let expenses = params.expenses;
    let history = params.history;

    const expenseFileChangeHandler = (event, expenseId) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 10000000) {
                errorToast(`${t('toast-File cannot have more than 10 MB')}.`);
            } else {
                const formData = new FormData();
                formData.append('file', selectedFile);
                infoToast(`${t('toast-Uploading')}.`);
                expenseService.uploadFile(entityId, expenseId, formData)
                    .then(response => {
                            successToast(`${t('toast-File uploaded successfully')}.`);
                            if (response && response.length > 0) {
                                // setFiles(response);
                                expenses.forEach(expense => {
                                    if (expense.id === expenseId) {
                                        expense.files = response;
                                    }
                                });
                                document.querySelector("td[class='expand-cell']").click();
                                document.querySelector("td[class='expand-cell']").click();
                            }
                        },
                        error => {
                            console.log(error);
                            errorToast(`${t('toast-Error while uploading file')}.`);
                        });
            }
        }
    };

    const triggerExpenseUploadButtonClicked = () => {
        // @ts-ignore
        document.querySelector("input[name='expFile']").click();
    };

    function onClickExpense(expenseId, fileId) {
        expenseService.getFileUrl(entityId, expenseId, fileId)
            .then(response => {
                    window.open(response.url);
                },
                error => {
                    errorToast(`${t('toast-Error while getting file')}.`);
                });
    }

    const expandExpenseRow = {
        renderer: row => (
            <> {
                (!row.files.length || row.files.length === 0)
                    ? <div className="ml-5">{t('No files')}</div>
                    : <>
                        {row.files.sort(function(a, b){ return new Date(b.createdAt) - new Date(a.createdAt) })
                            .map(file =>
                            <div className="mb-4">
                                <a onClick={e => onClickExpense(row.id, file.id)}>
                                    <em className="ml-5">{file.name}</em>
                                    <label className="ml-5"><strong>({getStringDateInCurrentTimeZone(file.createdAt)})</strong></label>
                                </a>
                            </div>
                        )}
                        <br/>
                        <br/>
                    </>
            }
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({isAnyExpands}) => {
            return <b></b>;
        },
        expandColumnRenderer: ({expanded}) => {
            if (expanded) {
                return (
                    <MDBIcon icon="angle-up"/>
                );
            }
            return (
                <MDBIcon icon="angle-down"/>
            );
        }
    };

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            hidden: true
        },
        {
            dataField: 'date',
            text: `${t('property-Expenses-Table-Date')}`,
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerFormatter: orderFormatter,
            formatter: (cell, row, rowIndex) => (
                <div>{getStringDateInCurrentTimeZone(cell)}</div>
            ),
            headerStyle: (colum, colIndex) => {
                return {width: '100px', minWidth: '100px'};
            }
        },
        {
            dataField: 'amount',
            text: `${t('property-Expenses-Table-Amount')}`,
            sort: true,
            align: 'center',
            headerAlign: 'center',
            headerStyle: (colum, colIndex) => {
                return {width: '120px', minWidth: '120px'};
            },
            headerFormatter: orderFormatter,
            formatter: (cell, row, rowIndex) => (
                <div>
                    {
                        cell && <Money number={cell} currency={row.currency.name}/>
                    }
                </div>

            )
        },
        {
            dataField: 'name',
            text: `${t('property-Expenses-Table-Name')}`,
            sort: true,
            headerAlign: 'center',
            headerStyle: (colum, colIndex) => {
                return {width: '130px', minWidth: '130px'};
            },
            headerFormatter: orderFormatter
        },
        {
            dataField: 'includeInYield',
            text: `${t('property-Expenses-Table-Include In Yield')}`,
            sort: true,
            hidden: params.hideYieldColumn,
            align: 'center',
            headerAlign: 'center',
            headerStyle: (colum, colIndex) => {
                return {width: '130px', minWidth: '130px'};
            },
            headerFormatter: orderFormatter,
            formatter: (cell) => (
                <>
                    {
                        cell
                            ? <MDBIcon icon="check" style={{color: 'darkgreen'}}/>
                            : <MDBIcon icon="times" style={{color: 'darkred'}}/>
                    }
                </>
            )
        },
        {
            dataField: 'description',
            text: `${t('property-Expenses-Table-Description')}`,
            sort:
                true,
            headerAlign:
                'center',
            headerStyle:
                (colum, colIndex) => {
                    return {width: '400px', minWidth: '130px'};
                },
            headerFormatter:
            orderFormatter
        },
        {
            dataField: '',
            text: '',
            headerStyle:
                (colum, colIndex) => {
                    return {width: '60px', minWidth: '60px'};
                },
            formatter:
                (cell, row, rowIndex) => (
                    <>
                        <div align="center" style={{marginBottom: "5px"}}>
                            <input type="file" name="expFile" onChange={event => expenseFileChangeHandler(event, row.id)} hidden={true}/>
                            <MDBIcon onClick={triggerExpenseUploadButtonClicked} icon="upload" size="1x" data-tip data-for="uploadExpenseFileTip"/>
                            <ReactTooltip id="uploadExpenseFileTip" place="top" effect="solid">
                                {t('Upload file')}
                            </ReactTooltip>
                        </div>
                        <div align="center">
                            {
                                permissionService.hasEditAccess(entityId) &&
                                <Link to={`/entities/${entityId}/expenses/${row.id}`} style={{marginRight: "15px"}}><MDBIcon icon="pen" size="1px"/></Link>
                            }
                            {
                                permissionService.hasDeleteAccess(entityId) &&
                                <ConfirmDeleteExpense entityId={entityId} propertyId={propertyId} expenseId={row.id} history={history}/>
                            }
                        </div>
                    </>
                )
        }
    ];


    return (
        <div>
            {
                expenses && expenses.length > 0
                    ? <BootstrapTable
                        condensed={true}
                        keyField='id'
                        data={expenses}
                        columns={columns}
                        expandRow={expandExpenseRow}
                        hover={true}
                        bordered={false}
                        defaultSorted={[{dataField: 'date', order: 'desc'}]}
                        wrapperClasses={"table-responsive"}/>
                    : <>
                        {
                            params.emptyMessage &&
                            params.emptyMessage.split(". ").map(message => {
                                return <h3 className="no-data-to-display">{message}</h3>
                            })
                        }

                    </>
            }
        </div>
    );
}


export default ExpensesTable;
