import React from 'react';
import {Redirect, Route} from 'react-router-dom';

import {authenticationService} from '../_services';
import {errorToast} from "../_helpers/toast-functions";

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            errorToast("Login is required to access this page!");
            return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
        }

        // authorised so return component
        return <Component {...props} />
    }}/>
)
