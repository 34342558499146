import React from 'react';
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {addressService} from "../../../_services/address.service";
import {filterBy} from '@progress/kendo-data-query';
import {lookupValuesService, propertyService} from "../../../_services";
import {getMapOfStringProperties} from "./properties-functions";
import './PropertyForm.css';
import BaseInformationForm, {baseInfoValidationSchema} from "./formSections/BaseInformationForm";
import TaxInformationForm, {taxInfoValidationSchema} from "./formSections/TaxInformationForm";
import DetailedInformationForm, {detailedInfoValidationSchema} from "./formSections/DetailedInformationForm";
import {CREATE_PROPERTY} from "../CreatePropertyPage/CreateProperty";
import {EDIT_PROPERTY} from "../EditPropertyPage/EditProperty";
import {successToast} from "../../../_helpers/toast-functions";
import {addressValidationSchema} from "./formSections/AddressForm";
import {withTranslation} from "react-i18next";

class PropertyForm extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props;
        this.state = {
            addressId: null,
            properties: [],
            allProperties: [],
            parentProperty: null,
            parentPropertyUpdated: false,
            propertyTypes: [],
            allPropertyTypes: [],
            propertyType: null,
            currencies: [],
            allCurrencies: [],
            currency: null,

            businessTypes: [],
            allBusinessTypes: [],
            businessType: null,
            locationTypes: [],
            allLocationTypes: [],
            locationType: null,

            kitchenTypes: [],
            allKitchenTypes: [],
            kitchenType: null,
            outsideAreaTypes: [],
            allOutsideAreaTypes: [],
            outsideAreaType: null,

            yesNoMap: [{display: "", value: null}, {display: `${t('Yes')}`, value: true}, {display: `${t('No')}`, value: false}],

            collapseBaseInformation: false,
            collapseDetailedInformation: true,
            showDetailedInformation: false,
            collapseTaxInformation: true
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return ({addressId: nextProps.address ? nextProps.address.id : null});
    }

    componentDidMount() {
        const {t} = this.props;
        propertyService.getAllSummary(this.props.entityId).then(response => {
            this.setState({properties: getMapOfStringProperties(response)});
            this.setState({allProperties: getMapOfStringProperties(response)});
        });
        lookupValuesService.getPropertyTypes().then(response => {
            const propertyTypesTranslated = response.map(x => {
                let translatedObj = x;
                translatedObj.name = `${t(`properties-${x.name.toUpperCase()}`)}`;
                return translatedObj;
            });
            this.setState({propertyTypes: propertyTypesTranslated});
            this.setState({allPropertyTypes: propertyTypesTranslated});
        });
        lookupValuesService.getCurrencies().then(response => {
            this.setState({currencies: response});
            this.setState({allCurrencies: response})
        });
        lookupValuesService.getPropertyBusinessTypes().then(response => {
            const businessTypesTranslated = response.map(x => {
                let translatedObj = x;
                translatedObj.name = `${t(`propertyForm-Business Type-${x.name.toUpperCase()}`)}`;
                return translatedObj;
            });
            this.setState({businessTypes: businessTypesTranslated});
            this.setState({allBusinessTypes: businessTypesTranslated})
        });
        lookupValuesService.getPropertyLocationTypes().then(response => {
            const locationTypesTranslated = response.map(x => {
                let translatedObj = x;
                translatedObj.name = `${t(`propertyForm-Location Type-${x.name.toUpperCase()}`)}`;
                return translatedObj;
            });
            this.setState({locationTypes: locationTypesTranslated});
            this.setState({allLocationTypes: locationTypesTranslated})
        });
        lookupValuesService.getKitchenTypes().then(response => {
            const kitchenTypesTranslated = response.map(x => {
                let translatedObj = x;
                translatedObj.name = `${t(`propertyForm-Kitchen Type-${x.name.toUpperCase()}`)}`;
                return translatedObj;
            });
            this.setState({kitchenTypes: kitchenTypesTranslated});
            this.setState({allKitchenTypes: kitchenTypesTranslated})
        });
        lookupValuesService.getOutsideAreaTypes().then(response => {
            const outsideAreaType = response.map(x => {
                let translatedObj = x;
                translatedObj.name = `${t(`propertyForm-Outside Area Type-${x.name.toUpperCase()}`)}`;
                return translatedObj;
            });
            this.setState({outsideAreaTypes: outsideAreaType});
            this.setState({allOutsideAreaTypes: outsideAreaType})
        });
    }

    handleChange = (event) => {
        switch (event.target.name) {
            case "propertyType":
                const propertyType = event.target.value;
                this.setState({
                    propertyType: propertyType,
                    showDetailedInformation: propertyType ? [1, 3, 4].includes(propertyType.id) : false,
                    collapseDetailedInformation: propertyType && [1, 3, 4].includes(propertyType.id) ? this.state.collapseDetailedInformation : true
                });
                break;
            case "parentProperty":
                this.setState({parentProperty: event.target.value});
                if (event.isUpdate) {
                    this.setState({parentPropertyUpdated: true});
                }
                break;
            case "currency":
                this.setState({currency: event.target.value});
                break;
            case "businessType":
                this.setState({businessType: event.target.value});
                break;
            case "locationType":
                this.setState({locationType: event.target.value});
                break;
            case "kitchenType":
                this.setState({kitchenType: event.target.value});
                break;
            case "outsideAreaType":
                this.setState({outsideAreaType: event.target.value});
                break;
            default:
                console.warn("unhandled change event for " + event.target.name);
        }
    };

    handleFilterChange = (event) => {
        switch (event.target.name) {
            case "propertyType":
                const propertyTypes = this.state.allPropertyTypes.slice();
                this.setState({propertyTypes: filterBy(propertyTypes, event.filter)});
                break;
            case "parentProperty":
                const allProperties = this.state.allProperties.slice();
                this.setState({properties: filterBy(allProperties, event.filter)});
                break;
            case "currency":
                const currencies = this.state.allCurrencies.slice();
                this.setState({currencies: filterBy(currencies, event.filter)});
                break;
            case "businessType":
                const businessTypes = this.state.allBusinessTypes.slice();
                this.setState({businessTypes: filterBy(businessTypes, event.filter)});
                break;
            case "locationType":
                const locationTypes = this.state.allLocationTypes.slice();
                this.setState({locationTypes: filterBy(locationTypes, event.filter)});
                break;
            case "kitchenType":
                const kitchenTypes = this.state.allKitchenTypes.slice();
                this.setState({kitchenTypes: filterBy(kitchenTypes, event.filter)});
                break;
            case "outsideAreaType":
                const outsideAreaTypes = this.state.allOutsideAreaTypes.slice();
                this.setState({outsideAreaTypes: filterBy(outsideAreaTypes, event.filter)});
                break;
            default:
                console.warn("unhandled filter change event for " + event.target.name);
        }
    };

    render() {
        const {t} = this.props;
        const entityId = this.props.entityId;
        return (
            <Formik
                enableReinitialize
                initialValues={this.props.initialValues}
                validationSchema={Yup.object().shape({...baseInfoValidationSchema, ...taxInfoValidationSchema, ...detailedInfoValidationSchema, ...addressValidationSchema})}
                onSubmit={(
                    {
                        name, code, description, areaMeters, value,

                        hasHeating, hasAirCondition, isFurnished, numBathrooms, numToilets, kitchenAreaMeters, outsideAreaMeters, numBalconies, numBedrooms, numSuits, hasLaundry,
                        hasStorageRoom, storageRoomAreaMeters, hasLivingRoom, livingRoomAreaMeters, hasDiningRoom, diningRoomAreaMeters, numParkingInside, numParkingOutside,

                        locationCode, articleCode, fractionCode,

                        isSubProperty, cityId, street, number, floor, mailBox, postalCode, latitude, longitude
                    },
                    {setStatus, setSubmitting}
                ) => {
                    if (cityId == null || this.state.propertyType == null) {
                        setSubmitting(false);
                        let error = {};
                        if (cityId == null) {
                            error["cityId"] = "City is required";
                        }
                        if (this.state.propertyType == null) {
                            error["propertyType"] = "Property Type is required";
                        }
                        setStatus(error);
                    } else {
                        let createOrUpdateAddress;
                        if (this.state.addressId) {
                            createOrUpdateAddress = addressService.updateAddress(entityId, this.state.addressId, cityId, street, number, floor, mailBox, postalCode, latitude, longitude);
                        } else {
                            createOrUpdateAddress = addressService.createAddress(entityId, cityId, street, number, floor, mailBox, postalCode, latitude, longitude);
                        }
                        createOrUpdateAddress
                            .then(
                                response => {
                                    setStatus();
                                    let addressId = this.state.addressId;
                                    if (!addressId) {
                                        addressId = response;
                                        this.setState({addressId: response})
                                    }
                                    this.createOrUpdateProperty(addressId, isSubProperty, name, code, description, areaMeters, value, locationCode, articleCode, fractionCode, hasHeating, hasAirCondition, isFurnished, numBathrooms, numToilets, kitchenAreaMeters, outsideAreaMeters, numBalconies, numBedrooms, numSuits, hasLaundry, hasStorageRoom, storageRoomAreaMeters, hasLivingRoom, livingRoomAreaMeters, hasDiningRoom, diningRoomAreaMeters, numParkingInside, numParkingOutside, entityId, setSubmitting, setStatus);
                                },
                                error => {
                                    setSubmitting(false);
                                    setStatus(error);
                                }
                            );
                    }
                }}
                render={({errors, status, values, touched, isSubmitting, handleChange, setFieldValue}) => (
                    <Form>

                        <div className="separator" onClick={() => this.setState({collapseBaseInformation: !this.state.collapseBaseInformation})}>
                            {t('propertyForm-Base Information')}
                            {
                                this.state.collapseBaseInformation
                                    ? <i className="fas fa-angle-down" style={{"marginLeft": "5px"}}/>
                                    : <i className="fas fa-angle-up" style={{"marginLeft": "5px"}}/>
                            }
                            <small className="pl-7">(click)</small>
                        </div>

                        <BaseInformationForm
                            collapseBaseInformation={this.state.collapseBaseInformation}
                            errors={errors}
                            touched={touched}
                            status={status}
                            values={values}
                            state={this.state}
                            setFieldValue={setFieldValue}
                            handleChange={this.handleChange}
                            handleFilterChange={this.handleFilterChange}
                            address={this.props.address}
                            parentPropertyId={this.props.parentPropertyId}
                            propertyTypeId={this.props.propertyTypeId}
                            currencyId={this.props.currencyId}
                            location={this.props.location}
                            page={this.props.page}
                        />

                        {
                            this.state.showDetailedInformation &&
                            <div className="separator" onClick={() => this.setState({collapseDetailedInformation: !this.state.collapseDetailedInformation})}>
                                {t('propertyForm-Detailed Information')}
                                {
                                    this.state.collapseDetailedInformation
                                        ? <i className="fas fa-angle-down" style={{"marginLeft": "5px"}}/>
                                        : <i className="fas fa-angle-up" style={{"marginLeft": "5px"}}/>
                                }
                                <small className="pl-7">(click)</small>
                            </div>
                        }

                        <DetailedInformationForm
                            collapseDetailedInformation={this.state.collapseDetailedInformation}
                            errors={errors}
                            touched={touched}
                            status={status}
                            handleChange={handleChange}
                            values={values}
                            setFieldValue={setFieldValue}
                            state={this.state}
                            handleChangeComboBox={this.handleChange}
                            handleFilterChange={this.handleFilterChange}
                            kitchenTypeId={this.props.kitchenTypeId}
                            outsideAreaTypeId={this.props.outsideAreaTypeId}
                        />

                        <div className="separator" onClick={() => this.setState({collapseTaxInformation: !this.state.collapseTaxInformation})}>
                            {t('propertyForm-Tax Information')}
                            {
                                this.state.collapseTaxInformation
                                    ? <i className="fas fa-angle-down" style={{"marginLeft": "5px"}}/>
                                    : <i className="fas fa-angle-up" style={{"marginLeft": "5px"}}/>
                            }
                            <small className="pl-7">(click)</small>
                        </div>

                        <TaxInformationForm
                            collapseTaxInformation={this.state.collapseTaxInformation}
                            errors={errors}
                            touched={touched}
                            status={status}
                            state={this.state}
                            handleChange={this.handleChange}
                            handleFilterChange={this.handleFilterChange}
                            propertyBusinessTypeId={this.props.propertyBusinessTypeId}
                            propertyLocationTypeId={this.props.propertyLocationTypeId}
                        />

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {this.props.page === CREATE_PROPERTY ? <a>{t('Create')}</a> : <a>{t('Update')}</a>}
                            </button>
                            {
                                isSubmitting &&
                                <img
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                            }
                        </div>
                        {
                            status && status.message &&
                            <div>
                                <div className={'alert alert-danger'}>{status.message}</div>
                            </div>
                        }
                    </Form>
                )}
            />
        );
    }

    createOrUpdateProperty(addressId, isSubProperty, name, code, description, areaMeters, value, locationCode, articleCode, fractionCode, hasHeating, hasAirCondition, isFurnished, numBathrooms, numToilets, kitchenAreaMeters, outsideAreaMeters, numBalconies, numBedrooms, numSuits, hasLaundry, hasStorageRoom, storageRoomAreaMeters, hasLivingRoom, livingRoomAreaMeters, hasDiningRoom, diningRoomAreaMeters, numParkingInside, numParkingOutside, entityId, setSubmitting, setStatus) {
        const propertyTypeId = this.state.propertyType.id;
        const currency = this.state.currency;
        const propertyBusinessType = this.state.businessType;
        const propertyLocationType = this.state.locationType;
        const kitchenType = this.state.kitchenType;
        const outsideAreaType = this.state.outsideAreaType;

        const parentProperty = isSubProperty ? this.state.parentProperty : null;
        const baseInfo = propertyService.cleanPropertyBaseInformation(addressId, propertyTypeId, name, code, description, areaMeters, value, currency);
        const taxInfo = propertyService.cleanTaxInformation(propertyBusinessType, propertyLocationType, locationCode, articleCode, fractionCode);
        let additionalInfo = null;
        if (this.state.showDetailedInformation) {
            additionalInfo = propertyService.cleanAdditionalInformation(
                hasHeating, hasAirCondition, isFurnished, numBathrooms, numToilets, kitchenType, kitchenAreaMeters, outsideAreaType, outsideAreaMeters, numBalconies, numBedrooms, numSuits,
                hasLaundry, hasStorageRoom, storageRoomAreaMeters, hasLivingRoom, livingRoomAreaMeters, hasDiningRoom, diningRoomAreaMeters, numParkingInside, numParkingOutside
            );
        }
        this.requestCreateOrUpdateProperty(entityId, parentProperty, baseInfo, taxInfo, additionalInfo, setSubmitting, setStatus);
    }

    requestCreateOrUpdateProperty(entityId, parentProperty, baseInfo, taxInfo, additionalInfo, setSubmitting, setStatus) {
        if (this.props.page === CREATE_PROPERTY) {
            this.createProperty(entityId, parentProperty, baseInfo, taxInfo, additionalInfo, setSubmitting, setStatus);
        } else if (this.props.page === EDIT_PROPERTY) {
            this.updateProperty(entityId, parentProperty, baseInfo, taxInfo, additionalInfo, setSubmitting, setStatus);
        }
    }

    updateProperty(entityId, parentProperty, baseInfo, taxInfo, additionalInfo, setSubmitting, setStatus) {
        const {t} = this.props;
        const propertyId = this.props.propertyId;
        propertyService.updateProperty(entityId, propertyId, parentProperty, baseInfo, taxInfo, additionalInfo)
            .then(
                response => {
                    successToast(`${t('toast-Property successfully updated')}!`);
                    this.props.history.push(`/entities/${entityId}/properties/${propertyId}`)
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }

    createProperty(entityId, parentProperty, baseInfo, taxInfo, additionalInfo, setSubmitting, setStatus) {
        const {t} = this.props;
        propertyService.createProperty(entityId, parentProperty, baseInfo, taxInfo, additionalInfo)
            .then(
                response => {
                    successToast(`${t('toast-Property successfully created')}!`);
                    this.props.history.push(`/entities/${entityId}/properties/${response}`);
                },
                error => {
                    setSubmitting(false);
                    setStatus(error);
                }
            );
    }
}

export default withTranslation()(PropertyForm);